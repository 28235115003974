/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import I18n from '@utils/i18n';

// Add JQuery
const { $ } = window;

// Component which renders common select for Kendo grid filters
//
// NB. If you want to re-use it from Rails views - move to features/Layouts
function CommonSelect({ name, value, elements, label, placeholder, placeholderValue, selectOptions, jQueryEvents }) {
  const selectRef = selectOptions.ref || useRef();

  const placeholderText =
    elements && elements.length ? placeholder || I18n.t('generic.not_set') : I18n.t('generic.no_results');

  useEffect(() => {
    registerJQueryEvents();
  }, []);

  // TODO: remake somehow with native event handlers
  // Some of widgets are using JQuery and its events as a basis.
  // That's why sometimes you cannot use native event handlers, but need to treat them separately.
  // Better solution - highly appreciated
  const registerJQueryEvents = () => {
    if (jQueryEvents.onChange) {
      $(selectRef.current).on('change', jQueryEvents.onChange);
      delete selectOptions.onChange;
    }

    if (jQueryEvents.onKeyUp) {
      $(selectRef.current).on('keyup', jQueryEvents.onKeyUp);
      delete selectOptions.onKeyUp;
    }

    if (jQueryEvents.onKeyDown) {
      $(selectRef.current).on('keydown', jQueryEvents.onKeyDown);
      delete selectOptions.onKeyDown;
    }

    if (jQueryEvents.onClick) {
      $(selectRef.current).on('click', jQueryEvents.onClick);
      delete selectOptions.onClick;
    }

    if (jQueryEvents.onFocus) {
      $(selectRef.current).on('focus', jQueryEvents.onFocus);
      delete selectOptions.onFocus;
    }
  };

  const renderOptionDataAttrs = (dataInfo) => {
    const dataAttributes = {};
    if (!dataInfo) return dataAttributes;

    Object.keys(dataInfo).forEach((field) => {
      dataAttributes[`data-${field}`] = dataInfo[field];
    });

    return dataAttributes;
  };

  return (
    <>
      <select name={name} onChange={() => {}} {...selectOptions} ref={selectRef} value={value}>
        {placeholder && <option value={placeholderValue}>{placeholderText}</option>}

        {elements.map((element, index) => (
          <option key={index} value={element.value} {...renderOptionDataAttrs(element.data)}>
            {element.label}
          </option>
        ))}
      </select>
      {label && (
        <label className="qmb-label" htmlFor={name}>
          {label}
        </label>
      )}
    </>
  );
}

CommonSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      data: PropTypes.object
    })
  ),
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholderValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  selectOptions: PropTypes.object,
  jQueryEvents: PropTypes.shape({
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func
  })
};

CommonSelect.defaultProps = {
  elements: [],
  value: undefined,
  label: undefined,
  placeholder: undefined,
  placeholderValue: '',
  selectOptions: {},
  jQueryEvents: {}
};

export default CommonSelect;
/* eslint-enable react/jsx-props-no-spreading */
