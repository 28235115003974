import React from 'react';
import PropTypes from 'prop-types';
import './CheckboxFilter.css';

function CheckboxFilter({ name, value, label, onChange }) {
  const handleOnChange = (event) => {
    const value = event.target.checked ? true : '';
    onChange(value);
  };

  return (
    <>
      <div className="filter__value">
        <div className="qmb-toggle-switch--check">
          <input id={name} type="checkbox" name={name} onChange={handleOnChange} checked={value} />
          <label htmlFor={name} className="toggle-switch__visual" />
        </div>
      </div>
      {label && (
        <label htmlFor={name} className="qmb-label">
          {label}
        </label>
      )}
    </>
  );
}

export default CheckboxFilter;

CheckboxFilter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func
};

CheckboxFilter.defaultProps = {
  value: undefined,
  label: undefined,
  onChange: null
};
