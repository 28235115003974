import moment from 'moment';
import kendoDatatableParams from '@helpers/kendoDatatableParams';
import { MAXIMUM_ITEMS_AMOUNT } from '../context/constants';

export default function getDatatableParams({ status }) {
  const filters = {
    operators: {},
    active: { status: true, scheduledDate: true },
    values: {
      status: [status],
      scheduledDate: {
        rangeType: 'custom_range',
        startDate: moment().startOf('month').format('MM/DD/YYYY'),
        endDate: moment().format('MM/DD/YYYY'),
        fieldType: 'dateRange',
        active: false
      }
    }
  };
  const page = {
    skip: 0,
    take: MAXIMUM_ITEMS_AMOUNT
  };
  return { params: kendoDatatableParams({ filters, page }) };
}
