import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

import loaderPath from '@images/loader.svg';

function Loader({ isLoading, className }) {
  if (!isLoading) return null;

  const label = I18n.t('generic.loading');

  return (
    <div className={`text-center ${className}`}>
      <div className="load-spinner load-32">
        <svg role="img" title={label}>
          <use href={`${loaderPath}#load-spinner`} />
        </svg>
        <span>{label}</span>
      </div>
    </div>
  );
}

Loader.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string
};

Loader.defaultProps = {
  isLoading: false,
  className: ''
};

export default Loader;
