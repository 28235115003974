export const CacheKeys = {
  StatusesSelection: 'statusesSelection'
};

function getItem(key) {
  return JSON.parse(localStorage.getItem(key));
}

function setItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export const Cache = {
  getItem,
  setItem
};
