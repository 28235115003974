import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import './Accordion.scss';

function Accordion({ children, title, containerClass, defaultExpanded }) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <div className={clsx('accordionContainer', containerClass)}>
      <button type="button" className="titleContainer" onClick={() => setExpanded(!expanded)}>
        {title}
        <i role="presentation" className={clsx('arrow fa-light fa-chevron-down', { rotated: expanded })} />
        <div className="horizontalSplit" />
      </button>
      {expanded && children}
    </div>
  );
}

export default Accordion;

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  containerClass: PropTypes.string,
  defaultExpanded: PropTypes.bool
};

Accordion.defaultProps = {
  containerClass: '',
  defaultExpanded: false
};
