import React from 'react';
import PropTypes from 'prop-types';

function LinkTableCell({ colSpan, dataItem, field, className, ariaColumnIndex, style }) {
  return (
    <td colSpan={colSpan} className={className} role="gridcell" aria-colindex={ariaColumnIndex} aria-selected="false" style={style}>
      {dataItem[`${field}Path`] && <a href={dataItem[`${field}Path`]}>{dataItem[field]}</a>}
      {!dataItem[`${field}Path`] && dataItem[field]}
    </td>
  );
}

LinkTableCell.propTypes = {
  colSpan: PropTypes.number,
  dataItem: PropTypes.shape({
    path: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};

export default LinkTableCell;
