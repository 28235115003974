import React from 'react';
import { isValidDate } from '@fullcalendar/core/internal';
import { translate } from '@utils/i18n';
import getDatetimeFormat from '../../../../utils/getDatetimeFormat';

const namespace = 'features.dashboards.home.tables';

const ProposalColumns = [
  {
    dataKey: 'id',
    width: 150,
    label: `${translate('proposal', { namespace })} #`,
    renderer: ({ item }) => <a href={`/proposals/${item?.id}`}>{item?.proposalNumber}</a>
  },
  {
    dataKey: 'proposalDate',
    width: 165,
    label: translate('date', { namespace }),
    renderer: ({ item }) => {
      const proposalDate = new Date(item?.relevantDate);
      if (isValidDate(proposalDate)) {
        return getDatetimeFormat(item?.relevantDate);
      }
      return 'N/A';
    }
  },
  {
    dataKey: 'projectName',
    width: 290,
    label: translate('project', { namespace })
  },
  {
    dataKey: 'proposalStatus',
    width: 120,
    label: translate('status', { namespace })
  },
  {
    dataKey: 'totalAmount',
    label: translate('total', { namespace }),
    width: 170,
    renderer: ({ item }) => {
      if (!item?.totalAmount) return 'N/A';
      const roundedValue = Math.ceil(item.totalAmount * 100) / 100;
      return roundedValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
  }
];

export default ProposalColumns;
