import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { listingMapLookupPath, listingLookupPath } from 'routes';
import SearchSelect from '@components/Kendo/SearchSelect';
import I18n, { translate } from '@utils/i18n';
import { Dialog } from '@progress/kendo-react-dialogs';

const namespace = 'features.buildings.price_list_selector';

const accountInput = document.querySelector('[name*="building[account_id]"');

function PriceListSelector({ priceList, isNewRecord, accountsPriceLists }) {
  const [priceListId, setPriceListId] = useState(priceList?.id?.toString() ?? '');
  const input = useRef();
  const [openAsociated, setOpenAsociated] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [foundPriceListId, setFoundPriceListId] = useState('');
  const [changeAssociatedItems, setChangeAssociatedItems] = useState(false);

  const handleAccountChange = function() {
    const foundPriceId = accountsPriceLists.find((account) => account.id.toString() === this.value)?.price_list_id?.toString() ?? ''

    if (priceListId === '') {
      setPriceListId(foundPriceId);
    } else {
      setOpenAccount(true);
      setFoundPriceListId(foundPriceId);
    }
  }

  useEffect(() => {
    if (!isNewRecord) return;

    accountInput.addEventListener('change', handleAccountChange);

    return () => {
      accountInput.removeEventListener('change', handleAccountChange);
    };
  }, [priceListId]);

  const onChange = (e) => {
    const newId = e.sender.element[0].value;
    let isChanged;
    if (priceListId === newId) return;
    setPriceListId((old) => {
      isChanged = newId !== old;
      return newId;
    });

    if (isChanged) {
      input.current.dispatchEvent(new Event('change'));

      if (priceList === null) return;

      if (priceList.id.toString() !== newId) setOpenAsociated(true);
    }
  };

  return (
    <>
      <div className="qmb-select" style={{ top: '-0.75rem', height: '43px' }}>
        {/* <span>{translate('price_list', { namespace })}: </span> */}
        <SearchSelect
          name="product"
          value={priceListId}
          dataUrl={listingLookupPath({ type: 'price_list', search: '' })}
          valueMapUrl={listingMapLookupPath({ type: 'price_list' })}
          label={I18n.t('features.buildings.price_list_selector.label')}
          placeholder={I18n.t('generic.select_value')}
          selectOptions={{ id: 'price_list', onChange }}
        />
      </div>
      {openAsociated && (
        <Dialog title={translate('dialog_title', { namespace })} className="qmb-dialog" onClose={() => {}}>
          <div>
            <input
              type="checkbox"
              className="qmb-checkbox__input"
              onChange={() => {
                setChangeAssociatedItems((old) => !old);
              }}
              checked={changeAssociatedItems}
            />
            {translate('items_associated', { namespace })}
          </div>
          <div>{translate('note', { namespace })}</div>
          <br />
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button
              type="button"
              className="qmb-button"
              onClick={() => {
                setPriceListId(priceList?.id?.toString());
                setChangeAssociatedItems(false);
                setOpenAsociated(false);
              }}>
              {I18n.t('generic.cancel')}
            </button>
            <button
              onClick={() => {
                setOpenAsociated(false);
              }}
              type="submit"
              className="qmb-button--submit">
              {I18n.t('generic.ok')}
            </button>
          </div>
        </Dialog>
      )}

      {openAccount && (
        <Dialog title={translate('account_title', { namespace })} className="qmb-dialog" onClose={() => {setOpenAccount(false)}}>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button
              type="button"
              className="qmb-button"
              onClick={() => {
                setOpenAccount(false);
              }}>
              {I18n.t('generic.cancel')}
            </button>
            <button
              onClick={() => {
                setPriceListId(foundPriceListId);
                setOpenAccount(false);
              }}
              type="submit"
              className="qmb-button--submit">
              {I18n.t('generic.ok')}
            </button>
          </div>
        </Dialog>
      )}
      <input
        type="hidden"
        name="building[change_associated_items_price_lists]"
        value={changeAssociatedItems}
        required="required"
      />
      <input ref={input} type="hidden" name="building[price_list_id]" value={priceListId} id="building_price_list_id" />
    </>
  );
}

PriceListSelector.propTypes = {
  priceList: PropTypes.object,
  isNewRecord: PropTypes.bool,
  accountsPriceLists: PropTypes.array
};

PriceListSelector.defaultProps = {
  isNewRecord: false
}

export default PriceListSelector;
