import React from 'react';
import { isValidDate } from '@fullcalendar/core/internal';
import { translate } from '@utils/i18n';
import { workOrderPath } from 'routes';
import getDatetimeFormat from '../../../../utils/getDatetimeFormat';

const namespace = 'features.dashboards.home.tables';

const ServiceColumns = [
  {
    dataKey: 'id',
    width: 200,
    label: `${translate('work_order', { namespace })} #`,
    renderer: ({ item }) => <a href={workOrderPath(item?.id)}>{item?.woNumber}</a>
  },
  {
    dataKey: 'scheduledDate',
    label: translate('date_time', { namespace }),
    width: 200,
    renderer: ({ item }) => {
      const scheduledDate = new Date(item?.scheduledDate);
      if (isValidDate(scheduledDate)) {
        return getDatetimeFormat(item?.scheduledDate);
      }
      return 'N/A';
    }
  },
  {
    dataKey: 'building',
    label: translate('building', { namespace }),
    width: 240
  },
  {
    dataKey: 'tech',
    label: translate('tech', { namespace }),
    width: 200
  }
];

export default ServiceColumns;
