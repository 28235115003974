import { useCallback, useEffect, useState } from 'react';
// Allows a piece of data to automatically update with newest changes
// and also be manually overwritten using the setData callback.
// Basically is 'useState' but also updates state if original reference changes.
const useGreedyState = (watchedData) => {
    const [managedData, setManagedData] = useState(watchedData);
    useEffect(() => {
        setManagedData(watchedData);
    }, [watchedData]);
    const setData = useCallback((overrideData) => {
        setManagedData(overrideData);
    }, []);
    return { data: managedData, setData };
};
export default useGreedyState;
