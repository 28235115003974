import React from 'react';
import { isValidDate } from '@fullcalendar/core/internal';
import { inspectionPath } from 'routes';
import { translate } from '@utils/i18n';
import getDatetimeFormat from '../../../../utils/getDatetimeFormat';

const namespace = 'features.dashboards.home.tables';

const InspectionColumns = [
  {
    dataKey: 'id',
    width: 180,
    label: `${translate('inspection', { namespace })} #`,
    renderer: ({ item }) => <a href={inspectionPath(item?.id)}>{item?.id}</a>
  },
  {
    dataKey: 'scheduledDate',
    label: translate('date_time', { namespace }),
    renderer: ({ item }) => {
      const scheduledDate = new Date(item?.scheduledDate);
      if (isValidDate(scheduledDate)) {
        return getDatetimeFormat(item?.scheduledDate);
      }
      return 'N/A';
    }
  },
  {
    dataKey: 'building',
    label: translate('building', { namespace })
  },
  {
    dataKey: 'tech',
    width: 200,
    label: translate('tech', { namespace })
  }
];

export default InspectionColumns;
