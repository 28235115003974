import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3';
import adaptivePlugin from '@fullcalendar/adaptive';
import I18n from '@utils/i18n';
import Header from './nested/Header';
import Event from './nested/Event';
import CellHeader from './nested/CellHeader';
import { CalendarContext, CalendarProvider } from './CalendarContext';
import useEvents from './hooks/useEvents';
import TimeOffModal from '../Modals/TimeOffModal/index.tsx';
import VisitEditModal from '../Modals/VisitModal/index.tsx';

const CalendarComp = React.memo(
  ({ view, events: initialEvents, onEventChange, onDateRangeChange, timeZone, setSelectedEvent }) => {
    const [isMonth, setIsMonth] = useState(view === 'dayGridMonth');

    const onChangeView = (e) => {
      setIsMonth(e.view.type === 'dayGridMonth');
      onDateRangeChange(e);
    };

    const events = useMemo(
      () =>
        initialEvents.map(({ display, ...event }) => {
          return {
            ...event,
            ...(isMonth ? { ...event, display } : {})
          };
        }),
      [initialEvents, isMonth]
    );

    return (
      <FullCalendar
        // TODO: move in env vars
        schedulerLicenseKey="0879361028-fcs-1723636422"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, luxonPlugin, adaptivePlugin]}
        headerToolbar={{
          left: 'title',
          right: 'prev,today,next dayGridMonth,timeGridWeek,timeGridDay'
        }}
        eventContent={EventWrapper}
        dayCellContent={CellHeaderWrapper}
        initialView={view}
        events={events}
        eventClick={(e) => {
          setSelectedEvent({ type: e.event.extendedProps.type, id: e.event.extendedProps.objectId });
        }}
        datesSet={onChangeView}
        eventDisplay="block"
        eventStartEditable
        eventChange={onEventChange}
        contentHeight="auto"
        dragScroll="true"
        showNonCurrentDates={false}
        timeZone={timeZone}
      />
    );
  }
);

CalendarComp.displayName = 'CalendarComp';

CalendarComp.propTypes = {
  view: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  onEventChange: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  setSelectedEvent: PropTypes.func.isRequired
};

function Calendar() {
  const {
    timeZone,
    state: { errorMessage, view, loading },
    actions: { onEventChange, onDateRangeChange, reloadEvents }
  } = useContext(CalendarContext);

  const events = useEvents();

  const [selectedEvent, setSelectedEvent] = useState(null);

  return (
    <>
      <div>{errorMessage}</div>
      <Header />
      {loading && (
        <div className="qmb-loading--128--page" data-title={I18n.t('generic.loading')}>
          <svg role="img">
            <use href="/map.svg#load-spinner" />
          </svg>
        </div>
      )}
      <CalendarComp
        view={view}
        events={events}
        onEventChange={onEventChange}
        onDateRangeChange={onDateRangeChange}
        timeZone={timeZone}
        setSelectedEvent={setSelectedEvent}
      />
      {selectedEvent?.type === 'TimeOff' && (
        <TimeOffModal
          id={selectedEvent?.id}
          onClose={() => {
            setSelectedEvent(null);
          }}
        />
      )}
      {selectedEvent && selectedEvent.type !== 'TimeOff' && (
        <VisitEditModal
          id={selectedEvent?.id}
          type={selectedEvent?.type}
          onClose={() => {
            setSelectedEvent(null);
          }}
          onSave={() => {
            reloadEvents(); // Not the most optimal way, but the simplest without major changes
            setSelectedEvent(null);
          }}
        />
      )}
    </>
  );
}

function EventWrapper(fcProps) {
  return <Event fcProps={fcProps} />;
}
function CellHeaderWrapper(fcProps) {
  return <CellHeader fcProps={fcProps} />;
}

function CalendarWrapper(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CalendarProvider {...props}>
      <Calendar />
    </CalendarProvider>
  );
}

export default CalendarWrapper;
