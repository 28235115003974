const DEVELOPMENT = 'development';
const TEST = 'test';
const STAGING = 'staging';
const STAGING_DEV = 'staging-dev';
const PRODUCTION = 'production';

// It is taken from the process ENV variable prepended either to dev-server bash command
// or to build pipeline. No magic, not taken implicitly from Rails application.
export const APP_ENV = process.env.RAILS_ENV || DEVELOPMENT;

export const isDevelopment = APP_ENV === DEVELOPMENT;
export const isTest = APP_ENV === TEST;
export const isStaging = APP_ENV === STAGING;
export const isStagingDev = APP_ENV === STAGING_DEV;
export const isProduction = APP_ENV === PRODUCTION;
