/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

// Add JQuery
const { $ } = window;

const DEFAULT_WIDTH = '200px';

// NB. If you want to re-use it from Rails views - move to features/Layouts
function ChosenSelect({ name, value, elements, label, reactOptions, chosenOptions }) {
  const selectRef = reactOptions.ref || useRef();

  useEffect(() => {
    $(selectRef.current).chosen({
      allow_single_deselect: true,
      no_results_text: I18n.t('generic.no_results'),
      width: DEFAULT_WIDTH,
      placeholder_text_single: chosenOptions.placeholder,
      placeholder_text_multiple: chosenOptions.placeholder,
      ...chosenOptions
    });

    if (value) $(selectRef.current).val(value);

    registerEvents();
  }, []);

  const registerEvents = () => {
    // TODO: remake somehow with native event handlers
    if (reactOptions.onChange) {
      $(selectRef.current).on('change', reactOptions.onChange);
      delete reactOptions.onChange;
    }

    if (reactOptions.onKeyUp) {
      $(selectRef.current).on('keyup', reactOptions.onKeyUp);
      delete reactOptions.onKeyUp;
    }

    if (reactOptions.onKeyDown) {
      $(selectRef.current).on('keydown', reactOptions.onKeyDown);
      delete reactOptions.onKeyDown;
    }

    if (reactOptions.onClick) {
      $(selectRef.current).on('click', reactOptions.onClick);
      delete reactOptions.onClick;
    }

    if (reactOptions.onFocus) {
      $(selectRef.current).on('focus', reactOptions.onFocus);
      delete reactOptions.onFocus;
    }
  };

  const renderOptionDataAttrs = (dataInfo) => {
    const dataAttributes = {};
    if (!dataInfo) return dataAttributes;

    Object.keys(dataInfo).forEach((field) => {
      dataAttributes[`data-${field}`] = dataInfo[field];
    });

    return dataAttributes;
  };

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <select name={name} {...reactOptions} ref={selectRef}>
        {elements.map((element, index) => (
          <option key={index} value={element.value} {...renderOptionDataAttrs(element.data)}>
            {element.label}
          </option>
        ))}
      </select>
    </>
  );
}

ChosenSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      data: PropTypes.object
    })
  ),
  label: PropTypes.string,
  reactOptions: PropTypes.object,
  chosenOptions: PropTypes.object
};

ChosenSelect.defaultProps = {
  elements: [],
  value: undefined,
  label: undefined,
  reactOptions: {},
  chosenOptions: {}
};

export default ChosenSelect;
/* eslint-enable react/jsx-props-no-spreading */
