import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '@components/Kendo/MultiSelect';

function MapTechFilter({ name, value, label, dataUrl, valueMapUrl, selectOptions, placeholder }) {
  return (
    <MultiSelect
      name={name}
      value={value}
      dataUrl={dataUrl}
      valueMapUrl={valueMapUrl}
      label={label}
      placeholder={placeholder}
      selectOptions={selectOptions}
      itemTemplate="
        <div style='display:flex; align-items: center;'>
            # if (value === -1) { #
                <span class='qmb-avatar--32--unassigned-icon' style='display:flex; border-radius:50%; align-items:center; justify-content: center; '  >
                    <i class='fa-light fa-user-helmet-safety'></i>
                </span>
            # } else if (photoUrl) { #
                <span class='qmb-avatar--32'>
                    <img style='border-radius:50%; border: 2px solid #: color #; box-sizing: border-box;' src=#: photoUrl # />
                </span>
            # } else { #
                <span class='qmb-avatar--32'>
                    <svg viewBox='0 0 16 16'>
                        <circle cx='8' cy='8' r='8' style='fill:#: color#'></circle>
                        <text x='50%' y='55%' dominant-baseline='middle' text-anchor='middle'>#: label[0] #</text>
                    </svg>
                </span>
            # } #
            <div style='margin-left:0.8rem'>#: label #</div>
        </div>
      "
      tagTemplate="
        # if (value === -1) {#
            <span class='qmb-avatar--24--unassigned--alt'>
                <i class='fa-light fa-user-helmet-safety'></i>
            </span>
        # } else if (photoUrl) { #
            <span class='qmb-avatar--24'>
                <img style='border-radius:50%; border: 2px solid #: color #; box-sizing: border-box;' src=#: photoUrl # />
            </span>
        # } else { #
            <span class='qmb-avatar--24'>
                <svg viewBox='0 0 16 16'>
                    <circle cx='8' cy='8' r='8' style='fill:#: color#'></circle>
                    <text x='50%' y='55%' dominant-baseline='middle' text-anchor='middle'>#: label[0] #</text>
                </svg>
            </span>
        # } #
      "
      staticOptions={[{ value: -1, label: I18n.t('generic.unassigned'), photoUrl: null, color: '#262626' }]}
    />
  );
}

export default MapTechFilter;

MapTechFilter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  selectOptions: PropTypes.object,
  dataUrl: PropTypes.string,
  valueMapUrl: PropTypes.string
};

MapTechFilter.defaultProps = {
  value: undefined,
  label: undefined,
  placeholder: undefined,
  selectOptions: {},
  dataUrl: '',
  valueMapUrl: ''
};
