import React from 'react';
import PropTypes from 'prop-types';
import Note from './Note';
import Upload from './Upload';

function Activity({ activity, dispatch, isBuilding }) {
  switch (activity.type) {
    case 'BuildingNote':
      return <Note note={activity} dispatch={dispatch} isBuilding={isBuilding} />;
    case 'Upload':
      return <Upload updload={activity} />;
    default:
      return null;
  }
}

Activity.propTypes = {
  activity: PropTypes.shape({ type: PropTypes.string }).isRequired,
  dispatch: PropTypes.func.isRequired,
  isBuilding: PropTypes.bool.isRequired
};

export default Activity;
