import produce from 'immer';

export const actionTypes = {
  REPORT_SELECTED: 'REPORT_SELECTED',
  REPORT_DESELECTED: 'REPORT_DESELECTED',
  REPORTS_DESELECTED: 'REPORTS_DESELECTED'
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.REPORT_SELECTED:
      draft.reports.push(action.id);
      break;
    case actionTypes.REPORT_DESELECTED:
      draft.reports.splice(draft.reports.indexOf(action.id), 1);
      break;
    case actionTypes.REPORTS_DESELECTED:
      draft.reports = [];
      break;
    default:
      throw new Error();
  }
});
