import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  inspectionPath,
  deficiencyPath,
  inspectionAnswerPath,
  inspectionFireDamperAnswerPath,
  inspectionFireAlarmAnswerPath,
  editInspectionValveAnswerPath,
  inspectionFireExtinguisherAnswerPath,
  inspectionFireExitSignAnswerPath,
  editInspectionDryValveAnswerPath,
  editInspectionInspectorsTestValveAnswerPath,
  editInspectionAlarmDeviceAnswerPath,
  editInspectionEquipmentInspectionInfoPath,
  editInternalPipeFiveYearAnswerPath,
  editInternalCheckValveFiveYearAnswerPath,
  editPressureReducingValveFiveYearAnswerPath,
  inspectionFireDoorAnswerPath,
  inspectionInspectionExternalFormPath,
  editInspectionCylinderAnswerPath,
  editInspectionHoseValveAnswerPath,
  editInspectionFireHoseAnswerPath,
  editInspectionUlcAncillaryDeviceAnswerPath,
  editInspectionControlPanelReadingPath,
  editInspectionAudibilityTestReadingPath,
  inspectionGenericAnswerPath,
  workOrderPath,
  editInspectionCircuitFaultToleranceTestReadingPath,
  editInspectionSubsequentAlarmControlPanelTestReadingPath,
  editInspectionNonLifeSafetyCircuitSupervisionTestReadingPath,
  editExternalFormPdfAnswerPath,
  editBackFlowPath
} from 'routes';
import { translate } from '@utils/i18n';

const namespace = 'features.photo_gallery.photo_card';

const pathMapper = {
  inspection: inspectionPath,
  deficiency: deficiencyPath,
  answer: inspectionAnswerPath,
  fire_damper_answer: inspectionFireDamperAnswerPath,
  fire_alarm_answer: inspectionFireAlarmAnswerPath,
  valve_answer: editInspectionValveAnswerPath,
  fire_extinguisher_answer: inspectionFireExtinguisherAnswerPath,
  fire_exit_sign_answer: inspectionFireExitSignAnswerPath,
  dry_valve_answer: editInspectionDryValveAnswerPath,
  inspectors_test_valve_answer: editInspectionInspectorsTestValveAnswerPath,
  alarm_device_answer: editInspectionAlarmDeviceAnswerPath,
  equipment_inspection_info: editInspectionEquipmentInspectionInfoPath,
  internal_pipe_five_year_answer: editInternalPipeFiveYearAnswerPath,
  internal_check_valve_five_year_answer: editInternalCheckValveFiveYearAnswerPath,
  pressure_reducing_valve_five_year_answer: editPressureReducingValveFiveYearAnswerPath,
  fire_door_answer: inspectionFireDoorAnswerPath,
  inspection_external_form: inspectionInspectionExternalFormPath,
  cylinder_answer: editInspectionCylinderAnswerPath,
  hose_valve_answer: editInspectionHoseValveAnswerPath,
  fire_hose_answer: editInspectionFireHoseAnswerPath,
  ulc_ancillary_device_answer: editInspectionUlcAncillaryDeviceAnswerPath,
  control_panel_reading: editInspectionControlPanelReadingPath,
  audibility_test_reading: editInspectionAudibilityTestReadingPath,
  generic_answer: inspectionGenericAnswerPath,
  work_order: workOrderPath,
  circuit_fault_tolerance_test_reading: editInspectionCircuitFaultToleranceTestReadingPath,
  subsequent_alarm_control_panel_test_reading: editInspectionSubsequentAlarmControlPanelTestReadingPath,
  non_life_safety_circuit_supervision_test_reading: editInspectionNonLifeSafetyCircuitSupervisionTestReadingPath,
  external_form_pdf_answer: editExternalFormPdfAnswerPath,
  back_flow: editBackFlowPath	
};

function PhotoCard({ photo, onCardClick, onDelete, pageDetails }) {
  const relatedList = useMemo(() => {
    const indexToRemove = photo.related.findIndex(
      (related) => related.type === pageDetails.type && related.id === pageDetails.id
    );
    const result = [...photo.related];
    if (indexToRemove !== -1) result.splice(indexToRemove, 1);

    return result;
  }, []);

  return (
    <tr>
      <td className="--primary--photo">
        <button type="button" className="table__thumb" onClick={onCardClick}>
          <img alt={photo.description} src={photo.thumb} />
          {photo.internal && <span className="thumb__tag--internal">Internal</span>}
        </button>
      </td>
      <td>
        {relatedList.length > 0 && (
          <div className="qmb-ref" data-title="Related:">
            {relatedList.map((answer) => (
              <>
                {!answer.disabled && (
                  <a
                    className="qmb-control--sm--inline"
                    target="_blank"
                    rel="noreferrer"
                    href={pathMapper[answer.type]({
                      id: answer.id,
                      inspection_id: answer.inspectionId,
                      _options: true
                    })}>
                    {translate(answer.type, { namespace })} #{answer.label || answer.id}
                  </a>
                )}
                {answer.disabled && (
                  <a className="qmb-control--sm--inline">
                    {translate(answer.type, { namespace })} #{answer.id}
                  </a>
                )}
              </>
            ))}
          </div>
        )}
        <p className="truncated">{photo.description}</p>
      </td>
      <td>{photo.photoType}</td>
      <td>
        {new Date(photo.dateTaken).toLocaleDateString('en-US')}
        {' @ '}
        {new Date(photo.dateTaken).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}
      </td>
      <td>
        <button type="button" onClick={onDelete}>
          <i className="fa-light fa-trash-can" />
        </button>
      </td>
    </tr>
  );
}

PhotoCard.propTypes = {
  onDelete: PropTypes.func.isRequired,
  photo: PropTypes.object.isRequired,
  onCardClick: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired
};

export default PhotoCard;
