import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Window } from '@progress/kendo-react-dialogs';
import clsx from 'classnames';

export default function Modal({ isOpen, title, children, onClose, height, width, className }) {
  const modalRef = useRef(null);

  useEffect(() => {
    const overlay = document.querySelector('.k-overlay');
    const handleOverlayClick = () => {
      onClose();
    };

    overlay?.addEventListener('click', handleOverlayClick);

    return () => {
      overlay?.removeEventListener('click', handleOverlayClick);
    };
  }, [onClose]);

  return (
    isOpen && (
      <Window
        ref={modalRef}
        title={title}
        modal
        resizable={false}
        draggable={false}
        onClose={onClose}
        width={width}
        minWidth={width}
        initialWidth={width}
        minHeight={height}
        height={height}
        initialHeight={height}
        minimizeButton={() => null}
        maximizeButton={() => null}
        restoreButton={() => null}
        className={clsx(className)}>
        {children}
      </Window>
    )
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string
};

Modal.defaultProps = {
  children: null,
  title: null,
  onClose: () => {},
  isOpen: false,
  height: undefined,
  width: undefined,
  className: ''
};

Modal.displayName = 'Modal';
