import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './DashboardSettings.scss';
import ClickAwayListener from 'react-click-away-listener';
import { translate } from '@utils/i18n';
import { Popup } from '@progress/kendo-react-popup';
import useDataTableContext from '../../context/useDataTableContext';
import { DashboardEntityType } from '../../context/constants';

export const namespace = 'features.dashboards.home.settings';

function DashboardSettings({ permissions }) {
  const [isOpen, setIsOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(null);
  const gearIcon = useRef(null);
  const subMenuAnchors = useRef([]);
  const {
    actions: { changeStatusesSelection },
    state: { inspectionStatuses, workOrderStatuses, statusesSelection }
  } = useDataTableContext();

  const entities = useMemo(() => {
    return [
      {
        titleLabel: translate('inspections', { namespace }),
        id: DashboardEntityType.Inspections,
        statuses: inspectionStatuses
      },
      permissions.service
        ? {
            titleLabel: translate('service', { namespace }),
            id: DashboardEntityType.WorkOrders,
            statuses: workOrderStatuses
          }
        : null
    ].filter(Boolean);
  }, []);

  const onChangeSelection = (entity, statusCode) => {
    const newSelection = statusesSelection[entity].includes(statusCode)
      ? statusesSelection[entity].filter((s) => s !== statusCode)
      : [...statusesSelection[entity], statusCode];
    changeStatusesSelection({
      ...statusesSelection,
      [entity]: newSelection
    });
  };

  const onSelectAll = ({ entity, statuses }) => {
    changeStatusesSelection({
      ...statusesSelection,
      [entity]: statuses.map((status) => status.statusCode)
    });
  };

  const toggleSubMenuOpen = (entity) => {
    setSubMenuOpen(entity === subMenuOpen ? null : entity);
  };

  const onCloseMainMenu = () => {
    if (!subMenuOpen) {
      setIsOpen(false);
    }
  };

  const onCloseSubMenu = () => {
    setSubMenuOpen(null);
  };

  const entitySelected = useMemo(() => entities.find(({ id }) => id === subMenuOpen), [subMenuOpen]);

  return (
    <>
      <div className="dashboard__settings">
        <button ref={gearIcon} type="button" onClick={() => setIsOpen(!isOpen)} className="qmb-control--icon">
          <i className="fa-light fa-gear iconToggleMenu" />
        </button>
      </div>
      <Popup anchor={gearIcon.current} show={isOpen} animate={false}>
        <ClickAwayListener onClickAway={() => onCloseMainMenu()}>
          <div className="qmb-popup--actions">
            <div className="popup__section--group">
              <h4 className="popup__title--group">{translate('widgets', { namespace })}</h4>
              <ul role="presentation">
                {entities.map((entity) => (
                  <li key={entity.id}>
                    <button
                      ref={(el) => {
                        subMenuAnchors.current[entity.id] = el;
                      }}
                      type="button"
                      className="qmb-control--menu"
                      onClick={() => toggleSubMenuOpen(entity.id)}>
                      <span>{entity.titleLabel}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            {permissions.settings && (
              <>
                <hr className="popup__split" />

                <div className="popup__section">
                  <a className="qmb-control" href="/company_settings">
                    {translate('company_settings', { namespace })}
                  </a>
                </div>
              </>
            )}
            {permissions.companySettings && (
              <>
                <hr className="popup__split" />

                <div className="popup__section">
                  <a className="qmb-control" href="/all_settings">
                    {translate('settings', { namespace })}
                  </a>
                </div>
              </>
            )}
          </div>
        </ClickAwayListener>
      </Popup>
      <Popup
        collision={{
          horizontal: 'fit',
          vertical: 'fit'
        }}
        anchor={subMenuAnchors.current[subMenuOpen]}
        animate={false}
        show={Boolean(subMenuOpen)}
        onClose={() => setSubMenuOpen(null)}>
        <ClickAwayListener onClickAway={() => onCloseSubMenu()}>
          <div className="qmb-popup--actions--y-max">
            <div className="popup__section--group--y-fill">
              <h4 className="popup__title--group">{translate('show_status', { namespace })}</h4>
              <ul role="presentation">
                {entitySelected?.statuses.map((status) => (
                  <li key={status.statusCode}>
                    <label className="qmb-checkbox">
                      <input
                        name="statusChckbox"
                        type="checkbox"
                        checked={statusesSelection[entitySelected?.id].some((s) => s === status.statusCode)}
                        onChange={() => onChangeSelection(entitySelected?.id, status.statusCode)}
                      />
                      <i className={`qmb-status ${status.icon}`} style={{ color: status.statusColor }} />
                      {status.name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>

            <hr className="popup__split" />

            <div className="popup__section">
              <button
                type="button"
                className="qmb-control"
                onClick={() => onSelectAll({ entity: entitySelected?.id, statuses: entitySelected?.statuses })}>
                {translate('select_all', { namespace })}
              </button>
            </div>
          </div>
        </ClickAwayListener>
      </Popup>
    </>
  );
}

DashboardSettings.propTypes = {
  permissions: PropTypes.object
};

DashboardSettings.defaultProps = {
  permissions: {}
};

export default DashboardSettings;
