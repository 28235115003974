import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';
import I18n, { translate } from '@utils/i18n';
import VisibilityToggle from './VisibilityToggle';
import { buildingBuildingNotesPath } from '../../../routes';
import { actionTypes } from '../reducer';

const namespace = 'features.activity_feed.note_form';

function NoteForm({ buildingId, workOrderId, inspectionId, dispatch }) {
  const [notes, setNotes] = useState('');
  const [visibility, setVisibilty] = useState('tech');
  const noteRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (noteRef.current && !noteRef.current.contains(event.target)) {
        removeActive();
      }
    };

    const handleFocus = (event) => {
      if (noteRef.current && !noteRef.current.contains(event.target)) {
        removeActive();
      }
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('focusin', handleFocus);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('focusin', handleFocus);
    };
  }, []);

  const addActive = () => {
    if (noteRef.current) {
      noteRef.current.classList.add('--active');
    }
  };

  const removeActive = () => {
    if (noteRef.current) {
      noteRef.current.classList.remove('--active');
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const body = new FormData();
    body.append('building_note[notes]', notes);
    body.append('building_note[visibility]', visibility);

    api
      .post(buildingBuildingNotesPath({ building_id: buildingId, format: 'json' }), body, {
        params: { inspection_id: inspectionId, work_order_id: workOrderId }
      })
      .then(({ data }) => dispatch({ type: actionTypes.NOTE_ADDED, note: data }))
      .then(() => {
        setNotes('');
        removeActive();
        noteRef.current.children[0].style.height = '118px';
      });
  };

  const onVisibilityChange = (newVisibility) => {
    setVisibilty(newVisibility);
  };

  return (
    <div className="activity__minor">
      <form onSubmit={onSubmit}>
        <div className="qmb-textarea--note--x-full" ref={noteRef}>
          <textarea
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            name="notes"
            placeholder=" "
            id="activity_notes"
            onFocus={addActive}
          />
          <label className="qmb-label" htmlFor="activity_notes">
            {translate('add_note', { namespace })}
          </label>
          <div className="textarea__controls">
            <VisibilityToggle
              dispatch={dispatch}
              note={{ visibility, canEditVisibilityDestroy: true }}
              onVisibilityChange={onVisibilityChange}
            />
            <button disabled={notes.length === 0} type="submit" className="qmb-control--submit">
              {I18n.t('generic.submit')}
            </button>
          </div>
          <svg viewBox="0 0 32 32" className="textarea__accent">
            <path d="M 32 0 L 0 32" className="accent__diag" />
            <path d="M 26 0.5 L 0 0.5" className="accent__x" />
            <path d="M 0.5 26 L 0.5 0" className="accent__y" />
          </svg>
        </div>
      </form>
    </div>
  );
}

NoteForm.propTypes = {
  buildingId: PropTypes.number.isRequired,
  workOrderId: PropTypes.number,
  inspectionId: PropTypes.number,
  dispatch: PropTypes.func.isRequired
};

NoteForm.defaultProps = {
  workOrderId: undefined,
  inspectionId: undefined
};

export default NoteForm;
