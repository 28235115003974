import React from 'react';
import PropTypes from 'prop-types';
import Item from './Item';

function List({ items, updateItem, removeItem }) {
  const itemsList = items.map((item, index) => {
    // eslint-disable-next-line react/no-array-index-key
    return <Item key={index} item={item} index={index} update={updateItem} remove={removeItem} />;
  });

  return <ul className="list w-100 pt-3">{itemsList}</ul>;
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateItem: PropTypes.func,
  removeItem: PropTypes.func
};

List.defaultProps = {
  updateItem: () => {},
  removeItem: () => {}
};

export default List;
