import React, {useCallback, useEffect, useMemo, useState} from "react";
import I18n from '@utils/i18n';
import api from '@utils/axios';
import {LocalizationProvider} from "@progress/kendo-react-intl";
import SearchPanel from "../../components/SearchPanel";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {getInitialData} from "./helpers/initialData";
import {CACHE_KEY, ALL_COLUMNS,ALL_COLUMNS_DATA} from "./constants";
import './index.css';
import {
    customersQuickBooksSessionPath,
    importQbCustomersAccountsPath,
    importQbCustomersBuildingsPath
} from "../../routes";
import kendoDatatableParams from "../../helpers/kendoDatatableParams";
import debounce from "lodash/debounce";
import {INITIAL_PAGE_SKIP} from "../InspectionsIndex/constants";
import {produce} from "immer";
import MassActions from "./nested/MassActions";
import {Window} from "@progress/kendo-react-dialogs";
import {STATUS} from "./constants";


function ImportFromQB({type, cacheKey, importTo}){
    const initialData = useMemo(() => getInitialData(cacheKey), []);
    const [page, setPage] = useState(initialData.page);
    const [sort, setSort] = useState(initialData.sort);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState(STATUS.LOADING)
    const [selectAll, setSelectAll] = useState(false);
    const [customers, setCustomers] = useState({ customers: [], total_count: 0 });
    const selectedCustomers = customers.customers.filter((x) => x.selected);
    const [response, setResponse] = useState(null)

    const allColumnsWrapped = useMemo(() => ALL_COLUMNS.map((column) => ALL_COLUMNS_DATA[column]), []);


    useEffect(() => {
        setStatus(STATUS.LOADING)
        api
            .get(customersQuickBooksSessionPath(), {
                params: kendoDatatableParams({
                    page,
                    sort,
                    search
                })
            })
            .then(( {data} ) => setCustomers(data))
            .finally(() => {
                setStatus(STATUS.READY)
            });
    }, [page]);



    const headerSelectionValue =
        selectAll || (customers.total_count > 0 && customers.customers.every((customer) => customer.selected));

    const onSearchChange = (value) => {
        setSearch(value);
        setPage((prevState) => ({ ...prevState, skip: INITIAL_PAGE_SKIP }));
    };


    const onSelectionChange = useCallback((event) => {
        setSelectAll(false);
        setCustomers(
            produce((draft) => {
                const customer = draft.customers.find((c) => c.id === event.dataItem.id);
                customer.selected = !customer.selected;
            })
        );
    }, []);

    const onSelectAll = useCallback(() => {
        setSelectAll(true);
        setCustomers(
            produce((draft) => {
                draft.customers.forEach((customer) => {
                    customer.selected = true;
                });
            })
        );
    }, []);

    const onHeaderSelectionChange = useCallback((event) => {
        if (!event.syntheticEvent.target.checked) {
            setSelectAll(false);
        }
        setCustomers(
            produce((draft) => {
                draft.customers.forEach((customer) => {
                    customer.selected = event.syntheticEvent.target.checked;
                });
            })
        );
    }, []);

    const loadData = useCallback((pageData) => {
        setSelectAll(false);
        if (pageData) return setPage(pageData);

        setPage((prevState) => ({ ...prevState, skip: INITIAL_PAGE_SKIP }));
    }, []);

    const handlePageSizeChange = (newPageSize) => {
        debouncedSetPageSizeValue(newPageSize);
    };

    // Debounce the function that updates pageSizeValue
    const debouncedSetPageSizeValue = debounce((newValue) => {
        loadData(newValue);
    }, 300);


    const handleImportClick = () => {
        setStatus(STATUS.IMPORTING)

        const params = selectAll ? {type: type, all: true} : {type: type, qb_ids: selectedCustomers.map(x => x.id) }

        api.post(importTo === 'building' ? importQbCustomersBuildingsPath() : importQbCustomersAccountsPath(), params, {timeout: 0})
            .then(res => {
                const selectedIds = selectedCustomers.map(x => x.id)
                setCustomers(
                    produce((draft) => {
                        draft.customers.forEach((customer) => {
                            if (selectedIds.includes(customer.id)){
                                customer.imported = true;
                                customer.selected = false;
                            }

                        });
                    })
                );
                setResponse(res.data)
                setStatus(STATUS.COMPLETE)
            })
            .catch(() => {
                console.log('error')
                setStatus(STATUS.ERROR)
            })
    }


    return (
        <LocalizationProvider language={I18n.locale}>

            <MassActions
                customers={customers}
                selectAll={selectAll}
                onSelectAll={onSelectAll}
                onImport={handleImportClick}
                importing={status === STATUS.IMPORTING}
                type={type}
                importTo={importTo}
                loading={status === STATUS.LOADING}
            >

                <div className="content-actions__group--search" style={{marginLeft: "auto", flex: "0 0 auto"}}>
                    <SearchPanel placeholder={I18n.t('filters.search')} onChange={onSearchChange} />
                </div>

            </MassActions>


            <hr className="workspace__split--sticky" />
            {status === STATUS.LOADING && (
                <div className="workspace__section">
                    <div className="qmb-loading--96" data-title="Loading">
                        <svg role="img">
                            <use href="/map.svg#load-spinner" />
                        </svg>
                    </div>
                </div>
            )}
            {status !== STATUS.LOADING &&
                <Grid
                    className="qmb-grid--01"
                    data={customers.customers}
                    total={customers.total_count}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={(event) => {
                        handlePageSizeChange(event.page);
                    }}
                    selectedField="selected"
                    pageable={{
                        type: 'input',
                        pageSizes: [25, 50, 100],
                        pageSizeValue: page.take
                    }}
                    sortable
                    sort={sort}
                    onSortChange={(event) => {
                        setSort(event.sort);
                        loadData();
                    }}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                >

                    {allColumnsWrapped.filter(x => !x.hidden).map((column) => (
                        <GridColumn
                            key={column.id}
                            field={column.field}
                            title={column.title}
                            cell={column.cell}
                            headerSelectionValue={headerSelectionValue}
                            sortable={column.sortable}
                            width={column.width}
                            className={column.className}
                            headerClassName={column.headerClassName}
                        />
                    ))}

                </Grid>
            }


            {(status === STATUS.IMPORTING || status === STATUS.COMPLETE || status === STATUS.ERROR) &&
            <Window
                modal={true}
                doubleClickStageChange={false}
                draggable={false}
                maximizeButton={() => null}
                minimizeButton={() => null}
                onClose={() => setStatus(STATUS.READY)}
                initialHeight={0}
            >
                {status === STATUS.IMPORTING && <Spinner /> }
                {status === STATUS.COMPLETE && <Complete inner_html={response} /> }
                {status === STATUS.ERROR && <Error /> }
            </Window> }
        </LocalizationProvider>)
}


const Spinner = () => {
    return (<div className="qmb-loading--96" data-title="Importing...">
        <svg role="img">
            <use href="/map.svg#load-spinner" />
        </svg>
    </div>)
}


const Complete = ({inner_html}) => {
    return (
        <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html: inner_html }}></div>
        </React.Fragment>
    )
}

const Error = () => {
    return (
        <React.Fragment>
            An error occurred. Please try again.
        </React.Fragment>
    )
}

export default ImportFromQB;

