import React from 'react';
import PropTypes from 'prop-types';
// import CreateEvent from './CreateEvent';

function CellHeader({ fcProps }) {
  return <span className="cal__num">{fcProps.dayNumberText}</span>;
}

CellHeader.propTypes = {
  fcProps: PropTypes.object.isRequired
};

export default CellHeader;
