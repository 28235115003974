import React, { useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import api from '@utils/axios';
import { translate } from '@utils/i18n';
import Activity from './nested/Activity';
import NoteForm from './nested/NoteForm';
import { actionTypes, reducer, sevenDaysAgo } from './reducer';
import { buildingBuildingNotesPath } from '../../routes';

const namespace = 'features.activity_feed.index';

function ActivityFeed({ buildingId, inspectionId, workOrderId }) {
  const [state, dispatch] = useReducer(reducer, { all: [], week: [], older: [] });
  const activityCount = useMemo(() => {
    const weekLength = state.all.filter((activity) => sevenDaysAgo < activity.datetime).length;
    return { week: state.week.length + weekLength, older: state.older.length + state.all.length - weekLength };
  }, [state]);

  const isBuilding = useMemo(() => !(inspectionId || workOrderId), []);

  useEffect(() => {
    api
      .get(
        buildingBuildingNotesPath({
          building_id: buildingId,
          inspection_id: inspectionId,
          work_order_id: workOrderId,
          format: 'json'
        })
      )
      .then(({ data }) => {
        dispatch({ type: actionTypes.DATA_LOADED, data });
      });
  }, []);

  const loadMore = () => {
    dispatch({ type: actionTypes.LOAD_MORE });
  };

  return (
    <div className="workspace__activity">
      <div className="activity__major">
        <InfiniteScroll
          loadMore={loadMore}
          useWindow={false}
          getScrollParent={() => document.getElementsByClassName('infinite-scroll-container')[0]}
          hasMore={state.all.length > 0}
          loader={
            <div className="qmb-loading--32" key={0}>
              <svg role="img">
                <use href="/map.svg#load-spinner" />
              </svg>
            </div>
          }>
          <>
            {state.week.length === 0 && state.older.length === 0 && (
              <section className="workspace__section">
                <article className="section__content">{translate('no_activities', { namespace })}</article>
              </section>
            )}

            {state.week.length > 0 && (
              <section className="workspace__section">
                <header className="section__title">
                  <h2 className="section__label">{translate('last_seven_days', { namespace })}</h2>
                  <span>
                    {activityCount.week} {translate('updates', { namespace })}
                  </span>
                  <hr className="section__split" />
                </header>
                <article className="section__content">
                  <ul role="presentation" className="activity__list">
                    {state.week.map((activity) => (
                      <Activity isBuilding={isBuilding} activity={activity} key={activity.id} dispatch={dispatch} />
                    ))}
                  </ul>
                </article>
              </section>
            )}

            {state.older.length > 0 && (
              <section className="workspace__section">
                <header className="section__title">
                  <h2 className="section__label">{translate('older', { namespace })}</h2>
                  <span>
                    {activityCount.older} {translate('updates', { namespace })}
                  </span>
                  <hr className="section__split" />
                </header>
                <article className="section__content">
                  <ul role="presentation" className="activity__list">
                    {state.older.length &&
                      state.older.map((activity) => (
                        <Activity isBuilding={isBuilding} activity={activity} key={activity.id} dispatch={dispatch} />
                      ))}
                  </ul>
                </article>
              </section>
            )}
          </>
        </InfiniteScroll>
      </div>

      <NoteForm buildingId={buildingId} workOrderId={workOrderId} inspectionId={inspectionId} dispatch={dispatch} />
    </div>
  );
}

ActivityFeed.propTypes = {
  buildingId: PropTypes.number.isRequired,
  inspectionId: PropTypes.number,
  workOrderId: PropTypes.number
};

ActivityFeed.defaultProps = {
  inspectionId: undefined,
  workOrderId: undefined
};

export default ActivityFeed;
