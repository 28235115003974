import React, { useContext, useRef } from 'react';
import { AssigneeMultiSelectContext } from '../AssigneeMultiSelectContext';
function TechSubSelector() {
    const { selectedTechnicians, selectedSubcontractors, setActiveMultiSelector } = useContext(AssigneeMultiSelectContext);
    const buttonStyle = { justifyContent: 'space-between' };
    const counterStyle = {
        marginRight: '2rem',
        background: '#F0EDF8',
        color: '#3D1B9D',
        boxSizing: 'border-box',
        padding: '.2rem',
        borderRadius: '20%'
    };
    const techAnchor = useRef(null);
    const subAnchor = useRef(null);
    const handleTechClick = () => {
        setActiveMultiSelector("technician" /* AssigneeType.TECHNICIAN */);
    };
    const handleSubClick = () => {
        setActiveMultiSelector("subcontractor" /* AssigneeType.SUBCONTRACTOR */);
    };
    return (React.createElement("div", { className: "qmb-popup--actions--y-max" },
        React.createElement("div", { className: "popup__section--y-fill" },
            React.createElement("button", { className: "qmb-control--menu", type: "button", style: buttonStyle, onClick: handleTechClick },
                React.createElement("span", null, "Our Technicians"),
                React.createElement("span", { style: counterStyle, ref: techAnchor.current }, selectedTechnicians.length)),
            React.createElement("button", { className: "qmb-control--menu", type: "button", style: buttonStyle, onClick: handleSubClick },
                React.createElement("span", null, "Subcontractors"),
                React.createElement("span", { style: counterStyle, ref: subAnchor.current }, selectedSubcontractors.length)))));
}
export default TechSubSelector;
