import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { Tooltip } from "@progress/kendo-react-tooltip";
import I18n from '@utils/i18n';

const NAMESPACE = 'filters';

function FilterToggle({ filters }) {
  const anchor = useRef(null);
  const popup = useRef(null);
  const [show, setShow] = useState(false);

  const onToggleClick = () => {
    setShow(!show);
  };

  const handleBodyClick = (event) => {
    if (popup.current?._popup?.contains(event.target)) return;
    if (anchor.current.contains(event.target)) return;

    setShow(false);
  };

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  return (
    <div>
      <button className="qmb-control--icon--manage filters__toggle-menu" ref={anchor} type="button" onClick={onToggleClick}>
        <i className="fa-light fa-filter" />
      </button>

      <Popup anchor={anchor.current} show={show} ref={popup}>
        <div className="qmb-popup--action-list">
          <Tooltip anchorElement="target" position="right">
            <h4 className="popup__section-title--small">{I18n.t(`${NAMESPACE}.manage_filters`)}</h4>
            <ul role="presentation">
              {filters.map((filter) => (
                <li key={filter.field}>
                  <div className="qmb-checkbox">
                    <input
                      id={`toggle_${filter.field}`}
                      type="checkbox"
                      checked={filter.active}
                      disabled={filter.locked}
                      onChange={filter.onToggle}
                      readOnly
                    />
                    <label htmlFor={`toggle_${filter.field}`} className="qmb-label" title={filter.tooltip}>
                      {I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </Tooltip>
        </div>
      </Popup>
    </div>
  );
}

FilterToggle.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      active: PropTypes.bool,
      locked: PropTypes.bool,
      onToggle: PropTypes.func,
      tooltip: PropTypes.string
    })
  )
};

FilterToggle.defaultProps = {
  filters: []
};

export default FilterToggle;
