import React, { useEffect, useState, useMemo } from 'react';
import { MultiSelect as KendoMultiSelect } from '@progress/kendo-react-dropdowns';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';
import './MultiSelect.css';
import { listingLookupPath } from 'routes';

const PAGE_SIZE = 10;

function MultiSelect({ name, value, label, onChange, backendField }) {
  const [allElements, setAllElements] = useState([]);
  const [filterQuery, setFilterQuery] = useState('');
  const [skip, setSkip] = useState(0);

  const filteredElements = useMemo(() => {
    if (filterQuery === '') return allElements;

    const re = new RegExp(filterQuery, 'i');

    return allElements.filter((element) => re.test(element.label));
  }, [filterQuery, allElements]);

  const elements = useMemo(() => filteredElements.slice(skip, skip + PAGE_SIZE), [allElements, filteredElements, skip]);

  const dataUrl = useMemo(() => listingLookupPath({ type: backendField, search: filterQuery }));

  useEffect(() => {
    fetch(dataUrl)
      .then((response) => response.json())
      .then((response) => {
        setAllElements(response.data);
      });
  }, []);

  const handleOnChange = (event) => {
    const res = event.value.map((elem) => elem.value);
    onChange(res);
  };

  const multiSelectValue = () => {
    if (value === '' || allElements.length === 0) return null;

    return value.map((val) => {
      return {
        value: val,
        label: allElements.find((elem) => elem.value === val).label
      };
    });
  };

  const onPageChange = (event) => {
    setSkip(event.page.skip);
  };

  const onClose = () => {
    setSkip(0);
  };

  const onFilterChange = (event) => {
    setFilterQuery(event.filter.value);
    setSkip(0);
  };

  return (
    <>
      {label && (
        <label className="qmb-label" htmlFor={name}>
          {label}
        </label>
      )}
      <KendoMultiSelect
        style={{ color: '#000' }}
        data={elements}
        textField="label"
        dataItemKey="value"
        value={multiSelectValue()}
        filterable
        onFilterChange={onFilterChange}
        onChange={handleOnChange}
        virtual={{
          total: filteredElements.length,
          pageSize: PAGE_SIZE,
          skip
        }}
        placeholder={I18n.t('generic.all')}
        onPageChange={onPageChange}
        onClose={onClose}
      />
    </>
  );
}

export default MultiSelect;

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  dataUrl: PropTypes.string.isRequired
};

MultiSelect.defaultProps = {
  label: undefined,
  onChange: null
};
