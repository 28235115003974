import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

function BooleanSelect({ name, value, label, trueLabel, falseLabel, onChange }) {
  const elements = [
    { label: trueLabel, value: 'true' },
    { label: falseLabel, value: 'false' }
  ];

  const ddListValue = {
    value,
    label: {
      true: trueLabel,
      false: falseLabel,
      '': I18n.t('generic.all')
    }[value]
  };

  const handleOnChange = (event) => {
    onChange(event.target.value.value);
  };

  return (
    <>
      <DropDownList
        id={name}
        textField="label"
        dataItemKey="value"
        data={elements}
        value={ddListValue}
        defaultItem={{
          label: I18n.t('generic.all'),
          value: ''
        }}
        onChange={handleOnChange}
      />
      {label && (
        <label className="qmb-label" htmlFor={name}>
          {label}
        </label>
      )}
    </>
  );
}

export default BooleanSelect;

BooleanSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  trueLabel: PropTypes.string,
  falseLabel: PropTypes.string,
  onChange: PropTypes.func
};

BooleanSelect.defaultProps = {
  label: undefined,
  trueLabel: I18n.t('generic.yes'),
  falseLabel: I18n.t('generic.no'),
  onChange: null
};
