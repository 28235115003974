import React from 'react';
import PropTypes from 'prop-types';
import ItemsSection, { INSPECTED, NOT_INSPECTED } from './ItemsSection';

function Items({ items }) {
  return (
    <li className="threaded-list__level-contents">
      <ItemsSection key={INSPECTED} category={INSPECTED} items={items} />
      <ItemsSection key={NOT_INSPECTED} category={NOT_INSPECTED} items={items} />
    </li>
  );
}

Items.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default Items;
