import React from 'react';
import PropTypes from 'prop-types';

export const LoaderSizes = {
  small: 16,
  medium: 32,
  large: 64,
  xlarge: 96
};

export function Loader({ size = LoaderSizes.small }) {
  return (
    <div className="loadingContainer">
      <div className={`qmb-loading--${size}`}>
        <svg id="load-spinner" viewBox="0 0 128 128">
          <path
            className="sprite"
            d="M104,81.26c0,22.45-18.2,40.64-40.64,40.64c-22.67,0-41.05-17.3-41.05-39.97c0-15.22,7.58-24.26,7.58-24.26
    c1.22-2.15,2.5-4.38,3.81-6.75c7-12.61,2.56-25.75,2.56-25.75s4.97,2.94,8.76,10.47c3.79,7.53,2.78,20.09,2.78,20.09
    c7.91-10.53,3.53-22.6,3.1-35.16S62.88,3.15,62.88,3.15c-7.32,17.31,7.57,29.42,13.41,35.96c7.64,8.55,7.16,23.19,7.16,23.19
    c4.27-9.08,1.07-15.65,0.53-25.59c-0.53-9.94,7.37-13.04,7.37-13.04s-1.6,7.32,0.11,16.88c1.7,9.51,15.75,20.38,15.92,39.82l0-0.21
    l0,1.08c0,24.08-19.52,43.6-43.6,43.6c-23.84,0-43.17-19.33-43.17-43.17c0-23.6,19.13-42.74,42.74-42.74
    c23.37,0,42.31,18.94,42.31,42.31c0,23.13-18.75,41.88-41.88,41.88c-22.9,0-41.47-18.57-41.47-41.47
    c0-22.67,18.38-41.05,41.05-41.05C85.8,40.62,104,58.81,104,81.26"
          />
        </svg>
      </div>
    </div>
  );
}

Loader.propTypes = {
  size: PropTypes.oneOf(Object.values(LoaderSizes))
};

Loader.defaultProps = {
  size: LoaderSizes.small
};
