import React from 'react';
import { Input } from '@progress/kendo-react-inputs';
import debounce from "lodash/debounce";
import PropTypes from 'prop-types';

function SearchPanel({ placeholder, onChange }) {


  const handleOnChange = debounce((event) => {
    onChange(event.target.value);
  }, 400);

  return (
      <div className="qmb-input--search">
        <Input id="search" onChange={handleOnChange} onKeyDown={handleOnChange} placeholder={placeholder} />
        <i className="fa-light fa-search" />
      </div>
  );
}

SearchPanel.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

SearchPanel.defaultProps = {
  placeholder: '',
  onChange: null
};

export default SearchPanel;
