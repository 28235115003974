import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import debounce from 'lodash/debounce';
import api from '@utils/axios';
import { listingLookupPath } from 'routes';
import Pagination from './Pagination';

function SearchDropdown({ proposedCorrection, setProposedCorrection }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const anchor = useRef();
  const popupRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(-1);

  useEffect(() => {
    const closeDropdownOnScroll = () => {
      setDropdownOpen(false);
      setPage(-1);
    };

    const closeDropdownOnBlur = (e) => {
      if (anchor.current?.contains(e.target)) return;
      if (!popupRef.current?.contains(e.target)) {
        setDropdownOpen(false);
        setPage(-1);
      }
    };

    document.querySelector('.partition__workspace').addEventListener('scroll', closeDropdownOnScroll);
    document.body.addEventListener('click', closeDropdownOnBlur);

    return () => {
      document.querySelector('.partition__workspace').removeEventListener('scroll', closeDropdownOnScroll);
      document.body.removeEventListener('click', closeDropdownOnBlur);
    };
  }, []);

  const handleInputClick = () => {
    setPage(0);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setDropdownOpen(false);
    debounceSetPage(0);
  };

  const handleSelection = (selectedOption) => {
    setDropdownOpen(false);
    setProposedCorrection(selectedOption);
    setPage(-1);
    setInputValue('');
  };

  const debounceSetPage = useCallback(
    debounce(() => {
      setPage(-1);
      setPage(0);
    }, 800),
    []
  );

  useEffect(() => {
    if (page < 0) {
      setTotalCount(0);
      setOptions([]);
      return;
    }

    setIsLoading(true);
    api
      .get(listingLookupPath({ type: 'proposed_correction', search: inputValue, take: 20, skip: page * 20 }))
      .then((response) => {
        setTotalCount(response.data.total_count);
        setOptions((prevData) => [...prevData, ...response.data.data]);
      })
      .catch((error) => {
        console.log(error);
        setTotalCount(0);
        setOptions([]);
      })
      .finally(() => {
        setIsLoading(false);
        setDropdownOpen(true);
      });
  }, [page]);

  return (
    <>
      {!proposedCorrection?.id && (
        <div className="qmb-input--text --x-full" ref={anchor} data-invalid="true">
          {isLoading && (
            <div
              id="spinner"
              className="qmb-loading--16"
              style={{
                position: 'absolute',
                right: '5px',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 10
              }}>
              <svg role="img">
                <use href="/map.svg#load-spinner" />
              </svg>
            </div>
          )}
          <input value={inputValue} onChange={handleInputChange} onClick={handleInputClick} placeholder="Search..." />
        </div>
      )}
      <Popup anchor={anchor.current} show={dropdownOpen}>
        <div className="qmb-popup" style={{ maxWidth: '400px' }} ref={popupRef}>
          <div
            style={{ display: 'flex', flexDirection: 'column', maxHeight: '220px', overflowY: 'auto', width: '200px' }}>
            {options.length > 0 && (
              <>
                {options.map((option) => (
                  <button
                    type="button"
                    key={option.id}
                    className="qmb-control"
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      gap: 0
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleSelection(option);
                    }}>
                    <small>{option.type}</small>
                    <span>{option.label}</span>
                  </button>
                ))}
              </>
            )}
            {options.length === 0 && (
              <span
                style={{
                  padding: '2rem',
                  color: '#BBB',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                No Match Found
              </span>
            )}
            <Pagination totalCount={totalCount} options={options} setPage={setPage} />
          </div>
        </div>
      </Popup>
    </>
  );
}

SearchDropdown.propTypes = {
  proposedCorrection: PropTypes.object,
  setProposedCorrection: PropTypes.func.isRequired
};

SearchDropdown.defaultProps = {
  proposedCorrection: {}
};

export default SearchDropdown;
