/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import I18n from '@utils/i18n';

// Add JQuery
const { $ } = window;

// Component which renders search select for Kendo grid filters
//
// NB. If you want to re-use it from Rails views - move to features/Layouts
function MultiSelect({
  name,
  value,
  label,
  selectOptions,
  jQueryEvents,
  itemTemplate,
  dataUrl,
  valueMapUrl,
  tagTemplate,
  staticOptions,
  placeholder
}) {
  const selectRef = selectOptions.ref || useRef();

  const multiRef = useRef();

  useEffect(() => {
    let dataSource = new kendo.data.DataSource({
      transport: {
        read: {
          url: dataUrl,
          dataType: 'json'
        }
      },
      schema: {
        total: 'total_count',
        data: 'data'
      },
      serverPaging: false,
      serverFiltering: false,
      serverSorting: false,
      requestEnd: function (e) {
        if (e.type === 'read' && staticOptions?.length > 0) {
          staticOptions.forEach((x) => {
            if (e.response) return e.response.data.unshift(x);

            return e;
          });
        }
      }
    });

    $(multiRef.current).kendoMultiSelect({
      autoWidth: true,
      placeholder,
      dataTextField: 'label',
      dataValueField: 'value',
      change: selectOptions.onChange,
      multiple: true,
      value,
      height: 420,
      dataSource: dataSource,
      itemTemplate,
      tagTemplate,
      messages: {
        clear: I18n.t('generic.clear'),
        deleteTag: I18n.t('generic.delete'),
        noData: I18n.t('generic.no_data')
      }
    });
  }, []);

  useEffect(() => {
    const multiselect = $(multiRef.current).data('kendoMultiSelect');
    multiselect.value(value);
  }, [value]);

  // TODO: remake somehow with native event handlers
  // Some of widgets are using JQuery and its events as a basis.
  // That's why sometimes you cannot use native event handlers, but need to treat them separately.
  // Better solution - highly appreciated
  const registerJQueryEvents = () => {
    if (jQueryEvents.onChange) {
      $(selectRef.current).on('change', jQueryEvents.onChange);
      delete selectOptions.onChange;
    }

    if (jQueryEvents.onKeyUp) {
      $(selectRef.current).on('keyup', jQueryEvents.onKeyUp);
      delete selectOptions.onKeyUp;
    }

    if (jQueryEvents.onKeyDown) {
      $(selectRef.current).on('keydown', jQueryEvents.onKeyDown);
      delete selectOptions.onKeyDown;
    }

    if (jQueryEvents.onClick) {
      $(selectRef.current).on('click', jQueryEvents.onClick);
      delete selectOptions.onClick;
    }

    if (jQueryEvents.onFocus) {
      $(selectRef.current).on('focus', jQueryEvents.onFocus);
      delete selectOptions.onFocus;
    }
  };

  const renderOptionDataAttrs = (dataInfo) => {
    const dataAttributes = {};
    if (!dataInfo) return dataAttributes;

    Object.keys(dataInfo).forEach((field) => {
      dataAttributes[`data-${field}`] = dataInfo[field];
    });

    return dataAttributes;
  };

  return (
    <>
      <select name={name} onChange={() => {}} {...selectOptions} ref={multiRef} multiple={true}></select>
      {label && (
        <label className="qmb-label" htmlFor={name}>
          {label}
        </label>
      )}
    </>
  );
}

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      data: PropTypes.object
    })
  ),
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholderValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  selectOptions: PropTypes.object,
  jQueryEvents: PropTypes.shape({
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func
  }),
  template: PropTypes.string,
  dataUrl: PropTypes.string,
  valueMapUrl: PropTypes.string,
  itemTemplate: PropTypes.string,
  tagTemplate: PropTypes.string
};

MultiSelect.defaultProps = {
  elements: [],
  value: undefined,
  label: undefined,
  placeholder: undefined,
  placeholderValue: '',
  selectOptions: {},
  jQueryEvents: {},
  template: '',
  dataUrl: '',
  valueMapUrl: '',
  itemTemplate: undefined,
  tagTemplate: undefined
};

export default MultiSelect;
/* eslint-enable react/jsx-props-no-spreading */
