import React from 'react';
import { Provider } from 'react-redux';
import I18n from '@utils/i18n';

import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import weekData from 'cldr-core/supplemental/weekData.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es/dateFields.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';
import { store } from './Redux/store';
import MapSchedulerContainer from './MapScheduler';

import esMessages from '../../locales/es.json';
import frMessages from '../../locales/fr.json';

load(weekData, esCaGregorian, esDateFields, frCaGregorian, frDateFields); // to i18n DateTimePicker component
loadMessages(esMessages, 'es');
loadMessages(frMessages, 'fr');

function MapScheduler({ selectOptions, assetsForSelect, configuration, permissions, timeZone, fleetTracking }) {
  return (
    <Provider store={store}>
      <LocalizationProvider language={I18n.locale}>
        <IntlProvider locale={I18n.locale}>
          <MapSchedulerContainer
            selectOptions={selectOptions}
            assetsForSelect={assetsForSelect}
            configuration={configuration}
            permissions={permissions}
            timeZone={timeZone}
            fleetTracking={fleetTracking}
          />
        </IntlProvider>
      </LocalizationProvider>
    </Provider>
  );
}
export default MapScheduler;
