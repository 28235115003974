import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import api from '@utils/axios';
import { simpleUpdateInspectionPath } from 'routes';
import ColumnMenuContext from './ColumnMenuContext';
import { actionTypes } from '../reducer';

function StatusCell({ tdProps, dataItem }) {
  const { statusesForSelect, dispatch } = useContext(ColumnMenuContext);
  const [edit, setEdit] = useState(false);
  const defaultStatus = {
    label: dataItem.humanizedStatus,
    value: dataItem.status,
    icon: dataItem.statusIcon,
    color: dataItem.statusColor
  };
  const [status, setStatus] = useState(defaultStatus);
  const dropdownRef = useRef(null);

  const statusItemRender = (element, itemProps) => {
    const { icon, color } = itemProps.dataItem;
    return React.cloneElement(element, element.props, statusRenderTemplate(element, icon, color));
  };

  const statusValueRender = (element, value) => {
    if (!value || !value.icon) return element;

    return React.cloneElement(element, element.props, statusRenderTemplate(element, value.icon, value.color));
  };

  const statusRenderTemplate = (element, icon, color) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          className="qmb-avatar--24--status-icon"
          style={{ display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}>
          <i className={`fa-lg ${icon}`} style={{ color }} />
        </span>
        <div style={{ marginLeft: '0.8rem' }}>{element.props.children}</div>
      </div>
    );
  };

  const onStatusChange = (event) => {
    api
      .put(simpleUpdateInspectionPath({ id: dataItem.id, format: 'json', _options: true }), {
        inspection: { status_code: event.target.value.value }
      })
      .then(({ data }) => {
        dispatch({ type: actionTypes.DATUM_CHANGED, data });
        setStatus({
          label: data.humanizedStatus,
          value: data.status,
          icon: data.statusIcon,
          color: data.statusColor
        });
        setEdit(false);
      });
  };

  useEffect(() => {
    if (dropdownRef.current) dropdownRef.current.focus();
  }, [edit]);

  return (
    <td {...tdProps}>
      {!edit && (
        <span
          role="button"
          onClick={() => {
            setEdit(true);
          }}>
          <i className={`fa-lg ${status.icon}`} style={{ color: status.color }} />
          &nbsp;{status.label}
        </span>
      )}
      {edit && (
        <DropDownList
          ref={dropdownRef}
          name="status"
          id="statusForSel"
          textField="label"
          dataItemKey="value"
          data={statusesForSelect}
          itemRender={statusItemRender}
          valueRender={statusValueRender}
          value={status}
          opened
          defaultItem={defaultStatus}
          onChange={onStatusChange}
          onBlur={() => {
            setEdit(false);
          }}
          size="large"
        />
      )}
    </td>
  );
}

StatusCell.propTypes = {
  tdProps: PropTypes.object.isRequired,
  dataItem: PropTypes.shape({
    status: PropTypes.string,
    id: PropTypes.number,
    humanizedStatus: PropTypes.string,
    statusColor: PropTypes.string,
    statusIcon: PropTypes.string
  }).isRequired
};

export default StatusCell;
