import React, { useCallback, useState } from 'react';
import Chip from '../../Chip/chip';
import { technicianAvatar, technicianTitle } from '@utils/functions/technician.functions';
function TechnicianMultiSelect(params) {
    const [searchValue, setSearchValue] = useState('');
    const handleTechToggle = useCallback((selectedTech) => {
        if (params.selectedTechnicians.some((x) => (x === null || x === void 0 ? void 0 : x.id) === (selectedTech === null || selectedTech === void 0 ? void 0 : selectedTech.id)))
            params.onChange(params.selectedTechnicians.filter((x) => (x === null || x === void 0 ? void 0 : x.id) !== (selectedTech === null || selectedTech === void 0 ? void 0 : selectedTech.id)));
        else
            params.onChange([...params.selectedTechnicians, selectedTech]);
    }, [params]);
    const handleSelectAllToggle = useCallback(() => {
        if (params.selectedTechnicians.length === params.technicianList.length)
            params.onChange([]);
        else
            params.onChange(params.technicianList);
    }, [params]);
    return (React.createElement("div", { className: "qmb-popup--actions--y-max" },
        React.createElement("div", { className: "popup__section" },
            React.createElement("input", { placeholder: 'Search Technicians', onChange: (e) => setSearchValue(e.target.value), style: { border: 'none', padding: '1.2rem' } })),
        React.createElement("hr", { className: "popup__split" }),
        React.createElement("div", { className: "popup__section--y-fill" },
            React.createElement("ul", { role: "presentation" }, params.technicianList
                .filter((x) => { var _a; return ((_a = x === null || x === void 0 ? void 0 : x.name) !== null && _a !== void 0 ? _a : 'Unassigned').toLowerCase().includes(searchValue === null || searchValue === void 0 ? void 0 : searchValue.toLowerCase()); })
                .map((tech) => {
                var _a;
                return (React.createElement("li", { key: `tech-option-${(_a = tech === null || tech === void 0 ? void 0 : tech.id) !== null && _a !== void 0 ? _a : 'unassigned'}` },
                    React.createElement("label", { className: "qmb-checkbox" },
                        React.createElement("input", { type: "checkbox", checked: params.selectedTechnicians.some((x) => (x === null || x === void 0 ? void 0 : x.id) === (tech === null || tech === void 0 ? void 0 : tech.id)), onChange: () => handleTechToggle(tech) }),
                        React.createElement(Chip, { icon: technicianAvatar(tech, 24), label: technicianTitle(tech) }))));
            }))),
        React.createElement("hr", { className: "popup__split" }),
        React.createElement("div", { className: "popup__section" },
            React.createElement("ul", { role: "presentation" },
                React.createElement("li", { key: `tech-option-select-all` },
                    React.createElement("label", { className: "qmb-checkbox" },
                        React.createElement("input", { type: "checkbox", onChange: handleSelectAllToggle, checked: params.selectedTechnicians.length === params.technicianList.length }),
                        "Select All"))))));
}
export default TechnicianMultiSelect;
