import { createSlice } from '@reduxjs/toolkit';

export const fleetTrackingSlice = createSlice({
  name: 'fleet',
  initialState: { active: false, azuga: true, techs: [] },
  reducers: {
    setFleetInfo: (state, action) => {
      state.active = action.payload.active;
      state.azuga = action.payload.has_azuga;
      state.techs = action.payload.technicians;
    },
    updateDrivers: (state, action) => {
      state.active = action.payload.active;
      state.techs = action.payload.technicians;
    },
    showFleetTrackingModal: (state, action) => {
      state.selectedTech = action.payload;
    },
    hideFleetTrackingModal: (state, action) => {
      state.selectedTech = null;
    },
    showAddPassengerPopup: (state, action) => {
      state.popup = true;
    },
    hideAddPassengerPopup: (state, action) => {
      state.popup = false;
    },
    updateSelectedTech: (state, action) => {
      state.selectedTech = state.techs.find((tech) => tech.id === action.payload.id);
    },
    showFleetDropdown: (state, action) => {
      state.dropdown = true;
    },
    hideFleetDropdown: (state, action) => {
      state.dropdown = false;
    }
  }
});

export const { setFleetInfo, updateDrivers, showFleetTrackingModal, hideFleetTrackingModal, showAddPassengerPopup, hideAddPassengerPopup, updateSelectedTech, hideFleetDropdown, showFleetDropdown } = fleetTrackingSlice.actions;

export default fleetTrackingSlice.reducer;
