export const DashboardEntityType = {
  Inspections: 'inspections',
  WorkOrders: 'workOrders',
  Invoices: 'invoices',
  Proposals: 'proposals',
  Deficiencies: 'deficiencies',
  Payments: 'payments',
  HighPriorities: 'highPriorities'
};

export const InitialState = {
  state: {
    entityType: null,
    tableData: [],
    isModalOpen: false,
    columnsStructureData: [],
    status: null,
    inspectionStatuses: [],
    workOrderStatuses: [],
    statusesSelection: {
      inspections: [],
      workOrders: []
    }
  },
  actions: {}
};

export const DefaultStatusesSelection = {
  inspections: [],
  workOrders: []
};

const WorkOrderStatus = {
  Closed: 1,
  Review: 2,
  FollowUp: 3,
  Open: 4,
  Test: 5,
  Cancelled: 6,
  Invoiced: 7,
  Paid: 8
};

export const BaseStatusesSelectionSort = {
  inspections: ['pending', 'scheduled', 'started', 'waiting_for_review', 'completed', 'cancelled'],
  workOrders: [
    WorkOrderStatus.Open,
    WorkOrderStatus.Review,
    WorkOrderStatus.FollowUp,
    WorkOrderStatus.Closed,
    WorkOrderStatus.Cancelled
  ]
};

export const MAXIMUM_ITEMS_AMOUNT = 10000;
