import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n, { translate } from '@utils/i18n';
import useDataTableContext from '../context/useDataTableContext';

const namespace = 'features.dashboards.home.deficiencies';

const { $ } = window;

function Deficiencies({ timeFrame, critical, impairment, nonCritical, expandLink, listingLink }) {
  const {
    actions: { loadDeficiencies }
  } = useDataTableContext();
  const criticalChartRef = useRef();
  const impairmentChartRef = useRef();
  const noncriticalChartRef = useRef();
  const widgetRef = useRef(null);
  const containerRef = useRef(null);

  const onLoadDeficiencies = (status) => {
    loadDeficiencies({ status });
  };

  const updateSparkline = () => {
    const baseWidget = containerRef.current?.firstElementChild;
    if (!baseWidget) return;

    const widgetPadding = 28;
    const areaWidth = baseWidget.clientWidth - widgetPadding * 2;
    const criticalSparkline = $(criticalChartRef.current).data('kendoSparkline');
    criticalSparkline.setOptions({ chartArea: { width: areaWidth } });

    const impairmentSparkline = $(impairmentChartRef.current).data('kendoSparkline');
    impairmentSparkline.setOptions({ chartArea: { width: areaWidth / 2 } });

    const noncriticalSparkline = $(noncriticalChartRef.current).data('kendoSparkline');
    noncriticalSparkline.setOptions({ chartArea: { width: areaWidth / 2.3 } });
  };

  useEffect(() => {
    const baseWidget = containerRef.current?.parentNode?.firstElementChild;

    const resizeObserver = new ResizeObserver(() => {
      updateSparkline();
    });

    if (baseWidget) {
      resizeObserver.observe(baseWidget);
    }
  }, [containerRef]);

  const drawSparkline = () => {
    const isMobile = 'ontouchstart' in window;
    const widgetStyle = window.getComputedStyle(widgetRef.current);
    const widgetPadding = parseInt(widgetStyle.getPropertyValue('padding-right'), 10);
    const areaWidth = widgetRef.current.clientWidth - widgetPadding * 2;

    $(criticalChartRef.current).kendoSparkline({
      type: 'area',
      chartArea: {
        width: areaWidth,
        height: 30
      },
      seriesDefaults: {
        color: '#F2E3E7',
        opacity: 1
      },
      tooltip: {
        color: 'white',
        visible: !isMobile
      },
      data: critical.histogram
    });

    $(impairmentChartRef.current).kendoSparkline({
      type: 'area',
      chartArea: {
        width: areaWidth / 2,
        height: 30
      },
      seriesDefaults: {
        color: '#E9E8E9',
        opacity: 1
      },
      tooltip: {
        color: 'white',
        visible: !isMobile
      },
      data: impairment.histogram
    });

    $(noncriticalChartRef.current).kendoSparkline({
      type: 'area',
      chartArea: {
        width: areaWidth / 2.3,
        height: 30
      },
      seriesDefaults: {
        color: '#E9E8E9',
        opacity: 1
      },
      tooltip: {
        color: 'white',
        visible: !isMobile
      },
      data: nonCritical.histogram
    });
  };

  useEffect(() => {
    drawSparkline();
  }, []);

  return (
    <div className="qmb-widget--deficiencies--sm" ref={containerRef}>
      <div className="widget__header">
        <svg className="widget__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
          <polygon points="64,0 0,64 0,0 " />
        </svg>
        <h3 className="widget__title">
          <a
            href={listingLink}
            className="title__link"
            title={I18n.t('features.dashboards.home.service_visits.view_all')}>
            {translate('title', { namespace })}
          </a>
          <small>{timeFrame}</small>
        </h3>
        <div className="widget__controls">
          <a href={expandLink} className="qmb-control--icon" title={translate('expand_title', { namespace })}>
            <i className="fa-light fa-chart-mixed" />
          </a>
        </div>
      </div>

      <div className="widget__body" ref={widgetRef}>
        <div className="widget__cell--x-full valueContainer">
          <button type="button" className="widget__counter" onClick={() => onLoadDeficiencies('critical')}>
            <span className="counter__label">{translate('critical', { namespace })}</span>
            <span className="counter__value--declined">{critical.count}</span>
          </button>
          <div ref={criticalChartRef} />
        </div>

        <div className="widget__cell valueContainer">
          <button type="button" className="widget__counter" onClick={() => onLoadDeficiencies('impairment')}>
            <span className="counter__label">{translate('impairment', { namespace })}</span>
            <span className="counter__value">{impairment.count}</span>
          </button>
          <div ref={impairmentChartRef} />
        </div>

        <div className="widget__cell valueContainer">
          <button type="button" className="widget__counter" onClick={() => onLoadDeficiencies('non_critical')}>
            <span className="counter__label">{I18n.t('features.dashboards.home.deficiencies.non_critical')}</span>
            <span className="counter__value">{nonCritical.count}</span>
          </button>
          <div ref={noncriticalChartRef} />
        </div>
      </div>
    </div>
  );
}

Deficiencies.defaultProps = {
  expandLink: '',
  listingLink: '',
  timeFrame: I18n.t('features.dashboards.home.timeFrame'),
  critical: { count: 0, histogram: [0] },
  impairment: { count: 0, histogram: [0] },
  nonCritical: { count: 0, histogram: [0] }
};

Deficiencies.propTypes = {
  expandLink: PropTypes.string,
  listingLink: PropTypes.string,
  timeFrame: PropTypes.string,
  critical: PropTypes.shape({
    count: PropTypes.number,
    histogram: PropTypes.arrayOf(PropTypes.number)
  }),
  impairment: PropTypes.shape({
    count: PropTypes.number,
    histogram: PropTypes.arrayOf(PropTypes.number)
  }),
  nonCritical: PropTypes.shape({
    count: PropTypes.number,
    histogram: PropTypes.arrayOf(PropTypes.number)
  })
};

export default Deficiencies;
