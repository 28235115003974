import React, { useCallback, useState } from 'react';
import Chip from '../../Chip/chip';
import { subcontractorIcon, subcontractorTitle } from '@utils/functions/subcontractor.functions';
function SubcontractorMultiSelect(params) {
    const [searchValue, setSearchValue] = useState('');
    const handleSubToggle = useCallback((selectedSub) => {
        if (params.selectedSubcontractors.some((x) => (x === null || x === void 0 ? void 0 : x.id) === (selectedSub === null || selectedSub === void 0 ? void 0 : selectedSub.id)))
            params.onChange(params.selectedSubcontractors.filter((x) => (x === null || x === void 0 ? void 0 : x.id) !== (selectedSub === null || selectedSub === void 0 ? void 0 : selectedSub.id)));
        else
            params.onChange([...params.selectedSubcontractors, selectedSub]);
    }, [params]);
    const handleSelectAllToggle = useCallback(() => {
        if (params.selectedSubcontractors.length === params.subcontractorList.length)
            params.onChange([]);
        else
            params.onChange(params.subcontractorList);
    }, [params]);
    return (React.createElement("div", { className: "qmb-popup--actions--y-max" },
        React.createElement("div", { className: "popup__section" },
            React.createElement("input", { placeholder: 'Search Subcontractors', onChange: (e) => setSearchValue(e.target.value), style: { border: 'none', padding: '1.2rem' } })),
        React.createElement("hr", { className: "popup__split" }),
        React.createElement("div", { className: "popup__section--y-fill" },
            React.createElement("ul", { role: "presentation" }, params.subcontractorList
                .filter((x) => { var _a; return ((_a = x === null || x === void 0 ? void 0 : x.name) !== null && _a !== void 0 ? _a : 'Unassigned').toLowerCase().includes(searchValue === null || searchValue === void 0 ? void 0 : searchValue.toLowerCase()); })
                .map((sub) => {
                var _a;
                return (React.createElement("li", { key: `tech-option-${(_a = sub === null || sub === void 0 ? void 0 : sub.id) !== null && _a !== void 0 ? _a : 'unassigned'}` },
                    React.createElement("label", { className: "qmb-checkbox" },
                        React.createElement("input", { type: "checkbox", onChange: () => handleSubToggle(sub), checked: params.selectedSubcontractors.some((x) => (x === null || x === void 0 ? void 0 : x.id) === (sub === null || sub === void 0 ? void 0 : sub.id)) }),
                        React.createElement(Chip, { icon: subcontractorIcon(sub, 24), label: subcontractorTitle(sub) }))));
            }))),
        React.createElement("hr", { className: "popup__split" }),
        React.createElement("div", { className: "popup__section" },
            React.createElement("ul", { role: "presentation" },
                React.createElement("li", { key: `tech-option-select-all` },
                    React.createElement("label", { className: "qmb-checkbox" },
                        React.createElement("input", { type: "checkbox", onChange: handleSelectAllToggle, checked: params.selectedSubcontractors.length === params.subcontractorList.length }),
                        "Select All"))))));
}
export default SubcontractorMultiSelect;
