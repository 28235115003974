import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

const MINUTES_IN_DAY = 1440;

function CurrentTimeIndicator() {
  const timeZone = useSelector((state) => state.settings.timeZone);
  const today = useMemo(() => DateTime.now().setZone(timeZone).toISODate(), []);
  const activeDay = useSelector((state) => state.scheduler.activeDay);
  const [right, setRight] = useState('');
  const timeAsPercentage = () => {
    const now = DateTime.now().setZone(timeZone);
    return now.diff(now.startOf('day')).as('minutes') / MINUTES_IN_DAY;
  };

  useEffect(() => {
    const headerDimensions = document.getElementById('scheduler-time-header').getBoundingClientRect();
    setRight((1 - timeAsPercentage()) * headerDimensions.width);
  }, [activeDay]);

  useEffect(() => {
    // On init, default the scheduler scroll to the current time
    const headerDimensions = document.getElementById('scheduler-time-header').getBoundingClientRect();
    document.getElementById('scheduler').scrollLeft = timeAsPercentage() * headerDimensions.width - 200;
  }, [timeZone]);

  if (today !== activeDay) return null;

  return (
    <div className="schedule__current-time" style={{ right: `${right}px` }}>
      <i className="schedule__current-point" />
    </div>
  );
}

export default CurrentTimeIndicator;
