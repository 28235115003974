import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  status,
  decrementActiveDay,
  incrementActiveDay,
  setActiveDayToToday,
  setStatus,
  addEvent,
  pushToSaveQueue,
  reassignEvent,
  unassignEvent,
  setActiveDay
} from '../Redux/schedulerSlice';
import { status as draggingStatuses, types, types as draggingTypes } from '../Redux/draggingSlice';
import { replaceVisit } from '../Redux/visitsSlice';
import I18n, { translate } from '@utils/i18n';
import {DatePicker} from "@progress/kendo-react-dateinputs";
import { DateTime } from 'luxon';

const namespace = 'features.map_scheduler';

function ActionBar() {
  const dispatch = useDispatch();
  const schedulerStatus = useSelector((state) => state.scheduler.status);
  const globalDragStatus = useSelector((state) => state.dragging.status);
  const globalDragType = useSelector((state) => state.dragging.type);
  const draggingData = useSelector((state) => state.dragging.data);

  const renderAction = () => {
    if (
      schedulerStatus === status.REPOSITIONING ||
      (globalDragStatus === draggingStatuses.DRAGGING && globalDragType === draggingTypes.EVENT)
    ) {
      return <Unassign />;
    }

    switch (schedulerStatus) {
      case status.LOADING:
        return <Spinner title={I18n.t('generic.loading')} />;
      case status.SAVING:
        return <Spinner title={schedulerStatus} />;
      case status.READY:
      default:
        return <PaginationActions />;
    }
  };

  const conditionalClasses = () => {
    let result = '';
    if (
      schedulerStatus === status.REPOSITIONING ||
      (globalDragStatus === draggingStatuses.DRAGGING && globalDragType === draggingTypes.EVENT)
    )
      result += '--unassign';
    return result;
  };

  const HandleMouseUp = (e) => {
    if (globalDragStatus === draggingStatuses.DRAGGING && globalDragType === types.EVENT) {
        let event = JSON.parse(JSON.stringify(draggingData))
        event.technicianId = null;
        event.technician = null;
        dispatch(unassignEvent({event: event}));
        dispatch(replaceVisit(event));
    }
  };

  return (
    <>
      <div id="scheduler-header-actions" className={`schedule__actions${conditionalClasses()}`} onMouseUp={HandleMouseUp}>
        <h2 className="pane__title">{translate('scheduler.title', { namespace })}</h2>
        {renderAction()}
      </div>
    </>
  );
}

function Spinner({ title }) {
  return (
    <div className="qmb-loading--32--alt" data-title={title}>
      <svg role="img">
        <use href="/map.svg#load-spinner" />
      </svg>
    </div>
  );
}

function PaginationActions() {
  const dispatch = useDispatch();
  const activeDay = DateTime.fromISO(useSelector((state) => state.scheduler.activeDay));
  const [show, setShow] = useState(false);

  const CustomInput = (props) => {
    const formattedDate = DateTime.fromJSDate(props.value).setLocale(I18n.locale).toLocaleString(DateTime.DATE_MED);
    return (
        <React.Fragment>
          <span className="qmb-control schedule__day" onClick={() => setShow(!show)} onDoubleClick={() => dispatch(setActiveDayToToday())}>
            {formattedDate}
          </span>
        </React.Fragment>
    )
  }

  return (
    <div className="schedule__controls">
      <DatePicker
          onBlur={() => setShow(false)}
          className={"border-none"}
          value={activeDay.toJSDate()}
          dateInput={CustomInput}
          toggleButton={() => null}
          show={show}
          onChange={(e) => {dispatch(setActiveDay(e.value.toISOString())); setShow(false)}}
      />

      <button className="qmb-control--icon" onClick={() => dispatch(decrementActiveDay())}>
        <i className="fa-light fa-angle-left" />
      </button>

      <button className="qmb-control--icon" onClick={() => dispatch(incrementActiveDay())}>
        <i className="fa-light fa-angle-right" />
      </button>

    </div>
  );
}

function Unassign() {
  return (
    <div className="schedule__controls">
      <span>Unassign</span>
      <i className="fa-solid fa-recycle" />
    </div>
  );
}

function Error() {
  const dispatch = useDispatch();

  return (
    <>
      <span style={{ color: 'red' }}>An Error Occurred.</span>
      <span style={{ cursor: 'pointer', color: 'red' }} onClick={(e) => dispatch(setStatus(status.READY))}>
        <i className="fa-solid fa-circle-exclamation" />
        <span>Dismiss</span>
      </span>
    </>
  );
}

export default ActionBar;
