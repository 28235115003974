import { translate } from '@utils/i18n';
import BuildingNameCell from './nested/BuildingNameCell';

export const CACHE_KEY = 'kendo/buildings/index';

export const NAMESPACE = 'buildings.datatable';

export const INITIAL_PAGE_SKIP = 0;

export const INITIAL_PAGE = { skip: INITIAL_PAGE_SKIP, take: 25 };

export const INITIAL_SORT = [
  {
    field: 'name',
    dir: 'asc'
  }
];

export const INITIAL_FILTERS = {
  values: {
    account: '',
    status: 'true',
    tags: '',
    territory: '',
    name: ''
  },
  active: {
    account: true,
    status: true,
    tags: true,
    territory: true,
    name: true
  },
  operators: { name: 'contains' }
};

export const ALL_COLUMNS = [
  'name',
  'address',
  'city',
  'state',
  'zip',
  'territory',
  'contractExpiration',
  'buildingNumber',
  'referenceNumber',
  'siteId',
  'inspectionsCount'
];

export const ALL_COLUMNS_DATA = {
  name: {
    id: 'name',
    field: 'name',
    title: translate('table.headers.name', { namespace: NAMESPACE }),
    width: 200,
    cell: BuildingNameCell
  },
  address: {
    id: 'address',
    field: 'address',
    width: 200,
    title: translate('table.headers.address', { namespace: NAMESPACE })
  },
  city: {
    id: 'city',
    field: 'city',
    width: 200,
    title: translate('table.headers.city', { namespace: NAMESPACE })
  },
  state: {
    id: 'state',
    field: 'state',
    width: 110,
    title: translate('table.headers.state', { namespace: NAMESPACE })
  },
  zip: {
    id: 'zip',
    field: 'zip',
    width: 150,
    title: translate('table.headers.zip', { namespace: NAMESPACE })
  },
  territory: {
    id: 'territory',
    field: 'territory',
    width: 110,
    title: translate('table.headers.territory', { namespace: NAMESPACE })
  },
  contractExpiration: {
    id: 'contractExpiration',
    field: 'contractExpiration',
    width: 150,
    title: translate('table.headers.contract_expiration', { namespace: NAMESPACE })
  },
  buildingNumber: {
    id: 'buildingNumber',
    field: 'buildingNumber',
    width: 150,
    title: translate('table.headers.building_number', { namespace: NAMESPACE })
  },
  referenceNumber: {
    id: 'referenceNumber',
    field: 'referenceNumber',
    width: 150,
    title: translate('table.headers.reference_number', { namespace: NAMESPACE })
  },
  siteId: {
    id: 'siteId',
    field: 'siteId',
    width: 110,
    title: translate('table.headers.site_id', { namespace: NAMESPACE }),
    sortable: false
  },
  inspectionsCount: {
    id: 'inspectionsCount',
    field: 'inspectionsCount',
    width: 180,
    title: translate('table.headers.inspections_count', { namespace: NAMESPACE }),
    sortable: false
  }
};

export const MIN_ADDRESS_CELL_WIDTH = 170;
export const MIN_NAME_CELL_WIDTH = 200;
