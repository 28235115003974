import React, { useEffect, useMemo } from 'react';
function Error(params) {
    useEffect(() => {
        console.error(params);
    }, [params]);
    const userMessage = useMemo(() => {
        if (!params.error)
            return 'An Error Occurred.';
        if (typeof params.error === 'string')
            return params.error;
        return params.error.userMessage || 'An Error Occurred.';
    }, [params]);
    return (React.createElement("div", { style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '2rem',
            alignSelf: 'center',
            justifySelf: 'center',
            flex: 1
        } },
        React.createElement("i", { className: "fa-duotone fa-triangle-exclamation", style: { fontSize: '8rem' } }),
        userMessage));
}
export default Error;
