// Solution to render react component generated by nested_fields_for
// Used only on:
// - Proposals fee.html.erb
// - Work Orders fee.html.erb

import React, { useEffect } from 'react';

function ReactDynamicComponentLoader({}) {
  useEffect(() => {
    $(document).on('fields_added.nested_form_fields', function (event, param) {
      const toRender = event.target.querySelectorAll('.react-component-manual-render');
      console.log(toRender)
      if (toRender.length > 0) {
        ReactRailsUJS.mountComponents(event.target);
      }
    });
  }, []);
  return null;
}

export default ReactDynamicComponentLoader;

// import React, { useEffect } from 'react';
// import ReactDOM from 'react-dom';
// import ProposalGroupButton from './GroupLookup/ProposalGroupButton';
// import ProposalGroupWatcher from './GroupLookup/ProposalGroupWatcher';
// import MaterialServicesAutocomplete from './WorkOrders/MaterialServicesAutocomplete';
// import ProposalMaterialServicesAutocomplete from './Proposals/ProposalMaterialServicesAutocomplete';
// import InvoiceMaterialServicesAutocomplete from './Invoices/InvoiceMaterialServicesAutocomplete';

// function ReactDynamicComponentLoader({}) {
//   useEffect(() => {
//     $(document).on('fields_added.nested_form_fields', function (event, param) {
//       const name = event?.target?.firstElementChild?.name;
//       if (name) {
//         const toRender = event.target.querySelectorAll('.react-component-manual-render');
//         toRender.forEach((element) => {
//           const reactClass = element.getAttribute('data-react-class');
//           const props = JSON.parse(element.getAttribute('data-react-props'));
//           if (reactClass === 'Proposals/ProposalMaterialServicesAutocomplete') {
//             ReactDOM.render(<ProposalMaterialServicesAutocomplete {...props} />, element);
//           } else if (reactClass === 'Invoices/InvoiceMaterialServicesAutocomplete') {
//             ReactDOM.render(<InvoiceMaterialServicesAutocomplete {...props} />, element);
//           } else if (
//             name.includes('[proposal_proposed_corrections_attributes]') ||
//             name.includes('[proposal_line_items_attributes]')
//           ) {
//             ReactDOM.render(<ProposalGroupButton {...props} />, element);
//           } else if (name.includes('[proposal_summary_items_attributes]')) {
//             ReactDOM.render(<ProposalGroupWatcher {...props} />, element);
//           } else if (name.includes('[work_order_corrections_attributes]')) {
//             ReactDOM.render(<MaterialServicesAutocomplete {...props} />, element);
//           }
//         });
//       }
//     });
//   }, []);
//   return null;
// }

// export default ReactDynamicComponentLoader;
