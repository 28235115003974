import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { groupBy, map } from '@helpers/other';
import I18n from '@utils/i18n';

function NavigationPanel({ state, onExpand, focusElement }) {
  const { photosCount } = state;
  const [focusInternal, setFocusInternal] = useState(focusElement);
  const [expanded, setExpanded] = useState(true);

  const handleMonths = (monthsCount) =>
    map(monthsCount, ([yearMonth, count]) => ({ yearMonth, count, month: yearMonth % 100 })).reverse();

  const byYearArray = useMemo(() => {
    const byYear = groupBy(photosCount, ([yearMonth]) => Math.floor(yearMonth / 100));
    return map(byYear, (monthsCount, year) => ({ year, months: handleMonths(monthsCount) })).reverse();
  }, [photosCount]);

  useEffect(() => {
    if (focusElement) {
      setFocusInternal(focusElement);
    }
  }, [focusElement]);

  const onClickItem = (yearMonth) => {
    setFocusInternal(focusElement);
    onExpand(yearMonth);
  };

  return (
    <aside className={`navigation-panel tab__aside${expanded ? '--expanded' : ''}`}>
      <div>
        {byYearArray.map(({ year, months }, index) => (
          <React.Fragment key={year}>
            <div className="aside__section">
              <h2 className="aside__title">{year}</h2>
              <ul role="presentation">
                {months.map(({ yearMonth, month, count }) => (
                  <li key={yearMonth}>
                    <button
                      type="button"
                      onClick={() => {
                        onClickItem(yearMonth);
                      }}
                      className={`qmb-control${focusInternal === yearMonth ? '--current' : ''}`}>
                      <span>{I18n.t('date.month_names')[month]}</span>
                      <span className="control__count">{count}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            {index < byYearArray.length - 1 && <hr className="aside__split" />}
          </React.Fragment>
        ))}
      </div>
      <div className="aside__toggle">
        <button
          onClick={() => {
            setExpanded(!expanded);
          }}
          type="button"
          className="qmb-control--icon">
          <i className={`fa-light ${expanded ? 'fa-arrow-right-from-line' : 'fa-arrow-left-from-line'}`} />
        </button>
      </div>
    </aside>
  );
}

NavigationPanel.propTypes = {
  state: PropTypes.object.isRequired,
  onExpand: PropTypes.func.isRequired,
  focusElement: PropTypes.string
};

NavigationPanel.defaultProps = {
  focusElement: ''
};

export default NavigationPanel;
