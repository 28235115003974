import React from 'react';
import ReactDOM from 'react-dom/server';
// If it would create problems in the future, it's recommended to re-write
// to plain HTML instead of using the component.
import Loader from '@components/Loader';

import I18n from './i18n';

const labels = {
  sEmptyTable: I18n.t('datatables.system.sEmptyTable'),
  sInfo: I18n.t('datatables.system.sInfo'),
  sInfoEmpty: I18n.t('datatables.system.sInfoEmpty'),
  sInfoFiltered: I18n.t('datatables.system.sInfoFiltered'),
  sInfoPostFix: I18n.t('datatables.system.sInfoPostFix'),
  sInfoThousands: I18n.t('datatables.system.sInfoThousands'),
  sLengthMenu: I18n.t('datatables.system.sLengthMenu'),
  sLoadingRecords: I18n.t('datatables.system.sLoadingRecords'),
  sProcessing: I18n.t('datatables.system.sProcessing'),
  sSearch: I18n.t('datatables.system.sSearch'),
  sZeroRecords: I18n.t('datatables.system.sZeroRecords'),
  oPaginate: {
    sFirst: I18n.t('datatables.system.oPaginate.sFirst'),
    sLast: I18n.t('datatables.system.oPaginate.sLast'),
    sNext: I18n.t('datatables.system.oPaginate.sNext'),
    sPrevious: I18n.t('datatables.system.oPaginate.sPrevious')
  },
  oAria: {
    sSortAscending: I18n.t('datatables.system.oAria.sSortAscending'),
    sSortDescending: I18n.t('datatables.system.oAria.sSortDescending')
  }
};

const getDatatableLabels = (object = {}) => {
  return {
    ...labels,
    sProcessing: ReactDOM.renderToString(<Loader isLoading />),
    ...object
  };
};

export default getDatatableLabels;
