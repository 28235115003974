import React from 'react';
import PropTypes from 'prop-types';
import './RadioGroup.scss';

export default function RadioGroup({ label, name, options, onChange, value }) {
  const onChangeValue = (event) => {
    onChange(event.target.value);
  };

  const onClickLabel = (newValue) => {
    onChange(newValue);
  };
  return (
    <div className="qmb-radio-group--x-full">
      <label htmlFor={name} className="qmb-label">
        {label}
      </label>
      <div className="qmb-radio-group radioGroupContainer">
        {options.map((option) => (
          <div className="qmb-radio-group__item" key={option.value}>
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={option.value === value}
              onChange={onChangeValue}
            />
            <label htmlFor={option.value} onClick={() => onClickLabel(option.value)}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

RadioGroup.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired
};
