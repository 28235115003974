import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

function PaymentsDisabled({ link }) {
  return (
    <div className="qmb-widget--sm" style={{ backgroundColor: '#ddd' }}>
      <div className="widget__header">
        <svg className="widget__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
          <polygon points="64,0 0,64 0,0 " />
        </svg>
        <h3 className="widget__title">{I18n.t('features.dashboards.home.payments.title')}</h3>
      </div>

      <div className="widget__body" style={{ display: 'flex' }}>
        <div dangerouslySetInnerHTML={{ __html: I18n.t('invoices.index.payments_blurb', { link }) }} />
      </div>
    </div>
  );
}

PaymentsDisabled.propTypes = {
  link: PropTypes.string.isRequired
};

export default PaymentsDisabled;
