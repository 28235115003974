import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GridColumnMenuSort, GridColumnMenuColumnsList } from '@progress/kendo-react-grid';
import ColumnMenuContext from './ColumnMenuContext';

function ColumnMenu(props) {
  const { columnsState, columns, onColumnsChange } = useContext(ColumnMenuContext);
  const { onCloseMenu = () => {} } = props;
  return (
    <div>
      {props.sortable && <GridColumnMenuSort {...props} />}
      <GridColumnMenuColumnsList
        columnsState={columnsState}
        columns={columns}
        onColumnsChange={onColumnsChange}
        onCloseMenu={onCloseMenu}
      />
    </div>
  );
}

ColumnMenu.propTypes = {
  sortable: PropTypes.bool.isRequired,
  onCloseMenu: PropTypes.func.isRequired
};

export default ColumnMenu;
