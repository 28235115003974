/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import './Select.scss';

const namespace = 'features.commons';

const Select = React.forwardRef(
  (
    {
      id,
      options,
      onChange,
      onBlur,
      name,
      disabled,
      label,
      noOptionLabel = translate('select_an_option', { namespace }),
      required = false
    },
    ref
  ) => {
    return (
      <div className="qmb-select--x-full selectContainer">
        <select id={id} name={name} ref={ref} onChange={onChange} onBlur={onBlur} disabled={disabled}>
          <option value="" disabled={required}>
            {noOptionLabel}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <label htmlFor={id} className="qmb-label">
          {label}
        </label>
      </div>
    );
  }
);

Select.displayName = 'Select';

export default Select;

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  id: PropTypes.string.isRequired,
  noOptionLabel: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool
};

Select.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  noOptionLabel: translate('select_an_option', { namespace }),
  name: '',
  disabled: false,
  label: '',
  required: false
};
