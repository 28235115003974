/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Add JQuery
const { $ } = window;

// NB. If you want to re-use it from Rails views - move to features/Layouts
function DatePicker({ name, value, label, reactOptions, datePickerOptions }) {
  const datePickerRef = reactOptions.ref || useRef();

  useEffect(() => {
    $(datePickerRef.current).datepicker({ ...datePickerOptions });

    registerEvents();
  }, []);

  const registerEvents = () => {
    // TODO: remake somehow with native event handlers
    if (reactOptions.onChange) {
      $(datePickerRef.current).on('change', reactOptions.onChange);
      delete reactOptions.onChange;
    }

    if (reactOptions.onKeyUp) {
      $(datePickerRef.current).on('keyup', reactOptions.onKeyUp);
      delete reactOptions.onKeyUp;
    }

    if (reactOptions.onKeyDown) {
      $(datePickerRef.current).on('keydown', reactOptions.onKeyDown);
      delete reactOptions.onKeyDown;
    }

    if (reactOptions.onClick) {
      $(datePickerRef.current).on('click', reactOptions.onClick);
      delete reactOptions.onClick;
    }

    if (reactOptions.onFocus) {
      $(datePickerRef.current).on('focus', reactOptions.onFocus);
      delete reactOptions.onFocus;
    }
  };

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <input type="text" name={name} value={value} placeholder=" " {...reactOptions} ref={datePickerRef} />
    </>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  reactOptions: PropTypes.object,
  datePickerOptions: PropTypes.object
};

DatePicker.defaultProps = {
  value: undefined,
  label: undefined,
  reactOptions: {},
  datePickerOptions: {}
};

export default DatePicker;
/* eslint-enable react/jsx-props-no-spreading */
