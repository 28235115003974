export const BASE_ALPHA_VALUE = 0.16;

export function addTransparency(hexColor, transparency) {
  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Combine the RGB values with the alpha value
  const rgbaColor = `rgba(${r}, ${g}, ${b}, ${transparency})`;

  return rgbaColor;
}
