import React, { useMemo, useCallback, useState } from 'react';
import I18n from '@utils/i18n';
import PropTypes from 'prop-types';

import { DropDownTree } from '@progress/kendo-react-dropdowns';
import { mapTree, extendDataItem } from '@progress/kendo-react-common';
import { filterBy } from '@progress/kendo-react-data-tools';

function TreeSelect({ name, value, label, onChange, elements, placeholder }) {
  const [expanded, setExpanded] = useState([]);
  const [filter, setFilter] = useState('');

  const treeData = useMemo(() => {
    const filtering = Boolean(filter && filter.value);
    const filteredData = filtering ? filterBy(elements, [filter], 'items') : elements;

    return mapTree(filteredData, 'items', (item) => {
      const props = {
        expanded: filtering || expanded.includes(item.value),
        selected: value && item.value === value
      };

      if (filtering) return extendDataItem(item, 'items', props);
      return { ...item, ...props };
    });
  }, [expanded, value, filter]);

  const treeSelectValue = useMemo(() => {
    if (!value) return null;

    return (
      elements.find((elem) => elem.value === value) ||
      elements
        .map((elem) => elem.items || [])
        .flat()
        .find((elem) => elem.value === value)
    );
  }, [value]);

  const onExpandChange = useCallback(
    (event) => {
      const nextExpanded = expanded.slice();
      const itemKey = event.item.value;
      const index = expanded.indexOf(itemKey);
      index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
      setExpanded(nextExpanded);
    },
    [expanded]
  );

  const handleOnChange = useCallback((event) => onChange(event.value?.value), []);

  const onFilterChange = useCallback((event) => setFilter(event.filter), []);

  return (
    <>
      {label && (
        <label className="qmb-label" htmlFor={name}>
          {label}
        </label>
      )}
      <DropDownTree
        placeholder="All"
        data={treeData}
        value={treeSelectValue}
        onChange={handleOnChange}
        dataItemKey="value"
        textField="label"
        subItemsField="items"
        selectField="selected"
        expandField="expanded"
        onExpandChange={onExpandChange}
        filterable
        onFilterChange={onFilterChange}
        filter={filter.value}
      />
    </>
  );
}

export default TreeSelect;

TreeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

TreeSelect.defaultProps = {
  label: undefined,
  placeholder: '',
  onChange: null
};
