import React, {useEffect, useReducer, useRef, useState} from "react";
import {actionTypes, reducer} from "./TagsReducer";
import { Popup } from "@progress/kendo-react-popup";
import I18n from '@utils/i18n';


function Tags({existingTags = [], attributeName, showHeader = true}){

    const [state, dispatch] = useReducer(reducer, {tags: existingTags});
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const anchor = useRef(null)

    const AddTag = (value) => {
        setDropdownOpen(false);
        dispatch({ type: actionTypes.ADD_TAG, value});
    }

    const RemoveTagEvent = (value) => {
        dispatch({ type: actionTypes.REMOVE_TAG, value});
    }


    return(
        <div className="form__set--horizontal--tags">
            {showHeader && <legend>{I18n.t('generic.tags')}</legend>}
            {state.tags.map(t => <Tag key={t} value={t} RemoveTagEvent={RemoveTagEvent} attributeName={attributeName}/>)}

            <button ref={anchor} className="qmb-control--icon--add new-tag-btn" type="button"
                onClick={(e) => {e.stopPropagation(); setDropdownOpen(!dropdownOpen)}}/>

            <Popup anchor={anchor.current} show={dropdownOpen}>
                <TagDropdown AddTag={AddTag} ClosePopup={() => setDropdownOpen(false)}/>
            </Popup>
        </div>
    )

}

function TagDropdown({AddTag, ClosePopup}){
    const searchRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true)
    const [availableTags, setAvailableTags] = useState([])
    const [filteredTags, setFilteredTags] = useState([])

    useEffect(()=>{
        searchRef.current.focus();

        fetchTags()
            .then((result) => {
                setAvailableTags(result.data.map(t => t.label));
                setFilteredTags(result.data.map(t => t.label))
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error)
            })

        document.body.addEventListener('click', ClosePopup)

        return () => {
            document.body.removeEventListener('click', ClosePopup)
        }

    }, [])

    const HandleKeyUp = (e) => {
        if (e.key === 'Enter'){
            AddTag(e.target.value);
        }
        else {
            setFilteredTags(availableTags.filter(t => t.toLowerCase().startsWith(e.target.value.toLowerCase())))
        }
    }

    const Content = () => {
        if (isLoading){
            return <LoadingSpinner/>
        } else {
            if (filteredTags.length > 0)
                return filteredTags.map(x => <DropdownItem key={x} value={x} HandleClick={AddTag} />)
            else
                return <NoMatch HandleClick={() => AddTag(searchRef.current.value)} />
        }
    }

    return(
        <div tabIndex={0}>
            <div className={"qmb-input--blank"}>
                <input ref={searchRef} onKeyUp={HandleKeyUp}/>
            </div>

            <div style={{display: "flex", flexDirection: "column", maxHeight: "120px", overflowY: "auto" }}>
                <Content/>
            </div>
        </div>
    )
}

function LoadingSpinner({}){
    return (
        <div className="qmb-loading--32--vertical" data-title="Loading" style={{padding: "2rem", alignSelf: "center", flex: "1 1 auto"}}>
            <svg role="img">
                <use href="/map.svg#load-spinner"/>
            </svg>
        </div>
    )
}

function DropdownItem({value, HandleClick}){
    return (
        <button className="qmb-control" onClick={() => HandleClick(value)}>
            {value}
        </button>
    )
}

function NoMatch({HandleClick}){
    return (
        <button className={"qmb-control"} onClick={HandleClick}>
            {I18n.t('generic.create_new_tag')}<i className="fa-light fa-turn-down-left"></i>
        </button>
    )
}

function Tag({value, RemoveTagEvent, attributeName}){
    return (
        <span className={"qmb-tag"}>
            {value}
            <input type="hidden" name={attributeName} value={value}/>
            <button className="qmb-control--icon fa-light fa-xmark" type="button" onClick={() => {RemoveTagEvent(value)}}/>
        </span>
    )
}

export const fetchTags = async () => {
    const response = await fetch(`/lookup?type=tags`);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return await response.json();
}

export default Tags;
