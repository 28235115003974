import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: { timeZone: 'Etc/UTC' },
  reducers: {
    setSettings: (state, action) => {
      state.timeZone = action.payload.timeZone;
    }
  }
});

export const { setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
