/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import I18n from '@utils/i18n';
import CommonSelect from './CommonSelect';

// Component which renders common select with Yes, No options for Kendo grid filters
//
// NB. If you want to re-use it from Rails views - move to features/Layouts
function BooleanSelect({
  name,
  value,
  label,
  placeholder,
  placeholderValue,
  selectOptions,
  jQueryEvents,
  trueLabel,
  falseLabel
}) {
  const elements = [
    { label: trueLabel, value: true },
    { label: falseLabel, value: false }
  ];

  return (
    <CommonSelect
      name={name}
      value={value}
      elements={elements}
      label={label}
      placeholder={placeholder}
      placeholderValue={placeholderValue}
      selectOptions={selectOptions}
      jQueryEvents={jQueryEvents}
    />
  );
}

BooleanSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  trueLabel: PropTypes.string,
  falseLabel: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholderValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  selectOptions: PropTypes.object,
  jQueryEvents: PropTypes.shape({
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func
  })
};

BooleanSelect.defaultProps = {
  value: undefined,
  label: undefined,
  placeholder: undefined,
  placeholderValue: '',
  selectOptions: {},
  trueLabel: I18n.t('generic.yes'),
  falseLabel: I18n.t('generic.no'),
  jQueryEvents: {}
};

export default BooleanSelect;
/* eslint-enable react/jsx-props-no-spreading */
