import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import { Loader, LoaderSizes } from '../Loader/Loader';
import './InputTextArea.scss';

const namespace = 'features.commons';

export const StyleVariant = {
  Light: 'light',
  Dark: 'dark'
};

function InputTextArea({
  id,
  onSave,
  label,
  loading,
  value,
  editLeftControl,
  leftControl,
  rightControl,
  placeholder,
  onCancel,
  buttonControlled = true,
  variant = StyleVariant.Dark,
  onChange
}) {
  const [internalValue, setInternalValue] = useState(value);
  const [isEditing, setIsEditing] = useState(!buttonControlled);
  const inputRef = useRef();

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    onChange(internalValue);
  }, [internalValue]);

  const onRequestEdit = () => {
    setIsEditing(true);
    inputRef.current.focus();
  };

  const onCancelClick = () => {
    setIsEditing(false);
    setInternalValue(value || '');
    if (onCancel) onCancel();
  };

  useLayoutEffect(() => {
    autoGrowTextZone();
  }, [internalValue]);

  const autoGrowTextZone = () => {
    inputRef.current.style.height = 'inherit';
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
  };

  const onRequestSave = async () => {
    await onSave(internalValue);
    setIsEditing(false);
  };

  const stopPropagation = (event) => {
    if (!isEditing) return;
    event.stopPropagation();
  };

  return (
    <div
      className={clsx('qmb-textarea--inverted--edit--x-full inputTextAreaContainer', {
        lightVariant: variant === StyleVariant.Light
      })}
      role="presentation"
      onPointerDown={stopPropagation}
      onKeyDown={stopPropagation}
      onWheel={stopPropagation}>
      <textarea
        ref={inputRef}
        id={id}
        onClick={onRequestEdit}
        style={{ touchAction: 'pan-y' }}
        value={internalValue}
        onChange={(e) => setInternalValue(e.target.value)}
        readOnly={!isEditing}
        placeholder={placeholder}
      />

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id} className="qmb-label">
        {label} {!isEditing && <i className="fa-light fa-pencil" />}
      </label>

      {buttonControlled && (
        <div className="textarea__controls">
          {isEditing ? editLeftControl : leftControl}

          {isEditing ? (
            <>
              <button className="qmb-control" onClick={onCancelClick} type="button" disabled={loading}>
                {translate('cancel', { namespace })}
              </button>
              {!loading ? (
                <button className="qmb-control--highlight" onClick={onRequestSave} type="button">
                  {translate('save', { namespace })}
                </button>
              ) : (
                <Loader size={LoaderSizes.small} />
              )}
            </>
          ) : (
            rightControl
          )}
        </div>
      )}
    </div>
  );
}

export default InputTextArea;

InputTextArea.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  editLeftControl: PropTypes.node,
  leftControl: PropTypes.node,
  buttonControlled: PropTypes.bool,
  rightControl: PropTypes.node,
  variant: PropTypes.string,
  onChange: PropTypes.func
};

InputTextArea.defaultProps = {
  placeholder: '--',
  onCancel: () => {},
  onSave: () => {},
  onChange: () => {},
  value: '',
  loading: false,
  id: '',
  label: '',
  buttonControlled: true,
  editLeftControl: null,
  leftControl: null,
  rightControl: null,
  variant: StyleVariant.Dark
};
