import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';

import { apiInternalVisitPath } from 'routes';
import './Visits.scss';
import api from '@utils/axios';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from '../../../components/Accordion/Accordion';
import Table, { TableSize } from '../../../components/Table/Table';
import getVisitsColumns from './visitsColumns';
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button/Button';
import NewVisitModal from './NewVisitModal';
import EditVisitModal from './EditVisitModal';

const namespace = 'features.work_orders';

function VisitsSection({
  title,
  visits: initialVisits,
  workOrderId,
  subcontractors,
  technicians,
  visitStatuses,
  defaultTechnicianId
}) {
  const [isAddOpen, setIsAddOpen] = React.useState(false);
  const [visitEditing, setVisitEditing] = React.useState(null);
  const [visits, setVisits] = React.useState(initialVisits);
  const [visitDeleting, setVisitDeleting] = React.useState(null);

  const onRequestEdit = (visitId) => {
    const selectedVisit = visits.find((visit) => visit.id === visitId);
    setVisitEditing(selectedVisit);
  };

  const onRequestAdd = () => {
    setIsAddOpen(true);
  };

  const onAddComplete = (newVisitInfo) => {
    setVisits([newVisitInfo, ...visits]);
    setIsAddOpen(false);
  };

  const onEditComplete = (newVisitInfo) => {
    setVisits(visits.map((visit) => (visit.id === newVisitInfo.id ? { ...visit, ...newVisitInfo } : visit)));
    setVisitEditing(null);
  };

  const onDeleteVisit = async (visitId) => {
    try {
      setVisitDeleting(visitId);

      await api.delete(apiInternalVisitPath({ id: visitId, format: 'json' }));

      setVisits(visits.filter((visit) => visit.id !== visitId));
    } catch (error) {
      toast.error(translate('delete_error', { namespace }), {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setVisitDeleting(null);
    }
  };

  return (
    <>
      <div className="span10 visitSection">
        <Toaster position="bottom-right" />
        <Accordion
          defaultExpanded
          title={
            <span className="header" id="work-order-visits">
              {title}
            </span>
          }>
          <div className="visitSectionContent">
            {visits.length > 0 && (
              <Table
                size={TableSize.Full}
                data={visits}
                columns={getVisitsColumns(
                  onRequestEdit,
                  onDeleteVisit,
                  visitStatuses,
                  visitDeleting,
                  subcontractors,
                  technicians
                )}
              />
            )}
            <Button onClick={onRequestAdd} variant={ButtonVariant.BlueAccent} size={ButtonSize.Full}>
              <i className="fa-light fa-circle-plus" />
              {translate('add_visit', { namespace })}
            </Button>
          </div>
        </Accordion>
      </div>

      <NewVisitModal
        woId={workOrderId}
        vendorOptions={subcontractors}
        techOptions={technicians}
        isOpen={isAddOpen}
        onClose={() => setIsAddOpen(false)}
        defaultTechnicianId={defaultTechnicianId}
        statusesOptions={visitStatuses}
        onAddComplete={onAddComplete}
      />
      <EditVisitModal
        woId={workOrderId}
        vendorOptions={subcontractors}
        techOptions={technicians}
        visit={visitEditing}
        isOpen={!!visitEditing}
        onClose={() => setVisitEditing(null)}
        statusesOptions={visitStatuses}
        onEditComplete={onEditComplete}
      />
    </>
  );
}

VisitsSection.propTypes = {
  title: PropTypes.string,
  visits: PropTypes.array,
  workOrderId: PropTypes.number.isRequired,
  technicians: PropTypes.array,
  defaultTechnicianId: PropTypes.number,
  subcontractors: PropTypes.array,
  visitStatuses: PropTypes.array
};

VisitsSection.defaultProps = {
  title: '',
  visits: [],
  technicians: [],
  defaultTechnicianId: null,
  subcontractors: [],
  visitStatuses: []
};

export default VisitsSection;
