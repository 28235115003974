import { useCallback, useEffect, useRef } from 'react';
import { SCROLL_BAR_LINE_ID } from '../constants';
import {
  findCommonParent,
  findLastScrollableElement,
  getFirstScrollableChild,
  getFirstScrollableElement,
  getScrollbarContainer,
  getScrollbarLine,
  getWidestScrollableChild
} from '../utils';

export default function useAddResizeObserver({
  scrollbarPrefixId,
  scrollSpaceRef,
  handleVerticalScroll,
  handleDragStart,
  handleUserWheelScroll,
  lastScrollableElementRef
}) {
  const previousScrollWidth = useRef(0);
  const previousScrollHeight = useRef(0);
  const previousContainerWidth = useRef(0);
  const previousContainerHeight = useRef(0);

  const createNewScrollLine = useCallback(() => {
    const container = getScrollbarContainer(scrollbarPrefixId);
    const oldElement = getScrollbarLine(scrollbarPrefixId);
    if (oldElement) oldElement.remove();

    const scrollLine = document.createElement('div');
    scrollLine.id = `${SCROLL_BAR_LINE_ID}${scrollbarPrefixId}`;
    scrollLine.className = SCROLL_BAR_LINE_ID;
    scrollLine.role = 'presentation';
    scrollLine.removeEventListener('mousedown', null);
    scrollLine.addEventListener('mousedown', handleDragStart);
    const firstScrollableElement = getFirstScrollableChild(container);
    const lastScrollableElement = findLastScrollableElement(container);
    lastScrollableElementRef.current = lastScrollableElement;
    if (firstScrollableElement && lastScrollableElement) {
      const parent = findCommonParent(firstScrollableElement, lastScrollableElement);
      if (parent.className.includes('header')) {
        parent.parent.appendChild(scrollLine);
      } else {
        parent.appendChild(scrollLine);
      }
      handleVerticalScroll();
    }
    return scrollLine;
  }, [handleVerticalScroll, handleDragStart]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach(() => {
        const container = getScrollbarContainer(scrollbarPrefixId);
        const widestScrollableElement = getWidestScrollableChild(container);
        const currentScrollWidth = widestScrollableElement?.scrollWidth || 0;
        const currentScrollHeight = widestScrollableElement?.scrollHeight || 0;
        if (
          currentScrollWidth !== previousScrollWidth.current ||
          previousContainerWidth.current !== container?.clientWidth
        ) {
          previousScrollWidth.current = currentScrollWidth;
          previousContainerWidth.current = container?.clientWidth;
          if (widestScrollableElement) {
            const scrollLine = getScrollbarLine(scrollbarPrefixId) || createNewScrollLine();
            const isRelative = scrollLine.style.position === 'relative';
            const outerSpace = widestScrollableElement.scrollWidth - widestScrollableElement.clientWidth;
            scrollSpaceRef.current = outerSpace;
            const lineWidth = (container?.clientWidth ?? 0) - outerSpace;
            scrollLine.style.width = `${lineWidth}px`;
            scrollLine.style.left = `${isRelative ? 0 : container?.getBoundingClientRect().x}px`;
            widestScrollableElement.scrollLeft = 0;
            widestScrollableElement?.removeEventListener('scroll', null);
            widestScrollableElement.addEventListener('scroll', handleUserWheelScroll);
          } else {
            getScrollbarLine(scrollbarPrefixId)?.remove();
          }
        }
        if (
          currentScrollHeight !== previousScrollHeight.current ||
          previousContainerHeight.current !== container?.clientHeight
        ) {
          previousScrollHeight.current = currentScrollHeight;
          previousContainerHeight.current = container?.clientHeight;
          const firstVerticalScroll = getFirstScrollableElement(container);
          firstVerticalScroll?.removeEventListener('scroll', handleVerticalScroll);
          firstVerticalScroll?.addEventListener('scroll', handleVerticalScroll);
          handleVerticalScroll();
        }
      });
    });
    const container = getScrollbarContainer(scrollbarPrefixId);
    const widestScrollableElement = getWidestScrollableChild(container);
    const scrollLine = getScrollbarLine(scrollbarPrefixId);
    const table = container?.querySelector('table');
    if (table) {
      resizeObserver.observe(table);
      resizeObserver.observe(container);
    }
    widestScrollableElement?.removeEventListener('scroll', null);
    widestScrollableElement?.addEventListener('scroll', handleUserWheelScroll);
    scrollLine?.removeEventListener('mousedown', null);
    scrollLine?.addEventListener('mousedown', handleDragStart);
    return () => {
      const firstVerticalScroll = getFirstScrollableElement(container);
      firstVerticalScroll?.removeEventListener('scroll', handleVerticalScroll);
      resizeObserver.disconnect();
      scrollLine?.removeEventListener('mousedown', handleDragStart);
      widestScrollableElement?.removeEventListener('scroll', null);
    };
  });
}
