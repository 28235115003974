import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const { $, kendo } = window;

function History({ isLoading, series, title, months, currency, stack }) {
  const barChartRef = useRef();

  useEffect(() => {
    if (isLoading) return;

    $(barChartRef.current).kendoChart({
      seriesDefaults: {
        type: 'column',
        border: {
          width: 0
        },
        overlay: {
          gradient: 'none'
        },
        stack
      },
      series,
      categoryAxis: {
        categories: months,
        labels: {
          color: '#444',
          font: '400 1.1rem/1 "Inter", sans-serif',
          margin: {
            top: 8
          }
        },
        line: {
          color: '#ccc'
        },
        majorGridLines: {
          visible: false
        },
        majorTicks: {
          visible: false
        }
      },
      legend: {
        offsetY: 16,
        position: 'bottom',
        spacing: 16,
        item: {
          visual(e) {
            const color = e.options.markers.background;
            const labelColor = e.options.labels.color;
            const rect = new kendo.geometry.Rect([0, 0], [300, 50]);
            const layout = new kendo.drawing.Layout(rect, {
              spacing: 4,
              alignItems: 'center'
            });

            const CircGeometry = new kendo.geometry.Circle([8, 4], 4);

            const MarkerCircle = new kendo.drawing.Circle(CircGeometry, {
              stroke: { width: 0 },
              fill: { color }
            });

            const label = new kendo.drawing.Text(e.series.name, [0, 0], {
              fill: {
                color: labelColor
              },
              cursor: 'pointer'
            });

            layout.append(MarkerCircle, label);
            layout.reflow();

            return layout;
          }
        }
      },
      valueAxis: [
        {
          labels: {
            color: '#777',
            format: `{0:${currency}##,#}`,
            font: '400 1.2rem/1 "Roboto Mono", Courier, monospace'
          },
          line: {
            color: '#ccc'
          },
          majorGridLines: {
            color: '#eee'
          },
          majorTicks: {
            visible: false
          }
        }
      ],
      chartArea: {
        // width: screen.width,
      },
      tooltip: {
        visible: true,
        template: `#= series.name#: #= kendo.format("{0:${currency}\\#,\\#\\#\\#.\\#\\#}", value)#`
      }
    });
  }, [series]);

  return (
    <div className="qmb-widget--lg--bar-chart">
      <div className="widget__header">
        <svg className="widget__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
          <polygon points="64,0 0,64 0,0 " />
        </svg>
        <h3 className="widget__title">{title}</h3>
      </div>

      {isLoading && (
        <div className="widget__body" style={{ display: 'flex' }}>
          <div className="load-spinner load-64">
            <svg role="img">
              <use href="/map.svg#load-spinner" />
            </svg>
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="widget__body" style={{ display: 'flex' }}>
          <div className="widget__figure">
            <div ref={barChartRef} />
          </div>
        </div>
      )}
    </div>
  );
}

History.defaultProps = {
  isLoading: true,
  series: [],
  title: 'Title',
  currency: '$',
  stack: true,
  months: []
};

History.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  currency: PropTypes.string,
  stack: PropTypes.bool,
  months: PropTypes.arrayOf(PropTypes.string),
  series: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string
    })
  )
};

export default History;
