import axios from 'axios';

const TIMEOUT = 60000;

export default axios.create({
  baseURL: window.location.origin,
  timeout: TIMEOUT,
  headers: {
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
});
