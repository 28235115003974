import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './CustomScrollbar.scss';
import { v4 as uuidv4 } from 'uuid';
import { SCROLL_BAR_CONTAINER_ID } from './constants';
import {
  getScrollbarContainer,
  getScrollbarLine,
  getWidestScrollableChild,
  isElementBottomVisible,
  isElementVisible
} from './utils';
import useAddEventListeners from './hooks/useAddEventListeners';
import useAddResizeObserver from './hooks/useAddResizeObserver';

export default function CustomScrollbarInternal({ children }) {
  const isDragging = useRef(null);
  const dragStartX = useRef(0);
  const currentPosition = useRef(0);
  const isUserScroll = useRef(false);
  const scrollSpace = useRef(false);
  const lastScrollableElement = useRef(null);

  const scrollbarPrefixId = useMemo(() => uuidv4(), []);

  const handleDrag = useCallback((event) => {
    if (!isDragging.current) return;
    const scrollLine = getScrollbarLine(scrollbarPrefixId);
    const container = getScrollbarContainer(scrollbarPrefixId);
    const isRelative = scrollLine.style.position === 'relative';
    const scrollableElement = getWidestScrollableChild(container);
    const scrollLeftAmount = event.clientX - dragStartX.current;
    const initialLeftPosition = isRelative ? 0 : container.getBoundingClientRect().x;
    const newScrollableElementLeft = currentPosition.current + scrollLeftAmount - initialLeftPosition;
    const newLeftPosition = currentPosition.current + scrollLeftAmount;
    if (newLeftPosition < initialLeftPosition || newLeftPosition > scrollSpace.current + initialLeftPosition) {
      if (newLeftPosition < initialLeftPosition) {
        scrollLine.style.left = `${initialLeftPosition}px`;
        scrollableElement.scrollLeft = 0;
      } else {
        scrollLine.style.left = `${scrollSpace.current + initialLeftPosition}px`;
        scrollableElement.scrollLeft = scrollSpace.current;
      }
      return;
    }
    scrollLine.style.left = `${newLeftPosition}px`;
    scrollableElement.scrollLeft = newScrollableElementLeft;
  }, []);

  const handleDragEnd = () => {
    document.body.style.userSelect = 'auto';
    isDragging.current = false;
  };

  const handleUserWheelScroll = useCallback((event) => {
    if (isUserScroll.current && !isDragging.current) {
      currentPosition.current = event.target.scrollLeft;
      const scrollLine = getScrollbarLine(scrollbarPrefixId);
      const container = getScrollbarContainer(scrollbarPrefixId);
      const containerLeft = container.getBoundingClientRect().x;
      const initialLeftPosition = scrollLine.style.position === 'relative' ? 0 : containerLeft;
      scrollLine.style.left = `${event.target.scrollLeft + initialLeftPosition}px`;
    }
    isUserScroll.current = false;
  }, []);

  const handleWheel = () => {
    isUserScroll.current = true;
  };

  const handleVerticalScroll = useCallback(() => {
    const container = getScrollbarContainer(scrollbarPrefixId);
    const scrollLine = getScrollbarLine(scrollbarPrefixId);
    if (!container || !scrollLine) return;
    const isVisible = isElementVisible(container);
    const isBottomVisible = isElementBottomVisible(lastScrollableElement.current);
    const scrollableElement = getWidestScrollableChild(container);
    const isFixed = scrollLine.style.position !== 'relative';
    const isRelative = scrollLine.style.position !== 'fixed';
    if (isVisible && !isBottomVisible) {
      if (isRelative) {
        scrollLine.style.position = 'fixed';
        const containerLeft = container.getBoundingClientRect().x;
        scrollLine.style.left = `${containerLeft + scrollableElement.scrollLeft}px`;
        scrollLine.style.bottom = '0';
      }
    } else if (isFixed) {
      scrollLine.style.position = 'relative';
      scrollLine.style.left = `${scrollableElement.scrollLeft}px`;
      scrollLine.style.bottom = '';
    }
  }, []);

  const handleDragStart = useCallback((event) => {
    const scrollLine = getScrollbarLine(scrollbarPrefixId);
    const currentLineLeft = parseInt(window.getComputedStyle(scrollLine).getPropertyValue('left'), 10) || 0;
    isDragging.current = true;
    document.body.style.userSelect = 'none';
    dragStartX.current = event.clientX;
    const newLeft = currentLineLeft;
    currentPosition.current = newLeft;
  }, []);

  useAddResizeObserver({
    scrollbarPrefixId,
    scrollSpaceRef: scrollSpace,
    handleVerticalScroll,
    handleDragStart,
    handleUserWheelScroll,
    lastScrollableElementRef: lastScrollableElement
  });

  useAddEventListeners({
    handleDrag,
    handleDragEnd,
    handleWheel
  });

  return (
    <div
      role="presentation"
      id={`${SCROLL_BAR_CONTAINER_ID}${scrollbarPrefixId}`}
      className="custom-scrollbar-container">
      {children}
    </div>
  );
}

function CustomScrollbar({ children }) {
  const [shouldShowScrollBar, setShouldShowScrollBar] = useState(false);

  useEffect(() => {
    setShouldShowScrollBar(!('ontouchstart' in window));
  }, []);

  return !shouldShowScrollBar ? children : <CustomScrollbarInternal>{children}</CustomScrollbarInternal>;
}

CustomScrollbar.propTypes = {
  children: PropTypes.node
};

CustomScrollbar.defaultProps = {
  children: null
};

CustomScrollbarInternal.propTypes = {
  children: PropTypes.node
};

CustomScrollbarInternal.defaultProps = {
  children: null
};
