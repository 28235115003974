import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

import Loader from '@components/Loader';
// eslint-disable-next-line import/no-cycle
import Rows from './Rows';
import Items from './Items';

export const ROWS = 'rows';
export const ITEMS = 'items';

function Row({ index, row, loadRow, parentNodes }) {
  const [opened, setOpened] = useState(false);

  const currentNodeLocation = (parentNodes || []).concat(index);
  const couldBeOpened = Number(row.items_count) > 0;
  const dropdownButtonClass = !couldBeOpened && 'cursor-default';

  const dropdownSignClasses = () => {
    const classes = ['fa-solid'];

    opened ? classes.push('fa-chevron-down') : classes.push('fa-chevron-right');
    if (!couldBeOpened) classes.push('invisible');

    return classes.join(' ');
  };

  const toggleRow = (e) => {
    e.preventDefault();

    if (!couldBeOpened) return;

    if (!opened) {
      loadRow({ url: row.url, nodeLocation: currentNodeLocation });
    }

    setOpened(!opened);
  };

  const renderChildren = () => {
    if (!opened) return;

    switch (row.childrenType) {
      case ROWS:
        return <Rows rows={row.children} loadRow={loadRow} parentNodes={currentNodeLocation} />;
      case ITEMS:
        return <Items items={row.children} />;
    }
  };

  const itemsCount = (count, newItemsCount) => {
    if (!(row.has_new_items_displayed && newItemsCount)) return count;

    return (
      <span className="data-list__count">
        {count}
        <span
          className="data-list__count--added"
          title={I18n.t('subcontractors.inspections.equipment.show.table.added_count', { count: newItemsCount })}>
          (+{newItemsCount})
        </span>
      </span>
    );
  };

  return (
    <>
      <li className="data-list__row" onClick={toggleRow}>
        <div className="data-list__col">
          <button type="button" className={`threaded-list__btn ${dropdownButtonClass}`}>
            <i className={dropdownSignClasses()} />
            {row.name}
          </button>
        </div>
        <div className="data-list__col--int">{row.parent_tenant_items_count}</div>
        <div className="data-list__col--int--divide">
          {itemsCount(row.inspected_items_count, row.new_inspected_items_count)}
        </div>
        <div className="data-list__col--int">
          {itemsCount(row.not_inspected_items_count, row.new_not_inspected_items_count)}
        </div>
      </li>
      {renderChildren()}
      <Loader isLoading={row.isLoading} className="py-5" />
    </>
  );
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  loadRow: PropTypes.func.isRequired,
  parentNodes: PropTypes.array
};

Row.defaultProps = {
  parentNodes: null
};

export default Row;
