const _ = require('lodash');
import I18n from '@utils/i18n';

export const DEFAULT_PAGE_SIZE = 25;
export const pageSizes = (pageSize) => {
  return [pageSize, pageSize * 2, pageSize * 4, pageSize * 10];
};
export const buildGridSettings = (tableElement, additionalSettings = {}) => {
  const settings = {
    dataSource: {
      transport: {
        // "jsonp" is required for cross-domain requests; use "json" for same-domain requests
        read: {
          url: tableElement.getAttribute('data-src-url'),
          dataType: 'json'
        }
      },
      schema: {
        parse: (response) => response,
        data: 'data',
        total: 'total_count',
        model: {}
      },
      serverPaging: true,
      serverFiltering: true,
      serverSorting: true
    },
    filterable: false,
    sortable: true,
    pageable: {
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizes: pageSizes(DEFAULT_PAGE_SIZE),
      messages: translatedMessages('pageable')
    },
    autoBind: false, // to have a better control over when to make a real Back-End API call
    columnMenu: {
      componentType: 'modern',
      filterable: false,
      messages: translatedMessages('columnMenu')
    }
  };

  return _.merge(settings, additionalSettings);
};

const translatedMessages = (scope) => {
  if (I18n.locale !== 'es') return {};

  return {
    columnMenu: {
      sortAscending: "Orden ascendente",
      sortDescending: "Orden descendente",
      columns: "Columnas",
      reset: 'Reiniciar',
      apply: 'Solicitar'
    },
    pageable: {
      display: "{0} - {1} de {2} ítems",
      empty: "No hay elementos para mostrar",
      page: "Página",
      allPages: 'Todo',
      of: "de {0}",
      itemsPerPage: "ítems por página",
      first: 'Ir a la primera página',
      previous: "Ir a la página anterior",
      next: "Ir a la página siguiente",
      last: "Ir a la última página"
    }
  }[scope]
}
