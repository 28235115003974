import React from 'react';
import { Input } from '@progress/kendo-react-inputs';
import PropTypes from 'prop-types';

function TextBox({ name, value, label, placeholder, onChange }) {
  const handleOnChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <>
      <Input id={name} placeholder={placeholder} onChange={handleOnChange} value={value}/>
      {label && (
        <label className="qmb-label" htmlFor={name}>
          {label}
        </label>
      )}
    </>
  );
}

export default TextBox;

TextBox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

TextBox.defaultProps = {
  label: undefined,
  placeholder: '',
  onChange: null
};
