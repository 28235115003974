import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

function ScheduledDateCell({ tdProps, dataItem }) {
  return (
    <td {...tdProps}>
      {dataItem.scheduledDate}
      &nbsp;
      {dataItem.linkToSeries && (
        <a
          href={dataItem.linkToSeries}
          className="qmb-control--icon"
          title={I18n.t('features.inspections_index.scheduled_date_cell.view_series')}>
          <i className="fa-light fa-rectangle-vertical-history" />
        </a>
      )}
    </td>
  );
}

ScheduledDateCell.propTypes = {
  tdProps: PropTypes.object,
  dataItem: PropTypes.shape({
    scheduledDate: PropTypes.string,
    linkToSeries: PropTypes.string
  }).isRequired
};

export default ScheduledDateCell;
