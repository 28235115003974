import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import I18n, { translate } from '@utils/i18n';
import { Lightbox, IconButton, createIcon, useLightboxState } from 'yet-another-react-lightbox';
import { photoPath } from 'routes';
import api from '@utils/axios';
import { Dialog } from '@progress/kendo-react-dialogs';
import PhotoCard from './PhotoCard';
import PhotoDetail from './PhotoDetail';
import { actionTypes } from '../reducer';
import 'yet-another-react-lightbox/styles.css';
import VisibilityObserver from './VisibilityObserver';

const namespace = 'features.photo_gallery.month_group';

const DeleteIcon = createIcon(
  'DeleteIcon',
  <path
    d="M4.5 12.5C4.5 12.775 4.275 13 4 13C3.725 13 3.5 12.775 3.5 12.5V5.5C3.5 5.225 3.725 5 4 5C4.275 5 4.5 5.225 4.5 5.5V12.5ZM7.5 12.5C7.5 12.775 7.275 13 7 13C6.725 13 6.5 12.775 6.5 12.5V5.5C6.5 5.225 6.725 5 7 5C7.275 5 7.5 5.225 7.5 5.5V12.5ZM10.5 12.5C10.5 12.775 10.275 13 10 13C9.725 13 9.5 12.775 9.5 12.5V5.5C9.5 5.225 9.725 5 10 5C10.275 5 10.5 5.225 10.5 5.5V12.5ZM9.69063 0.705L10.5281 2H13.5C13.775 2 14 2.22375 14 2.5C14 2.77625 13.775 3 13.5 3H13V13.5C13 14.8813 11.8813 16 10.5 16H3.5C2.11937 16 1 14.8813 1 13.5V3H0.5C0.223875 3 0 2.77625 0 2.5C0 2.22375 0.223875 2 0.5 2H3.47187L4.28125 0.705C4.55625 0.266437 5.0375 0 5.55312 0H8.44687C8.9625 0 9.44375 0.266437 9.69063 0.705ZM4.65312 2H9.34688L8.86875 1.235C8.77812 1.08875 8.61875 1 8.44687 1H5.55312C5.38125 1 5.22187 1.08875 5.13125 1.235L4.65312 2ZM2 13.5C2 14.3281 2.67156 15 3.5 15H10.5C11.3281 15 12 14.3281 12 13.5V3H2V13.5Z"
    fill="#FFFFFF"
  />
);

function DeleteButton({ onDelete }) {
  const { currentSlide } = useLightboxState();

  return <IconButton onClick={onDelete(currentSlide)} label="Delete button" icon={DeleteIcon} />;
}

function MonthGroup({
  expanded,
  photos,
  onExpand,
  onCollapse,
  yearMonth,
  count,
  innerRef,
  dispatch,
  pageDetails,
  onChangeVisibility: onChangeVisibilityProp
}) {
  const [photoIndex, setPhotoIndex] = useState(-1);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState({});
  const year = useMemo(() => Math.floor(yearMonth / 100), []);
  const month = useMemo(() => I18n.t('date.month_names')[yearMonth % 100], []);

  const onHeadClick = () => {
    if (expanded) return onCollapse(yearMonth);

    onExpand(yearMonth);
  };

  const onDelete = (photo) => {
    return () => {
      setConfirmDeleteDialog(true);
      setDeleteCandidate(photo);
    };
  };

  const onDeleteConfirm = () => {
    api.delete(photoPath({ id: deleteCandidate.id, type: deleteCandidate.type, _options: true })).then(() => {
      setPhotoIndex(-1);
      dispatch({ type: actionTypes.PHOTO_DELETED, photoId: deleteCandidate.id, yearMonth });
      setConfirmDeleteDialog(false);
      setDeleteCandidate({});
    });
  };

  const onChangeVisibility = (visible) => {
    onChangeVisibilityProp({ yearMonth, isVisible: visible });
  };

  return (
    <section
      style={{ scrollMarginTop: '64px' }}
      className={`workspace__section ${expanded ? '--expanded  --active' : '--collapsed'}`}>
      <VisibilityObserver onChangeVisibility={onChangeVisibility}>
        <button type="button" onClick={onHeadClick} className="section__title">
          <h2 className="section__label" ref={innerRef}>
            {month} {year}
          </h2>
          <span className="section__count">
            {count} {translate('photos', { namespace })}
          </span>
          <hr className="section__split" />
        </button>
      </VisibilityObserver>
      <div className="section__content--table">
        <article className="qmb-table--02--x-full">
          {photos?.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th className="--primary">{translate('photo', { namespace })}</th>
                  <th>{translate('description', { namespace })}</th>
                  <th>{translate('type', { namespace })}</th>
                  <th>{translate('date_taken', { namespace })}</th>
                  <th style={{ width: '25px' }} />
                </tr>
              </thead>
              <tbody>
                {photos?.map((photo, index) => (
                  <PhotoCard
                    onDelete={onDelete(photo)}
                    key={photo.id}
                    photo={photo}
                    onCardClick={() => setPhotoIndex(index)}
                    pageDetails={pageDetails}
                  />
                ))}
              </tbody>
            </table>
          )}
          {photos?.length === 0 && <div>{translate('no_photos', { namespace })}</div>}
          {photos === null && (
            <div className="qmb-loading--96" data-title={I18n.t('generic.loading')}>
              <svg role="img">
                <use href="/map.svg#load-spinner" />
              </svg>
            </div>
          )}
        </article>
      </div>
      <Lightbox
        carousel={{ finite: true }}
        open={photoIndex >= 0}
        index={photoIndex}
        on={{ view: ({ index }) => setPhotoIndex(index) }}
        className="qmb-media"
        close={() => setPhotoIndex(-1)}
        slides={photos}
        render={{
          slide: ({ slide }) => <PhotoDetail dispatch={dispatch} yearMonth={yearMonth} photo={slide} />
        }}
        toolbar={{
          buttons: [<DeleteButton onDelete={onDelete} key="delete-button" />, 'close']
        }}
      />
      {confirmDeleteDialog && (
        <Dialog
          style={{ zIndex: '10105' }}
          title="Confirm delete photo"
          className="qmb-dialog"
          onClose={() => setConfirmDeleteDialog(false)}>
          <p>{translate('confirm_delete', { namespace })}</p>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button type="button" className="qmb-button" onClick={() => setConfirmDeleteDialog(false)}>
              {I18n.t('generic.cancel')}
            </button>
            <button type="button" onClick={onDeleteConfirm} className="qmb-button--submit">
              {I18n.t('generic.ok')}
            </button>
          </div>
        </Dialog>
      )}
    </section>
  );
}

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired
};

MonthGroup.propTypes = {
  expanded: PropTypes.bool,
  photos: PropTypes.array,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  yearMonth: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  innerRef: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pageDetails: PropTypes.object,
  onChangeVisibility: PropTypes.func
};

MonthGroup.defaultProps = {
  expanded: false,
  photos: null,
  pageDetails: {},
  onChangeVisibility: () => {}
};

export default MonthGroup;
