import produce from 'immer';

import { THIS_MONTH } from '@constants/Kendo/rangeOptions';

import {
  actionTypes as dateRangePickerActionTypes,
  buildInitialState as buildInitialDateRangeState,
  reducerFunction as dateRangePickerReducerFunction
} from '@components/Kendo/DateRangePickerReducer';

export const actionTypes = {
  FILTER_CHANGED: 'FILTER_CHANGED',
  FILTER_TOGGLED: 'FILTER_TOGGLED',
  FILTERS_RESET: 'FILTERS_RESET',
  DATA_LOADED: 'DATA_LOADED',
  IS_LOADING: 'IS_LOADING',
  LOAD_FILTERS_FROM_CACHE: 'LOAD_FILTERS_FROM_CACHE',
  ...dateRangePickerActionTypes // static line, should be the only one for all DateRangePickers
};

export const buildInitialFiltersState = () => {
  return {
    account: {
      value: '',
      active: true
    },
    building: {
      value: '',
      active: true
    },
    technician: {
      value: '',
      active: false
    },
    technicianTeam: {
      value: '',
      active: false
    },
    invoiceCategory: {
      value: '',
      active: false
    },
    ...buildInitialDateRangeState('issueDate', THIS_MONTH, true)
  };
};

export const reducer = produce((state, action) => {
  // static snippet, should be the only one for all DateRangePickers
  if (Object.keys(dateRangePickerActionTypes).includes(action.type)) {
    return dateRangePickerReducerFunction(state, action);
  }

  switch (action.type) {
    case actionTypes.FILTERS_RESET:
      state.filters = buildInitialFiltersState();
      break;
    case actionTypes.FILTER_CHANGED:
      if (action.value === state.filters[action.field].value) break;

      state.isLoading = true;
      state.filters[action.field].value = action.value;
      break;
    case actionTypes.FILTER_TOGGLED:
      state.filters[action.field].value = '';
      state.filters[action.field].startDate = '';
      state.filters[action.field].endDate = '';
      state.filters[action.field].active = !state.filters[action.field].active;
      break;
    case actionTypes.DATA_LOADED:
      state.isLoading = false;
      state.totalInvoiced = action.data.totalInvoiced;
      state.totalDue = action.data.totalDue;
      state.outstandingCustomers = action.data.outstandingCustomers;
      state.issueHistory = action.data.issueHistory;
      state.historyByTech = action.data.historyByTech;
      state.paidHistory = action.data.paidHistory;
      state.months = action.data.months;
      break;
    case actionTypes.IS_LOADING:
      state.isLoading = true;
      break;
    default:
      throw new Error('Reducer - Wrong action type.');
  }
});
