import { translate } from '@utils/i18n';
import React from 'react';
function RecursiveDetails(params) {
    const namespace = 'features.modals.visit_modal.details';
    return (React.createElement(React.Fragment, null, Object.keys(params.details).map((key) => {
        const value = params.details[key];
        if (typeof value === 'string') {
            return (React.createElement("div", { key: key, className: "list__item", style: { marginLeft: `${params.depth}rem` } },
                React.createElement("span", { className: "item__label" },
                    translate(key, { namespace }),
                    ":"),
                React.createElement("span", null, value)));
        }
        if (typeof value === 'object' && value !== null) {
            return (React.createElement("span", { key: key },
                React.createElement("span", { className: "item__label", key: key },
                    translate(key, { namespace }),
                    ":"),
                React.createElement(RecursiveDetails, { details: value, depth: params.depth + 1 })));
        }
        return null;
    })));
}
export default RecursiveDetails;
