import _ from 'lodash';

export const map = (input, callbackFn) => {
  return Object.entries(input).map(([key, value]) => {
    if (typeof callbackFn === 'function') return callbackFn(value, key, input);
    if (typeof callbackFn === 'string') return value[callbackFn];

    return value;
  });
};

export const groupBy = (input, callbackFn) => {
  if (Array.isArray(input)) return _.groupBy(input, callbackFn);

  return _.groupBy(Object.entries(input), callbackFn);
};
