import produce from 'immer';

export const actionTypes = {
  DATA_LOADED: 'DATA_LOADED',
  ERROR_LOADED: 'ERROR_LOADED',
  MONTH_EXPANDED: 'MONTH_EXPANDED',
  MONTH_COLLAPSED: 'MONTH_COLLAPSED',
  MONTH_FOCUSED: 'MONTH_FOCUSED',
  PHOTO_UPDATED: 'PHOTO_UPDATED',
  PHOTO_DELETED: 'PHOTO_DELETED',
  PHOTO_ADDED: 'PHOTO_ADDED'
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.ERROR_LOADED:
      draft.error = action.error;
      break;
    case actionTypes.DATA_LOADED:
      draft.error = null;
      draft.photos = { ...draft.photos, ...action.data.photos };

      if (action.data.photosCount) {
        draft.photosCount = action.data.photosCount;
        Object.keys(draft.photos).forEach((yearMonth) => {
          draft.expanded[yearMonth] = true;
        });
        draft.focused = Object.keys(draft.photos).at(-1);
      }
      break;
    case actionTypes.MONTH_EXPANDED:
      draft.expanded[action.yearMonth] = true;
      break;
    case actionTypes.MONTH_COLLAPSED:
      draft.expanded[action.yearMonth] = false;
      break;
    case actionTypes.MONTH_FOCUSED:
      draft.focused = action.focused;
      break;
    case actionTypes.PHOTO_UPDATED: {
      const index = draft.photos[action.yearMonth].findIndex((photo) => photo.id === action.newPhoto.id);
      draft.error = null;
      draft.photos[action.yearMonth][index] = action.newPhoto;
      break;
    }
    case actionTypes.PHOTO_ADDED:
      (draft.photos[action.yearMonth] ??= []).unshift(action.newPhoto);
      draft.error = null;
      draft.photosCount[action.yearMonth] ??= 0;
      draft.photosCount[action.yearMonth] += 1;
      draft.expanded[action.yearMonth] = true;
      break;
    case actionTypes.PHOTO_DELETED: {
      draft.error = null;
      const index = draft.photos[action.yearMonth].findIndex((photo) => photo.id === action.photoId);

      draft.photos[action.yearMonth].splice(index, 1);
      draft.photosCount[action.yearMonth] -= 1;
      if (draft.photosCount[action.yearMonth] === 0) delete draft.photosCount[action.yearMonth];
      break;
    }
    default:
      throw new Error('Reducer - Wrong action type.');
  }
});
