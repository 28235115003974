import { useCallback, useEffect, useState } from 'react';
import api from 'axios';
import { apiInternalInspectionPrimaryVisitPath, apiInternalInspectionVisitPath, apiInternalServiceVisitPath } from '../../routes';
const useGetVisit = (type, id) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const fetchData = useCallback((fetchType, fetchId, controller) => {
        setLoading(true);
        let url;
        switch (fetchType) {
            case "InspectionVisit" /* VisitType.INSPECTION_VISIT */:
                url = apiInternalInspectionVisitPath(fetchId);
                break;
            case "Inspection" /* VisitType.INSPECTION_PRIMARY_VISIT */:
                url = apiInternalInspectionPrimaryVisitPath(fetchId);
                break;
            case "Visit" /* VisitType.SERVICE_VISIT */:
            default:
                url = apiInternalServiceVisitPath(fetchId);
                break;
        }
        api
            .get(url, { signal: controller.signal })
            .then((response) => {
            const result = response.data.data;
            const tmp = Object.assign(Object.assign({}, result), { type, start: new Date(result.start), end: new Date(result.end) }); // This should be a function somewhere
            setData(tmp);
        })
            .catch((err) => {
            if (!controller.signal.aborted) {
                setData(undefined);
                setError(err);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted) {
                setLoading(false);
            }
        });
    }, []);
    const refetchData = useCallback(() => {
        const controller = new AbortController();
        fetchData(type, id, controller);
        return () => {
            controller.abort();
        };
    }, [type, id]);
    useEffect(() => {
        const controller = new AbortController();
        fetchData(type, id, controller);
        return () => {
            controller.abort();
        };
    }, [type, id]);
    return { data, loading, error, refetchData };
};
export default useGetVisit;
