import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

function CommonSelect({ name, value, label, onChange, elements, placeholder }) {
  const ddListValue = () => {
    if (value === undefined || value === '') return null;

    return {
      value,
      label: elements.find((elem) => elem.value === value).label
    };
  };

  const handleOnChange = (event) => {
    onChange(event.target.value.value);
  };

  return (
    <>
      {label && (
        <label className="qmb-label" htmlFor={name}>
          {label}
        </label>
      )}
      <DropDownList
        id={name}
        className="qmb-popup"
        textField="label"
        dataItemKey="value"
        data={elements}
        value={ddListValue()}
        defaultItem={{
          label: placeholder,
          value: ''
        }}
        onChange={handleOnChange}
      />
    </>
  );
}

export default CommonSelect;

CommonSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ).isRequired
};

CommonSelect.defaultProps = {
  label: undefined,
  onChange: null,
  placeholder: I18n.t('generic.all')
};
