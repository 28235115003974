import { SCROLL_BAR_CONTAINER_ID, SCROLL_BAR_LINE_ID, SCROLL_WIDTH_OFFSET } from './constants';

const VALID_SCROLL_TAGS = ['DIV', 'TABLE', 'ARTICLE'];

export function getFirstScrollableElement(element) {
  const tags = VALID_SCROLL_TAGS.map((tag) => tag.toLowerCase()).join(', ');
  return Array.from(document.querySelectorAll(tags)).find((selector) => {
    return (
      selector.scrollHeight > selector.clientHeight &&
      (selector.contains(element) || element?.contains(selector) || element === selector)
    );
  });
}

export function getWidestScrollableChild(element) {
  let widestScrollableChild = null;
  let maxScrollableWidth = 0;

  if (!element) return null;

  for (let i = 0; i < element.childNodes.length; i += 1) {
    const child = element.childNodes[i];
    if (child.nodeType === Node.ELEMENT_NODE) {
      const childScrollableWidth = child.scrollWidth - child.clientWidth;

      if (childScrollableWidth > maxScrollableWidth && VALID_SCROLL_TAGS.includes(child.tagName)) {
        widestScrollableChild = child;
        maxScrollableWidth = childScrollableWidth;
      }

      const childWidestScrollableChild = getWidestScrollableChild(child);

      if (
        childWidestScrollableChild &&
        childWidestScrollableChild.scrollWidth - childWidestScrollableChild.clientWidth > maxScrollableWidth
      ) {
        widestScrollableChild = childWidestScrollableChild;
        maxScrollableWidth = childWidestScrollableChild.scrollWidth - childWidestScrollableChild.clientWidth;
      }
    }
  }

  if (maxScrollableWidth < SCROLL_WIDTH_OFFSET) {
    return null;
  }

  return widestScrollableChild;
}

export function getFirstScrollableChild(element) {
  if (element?.scrollWidth > element?.clientWidth) {
    return element;
  }

  for (let i = 0; i < element.children.length; i += 1) {
    const child = element.children[i];
    const scrollableChild = getFirstScrollableChild(child);
    if (scrollableChild) {
      return scrollableChild;
    }
  }

  return null;
}

export function findLastScrollableElement(elementParam) {
  if (!elementParam) {
    return null;
  }
  const scrollableChildren = [];
  function findScrollableChildrenRecursive(element) {
    const children = Array.from(element.children);

    for (let i = 0; i < children.length; i += 1) {
      const child = children[i];
      if (element.scrollWidth > element.clientWidth + SCROLL_WIDTH_OFFSET) {
        scrollableChildren.push(child);
      } else {
        findScrollableChildrenRecursive(child);
      }
    }
  }
  findScrollableChildrenRecursive(elementParam);
  return scrollableChildren[scrollableChildren.length - 1];
}

export function isElementVisible(el) {
  if (!el) return false;
  const rect = el.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  return rect.top < windowHeight && rect.bottom > 0 && rect.left < windowWidth && rect.right > 0;
}

export function isElementBottomVisible(element) {
  if (!element) return false;
  const elementRect = element.getBoundingClientRect();
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  return elementRect.bottom <= viewportHeight;
}

export function findCommonParent(element1, element2) {
  if (!element1 || !element2) {
    return null;
  }
  if (element1.contains(element2) || element1 === element2) {
    return element1.parentNode || element1;
  }
  if (element2.contains(element1)) {
    return element2.parentNode || element2;
  }

  const pathToRoot1 = [];
  let currentElement1 = element1;
  while (currentElement1) {
    pathToRoot1.push(currentElement1);
    currentElement1 = currentElement1.parentNode;
  }

  let currentElement2 = element2;
  while (currentElement2) {
    if (pathToRoot1.includes(currentElement2)) {
      return currentElement2;
    }
    currentElement2 = currentElement2.parentNode;
  }

  return null;
}

export function getScrollbarLine(prefixId) {
  return document.getElementById(`${SCROLL_BAR_LINE_ID}${prefixId}`);
}

export function getScrollbarContainer(prefixId) {
  return document.getElementById(`${SCROLL_BAR_CONTAINER_ID}${prefixId}`);
}
