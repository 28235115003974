import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { technicianAvatar } from '@utils/functions/technician.functions';
import { subcontractorIcon } from '@utils/functions/subcontractor.functions';
import { Popup } from '@progress/kendo-react-popup';
import { AssigneeMultiSelectContext, AssigneeMultiSelectProvider } from './AssigneeMultiSelectContext';
import { Loader, LoaderSizes } from '../../Loader/Loader';
import TechSubSelector from './nested/tech-sub-selector';
import TechnicianMultiSelect from '../TechnicianMultiSelect';
import SubcontractorMultiSelect from '../SubcontractorMultiSelect';
function AssigneeMultiSelect({ style }) {
    const { multiSelectReady, tenant, selectedTechnicians, selectedSubcontractors, technicianList, subcontractorList, activeMultiSelector, setActiveMultiSelector, setSelectedTechnicians, setSelectedSubcontractors } = useContext(AssigneeMultiSelectContext);
    const rootAnchor = useRef(null);
    const popup = useRef(null);
    const subPopup = useRef(null);
    const [open, setOpen] = useState(false);
    const [isSubanchorReady, setIsSubanchorReady] = useState(false);
    useEffect(() => {
        if (open && popup.current && activeMultiSelector !== null) {
            setIsSubanchorReady(true);
        }
        else {
            setIsSubanchorReady(false);
        }
    }, [open, popup.current, activeMultiSelector]);
    const handleBodyClick = useCallback((e) => {
        var _a, _b, _c;
        if (popup.current && ((_a = popup.current._popup) === null || _a === void 0 ? void 0 : _a.contains(e.target)))
            return;
        if (rootAnchor.current && ((_b = rootAnchor.current) === null || _b === void 0 ? void 0 : _b.contains(e.target)))
            return;
        if (subPopup.current && ((_c = subPopup.current._popup) === null || _c === void 0 ? void 0 : _c.contains(e.target)))
            return;
        setActiveMultiSelector(null);
        setOpen(false);
    }, []);
    useEffect(() => {
        document.body.addEventListener('click', handleBodyClick);
        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, []);
    const collectionPreview = useMemo(() => {
        const newSelectedSubs = (tenant === null || tenant === void 0 ? void 0 : tenant.flags.subcontractorsEnabled) ? selectedSubcontractors : [];
        const totalSelections = selectedTechnicians.length + ((tenant === null || tenant === void 0 ? void 0 : tenant.flags.subcontractorsEnabled) ? selectedSubcontractors.length : 0);
        const techPreviews = selectedTechnicians.slice(0, 5).map((tech) => {
            var _a;
            return (React.createElement(React.Fragment, { key: `multiselect-preview-tech-${(_a = tech === null || tech === void 0 ? void 0 : tech.id) !== null && _a !== void 0 ? _a : 'unassigned'}` }, technicianAvatar(tech, 24)));
        });
        const subPreviews = newSelectedSubs.slice(0, 5 - techPreviews.length).map((sub) => {
            var _a;
            return (React.createElement(React.Fragment, { key: `multiselect-preview-sub-${(_a = sub === null || sub === void 0 ? void 0 : sub.id) !== null && _a !== void 0 ? _a : 'unassigned'}` }, subcontractorIcon(sub, 24)));
        });
        const overflow = totalSelections > 5 ? (React.createElement("span", { key: "assignee-multiselect-overflow", className: "control__count" },
            "+",
            totalSelections - 5)) : null;
        return [...techPreviews, ...subPreviews, overflow];
    }, [selectedTechnicians, selectedSubcontractors, tenant]);
    const content = useMemo(() => {
        if (!multiSelectReady)
            return React.createElement(Loader, { size: LoaderSizes.small });
        return (React.createElement("span", { key: "multiselect-preview-content-icons", className: "control__collection" }, collectionPreview));
    }, [multiSelectReady, selectedTechnicians, selectedSubcontractors, tenant]);
    const activeMultiSelectorComponent = useMemo(() => {
        if (activeMultiSelector === "technician" /* AssigneeType.TECHNICIAN */)
            return (React.createElement(TechnicianMultiSelect, { selectedTechnicians: selectedTechnicians, technicianList: technicianList, onChange: setSelectedTechnicians }));
        if (activeMultiSelector === "subcontractor" /* AssigneeType.SUBCONTRACTOR */)
            return (React.createElement(SubcontractorMultiSelect, { selectedSubcontractors: selectedSubcontractors, subcontractorList: subcontractorList, onChange: setSelectedSubcontractors }));
        return null;
    }, [activeMultiSelector, selectedTechnicians, technicianList, selectedSubcontractors, subcontractorList]);
    return (React.createElement(React.Fragment, null,
        style === 'filter-panel' && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "qmb-label", htmlFor: "assigneeButton" }, "Assigned To"),
            React.createElement("button", { name: "assigneeButton", className: "filter__value", type: "button", ref: rootAnchor, onClick: () => {
                    setOpen(!open);
                    setActiveMultiSelector(null);
                } }, content))),
        style !== 'filter-panel' && (React.createElement("button", { className: "qmb-control--menu", type: "button", ref: rootAnchor, onClick: () => {
                setOpen(!open);
                setActiveMultiSelector(null);
            } },
            "Assigned To ",
            content)),
        React.createElement(Popup, { anchor: rootAnchor.current, show: open, ref: popup, id: "assignee-parent-popup" },
            (tenant === null || tenant === void 0 ? void 0 : tenant.flags.subcontractorsEnabled) && (React.createElement(React.Fragment, null,
                React.createElement(TechSubSelector, null),
                isSubanchorReady && (React.createElement(Popup, { ref: subPopup, anchor: document.getElementById('assignee-parent-popup'), show: activeMultiSelector !== null, anchorAlign: { horizontal: 'right', vertical: 'top' }, popupAlign: { horizontal: 'left', vertical: 'top' } }, activeMultiSelectorComponent)))),
            !(tenant === null || tenant === void 0 ? void 0 : tenant.flags.subcontractorsEnabled) && (React.createElement(TechnicianMultiSelect, { selectedTechnicians: selectedTechnicians, technicianList: technicianList, onChange: setSelectedTechnicians })))));
}
function AssigneeMultiSelectWrapper(params) {
    return (React.createElement(AssigneeMultiSelectProvider, { selectedTechnicianIds: params.selectedTechnicianIds, selectedSubcontractorIds: params.selectedSubcontractorIds, onTechChange: params.onTechChange, onSubcontractorChange: params.onSubChange },
        React.createElement(AssigneeMultiSelect, { style: params.style })));
}
export default AssigneeMultiSelectWrapper;
