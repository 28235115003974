import React from 'react';
import { translate } from '@utils/i18n';

const namespace = 'features.dashboards.home.tables';

const PaymentColumns = [
  {
    dataKey: 'invoiceId',
    width: 120,
    label: translate('related_items', { namespace }),
    renderer: ({ item }) =>
      item?.invoiceId ? <a href={`/invoices/${item?.invoiceId}`}>Invoice #{item?.invoiceId}</a> : 'N/A'
  },
  {
    dataKey: 'createdAt',
    width: 230,
    label: translate('timestamp', { namespace }),
    renderer: ({ item }) => {
      return new Date(item?.createdAt).toLocaleDateString();
    }
  },
  {
    dataKey: 'building',
    width: 290,
    label: translate('project', { namespace }),
    renderer: ({ item }) => {
      if (!item?.building) return 'N/A';
      return (
        <div>
          <span>
            Building: <a href={`/buildings/${item?.building?.id}`}>{item?.building?.name}</a>
          </span>
          {item?.building?.contact?.name ? (
            <>
              <br />
              <span>{item?.building?.contact?.name}</span>
            </>
          ) : null}
          {item?.building?.contact?.email ? (
            <>
              <br />
              <a href={`mailto:${item?.building?.contact?.email}`}>{item?.building?.contact?.email}</a>
            </>
          ) : null}
        </div>
      );
    }
  },
  {
    dataKey: 'paymentMethod',
    width: 170,
    label: translate('payment_method', { namespace }),
    renderer: ({ item }) => {
      return (
        <div>
          <span>{item?.paymentMethod}</span>
          <br />
          <span>Ending in: {item?.last4 || 'N/A'}</span>
        </div>
      );
    }
  }
];

export default PaymentColumns;
