import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';
import '../styles.scss';
import { addTransparency, BASE_ALPHA_VALUE } from '../../utils/addTransparency';
import useStatusesFormatted from './useStatusesFormatted';

function StatusCounterWidget({
  widgetTitle,
  statusesInfo,
  statusSelection,
  onShowStatusTable,
  month,
  chartsLink,
  chartsTitle,
  viewAllLink
}) {
  let barX = 0;

  const amountTotal = statusesInfo?.reduce((acc, status) => acc + status.value, 0);

  const statusesInfoFormatted = useStatusesFormatted({ statuses: statusesInfo, statusSelection });

  const visualSegment = useMemo(
    () =>
      statusesInfoFormatted.map((status) => {
        return {
          name: status.name,
          text: `${status.name}: ${status.value}`,
          width: amountTotal !== 0 ? (status.value / amountTotal) * 100 : 0,
          status: status.statusCode,
          color: status.statusColor
        };
      }),
    [statusesInfoFormatted, amountTotal]
  );

  useEffect(() => {
    const svgContain = document.getElementById(`${widgetTitle}-visual-contain`);
    const svgMain = document.getElementById(`${widgetTitle}-visual-main`);
    svgMain.innerHTML = '';

    if (amountTotal === 0) {
      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      text.setAttribute('x', 0);
      text.setAttribute('y', 0);
      text.textContent = '\ue2b8';
      svgContain.appendChild(text);

      const line = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      line.setAttribute('x', 0);
      line.setAttribute('y', 30);
      svgContain.appendChild(line);

      svgContain.setAttribute('class', '--empty');
    } else {
      let firstSegment = null;
      let lastSegment = null;

      for (let i = 0; i < visualSegment.length; i += 1) {
        const segment = visualSegment[i];

        if (segment.width > 0) {
          firstSegment = segment ?? null;
          lastSegment = segment;
        }

        if (segment === firstSegment) {
          const firstLine = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
          firstLine.setAttribute('x', 0);
          firstLine.setAttribute('y', 30);
          firstLine.setAttribute('stroke-width', 0);
          firstLine.setAttribute('class', `figure__line--first`);
          firstLine.setAttribute('style', `fill: #eee`);
          svgContain.appendChild(firstLine);
        }

        if (segment === lastSegment) {
          const lastLine = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
          lastLine.setAttribute('x', 0);
          lastLine.setAttribute('y', 30);
          lastLine.setAttribute('stroke-width', 0);
          lastLine.setAttribute('class', `figure__line--last`);
          lastLine.setAttribute('style', `fill: #eee`);
          svgContain.appendChild(lastLine);
        }

        const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        rect.setAttribute('x', `${barX}%`);
        rect.setAttribute('y', 0);
        rect.onclick = () => onClickStatus(segment.status);
        rect.setAttribute('width', `${segment.width}%`);
        rect.setAttribute('class', `figure__segment withTooltip`);
        rect.style.cursor = 'pointer';
        rect.style.fill = addTransparency(segment.color, BASE_ALPHA_VALUE);
        svgMain.appendChild(rect);

        const line = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        line.setAttribute('x', `${barX}%`);
        line.setAttribute('y', 29.5);
        line.onclick = () => onClickStatus(segment.status);
        line.setAttribute('width', `${segment.width}%`);
        line.setAttribute('class', `figure__line`);
        line.style.cursor = 'pointer';
        line.style.fill = addTransparency(segment.color, 1);
        svgMain.appendChild(line);

        const title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
        title.textContent = segment.text;
        rect.appendChild(title);

        barX += segment.width;
      }
    }
  }, [visualSegment]);

  const onClickStatus = (status) => {
    onShowStatusTable({ status });
  };

  return (
    <div className="qmb-widget--inspections--sm">
      <div className="widget__header">
        <svg className="widget__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
          <polygon points="64,0 0,64 0,0 " />
        </svg>
        <h3 className="widget__title">
          <a
            href={viewAllLink}
            className="title__link"
            title={I18n.t('features.dashboards.home.service_visits.view_all')}>
            {widgetTitle}
          </a>
          <small>{month}</small>
        </h3>
        {chartsLink && (
          <div className="widget__controls">
            <a href={chartsLink} className="qmb-control--icon" title={chartsTitle}>
              <i className="fa-light fa-chart-mixed" />
            </a>
          </div>
        )}
      </div>

      <div className="widget__body">
        <div className="widget__figure">
          <div className="figure__visual">
            <svg id={`${widgetTitle}-visual-contain`} role="img" x="0" y="0" height="32" width="100%">
              <svg id={`${widgetTitle}-visual-main`} x="12" y="0" width="calc(100% - 2.4rem)" />
            </svg>
          </div>
          <small className="figure__label">
            {amountTotal} {widgetTitle}
          </small>
        </div>

        <div className="widget__list">
          {statusesInfoFormatted.map((status, idx) => (
            <div
              key={status.statusCode}
              role="button"
              tabIndex="0"
              className="widget__counter"
              onClick={() => onClickStatus(status.statusCode)}>
              <span className="counter__value">{status.value}</span>
              <span className="counter__label">
                <i className={`qmb-status ${status.icon}`} style={{ color: status.statusColor }} />
                {status.name}
              </span>
              <span className="counter__figure">
                <span
                  className="figure__bar"
                  style={{
                    width: status.value > 0 ? `${visualSegment[idx].width}%` : 0,
                    background: addTransparency(status.statusColor, BASE_ALPHA_VALUE)
                  }}
                />
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

StatusCounterWidget.defaultProps = {
  month: '',
  statusesInfo: [],
  statusSelection: [],
  onShowStatusTable: () => {},
  viewAllLink: '#',
  chartsLink: '',
  chartsTitle: '',
  widgetTitle: ''
};

StatusCounterWidget.propTypes = {
  month: PropTypes.string,
  statusesInfo: PropTypes.array,
  statusSelection: PropTypes.array,
  onShowStatusTable: PropTypes.func,
  viewAllLink: PropTypes.string,
  chartsLink: PropTypes.string,
  chartsTitle: PropTypes.string,
  widgetTitle: PropTypes.string
};

export default StatusCounterWidget;
