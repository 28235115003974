import React, { useRef, useState, useCallback, useEffect, useContext } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import { newInspectionPath, newWorkOrderPath } from 'routes';
import { CalendarContext } from '../CalendarContext';

const namespace = 'features.calendar.create_event';

function CreateEvent({ withTitle }) {
  const { permissions } = useContext(CalendarContext);

  const anchor = useRef(null);
  const popup = useRef(null);
  const [open, setOpen] = useState(false);

  const handleBodyClick = useCallback((event) => {
    if (popup.current?._popup?.contains(event.target)) return;
    if (anchor.current.contains(event.target)) return;

    setOpen(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const onAddEventPopup = useCallback(() => {
    setOpen(!open);
  }, []);

  return (
    <>
      <Popup anchor={anchor.current} show={open} ref={popup}>
        <div className="qmb-popup--action-list">
          <ul role="presentation">
            <li key="inspection">
              <a href={newInspectionPath()} style={{ cursor: 'pointer' }}>
                <i className="fa-light fa-clipboard-check" />
                {translate('inspection', { namespace })}
              </a>
            </li>
            {permissions.hasService && (
              <li key="wo">
                <a href={newWorkOrderPath()} style={{ cursor: 'pointer' }}>
                  <i className="fa-light fa-screwdriver-wrench" />
                  {translate('work_order', { namespace })}
                </a>
              </li>
            )}
          </ul>
        </div>
      </Popup>
      {withTitle && (
        <button
          type="button"
          className="qmb-control--menu"
          ref={anchor}
          disabled={!permissions.createEvent}
          onClick={onAddEventPopup}>
          <i className="fa-light fa-circle-plus" />
          {translate('create', { namespace })}
        </button>
      )}

      {!withTitle && (
        <button type="button" className="qmb-control--icon--manage" ref={anchor} onClick={onAddEventPopup}>
          <i className="fa-light fa-circle-plus" />
        </button>
      )}
      {/* <button type="button" tabIndex="0" className="qmb-control" disabled={disabled} onClick={clearForm}>
        <i className="fa-light fa-pen-to-square" />
        {translate('button', { namespace })}
      </button> */}
    </>
  );
}

CreateEvent.propTypes = {
  withTitle: PropTypes.bool
};

CreateEvent.defaultProps = {
  withTitle: false
};

export default CreateEvent;
