import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import Row from './Row';

function Rows({ rows, loadRow, parentNodes }) {
  const rowsList = rows.map((row, index) => {
    // eslint-disable-next-line react/no-array-index-key
    return <Row key={index} row={row} index={index} loadRow={loadRow} parentNodes={parentNodes} />;
  });

  const containerClasses = () => {
    return parentNodes ? 'threaded-list__level' : 'qmb-threaded-list threaded-list__level--top';
  };

  return <ol className={containerClasses()}>{rowsList}</ol>;
}

Rows.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadRow: PropTypes.func.isRequired,
  parentNodes: PropTypes.array
};

Rows.defaultProps = {
  parentNodes: null
};

export default Rows;
