import { translate } from '@utils/i18n';
import SelectCell from "./nested/SelectCell";

export const namespace = 'features.retrieve_from_qb';

export const CACHE_KEY = 'kendo/retrieve_from_qb/index';

export const STATUS = {
  LOADING: 'LOADING',
  READY: 'READY',
  IMPORTING: 'IMPORTING',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR'
}

export const INITIAL_PAGE_SKIP = 0;

export const INITIAL_PAGE = { skip: INITIAL_PAGE_SKIP, take: 25 };

export const INITIAL_SORT = [
  {
    field: 'company_name',
    dir: 'asc'
  }
];

export const INITIAL_FILTERS = {};

export const ALL_COLUMNS = [
  'selected',
  'id',
  'name',
  'type',
  'billingAddress',
  'physicalAddress',
  'customerName',
  'customerPhone',
  'email'
];
export const ALL_COLUMNS_DATA = {
  selected: {
    id: 'selected',
    field: 'selected',
    sortable: false,
    title: translate('table.headers.selected', { namespace }),
    width: 64,
    className: 'k-table-td --selector',
    headerClassName: 'k-table-th --selector',
    cell: SelectCell,
  },
  id: {
    id: 'id',
    field: 'id',
    title: translate('table.headers.id', { namespace }),
    width: "50%",
    hidden: true,
  },
  name: {
    id: 'company_name',
    field: 'company_name',
    sortable: true,
    title: translate('table.headers.name', { namespace }),
    width: 180,
    className: 'k-table-td --primary',
    headerClassName: 'k-table-th --primary',
    value: 'company_name',
    label: translate('table.headers.name', { namespace }),
    searchable: true
  },
  type: {
    id: 'customer_type',
    field: 'customer_type',
    sortable: false,
    title: translate('table.headers.type', { namespace }),
    width: 100,
    value: 'customer_type',
    label: translate('table.headers.type', { namespace }),
  },
  billingAddress: {
    id: 'billing_address',
    field: 'billing_address',
    sortable: false,
    title: translate('table.headers.billing_address', { namespace }),
    width: 200,
    value: 'billing_address',
    label: translate('table.headers.billing_address', { namespace }),
  },
  physicalAddress: {
    id: 'physical_address',
    field: 'physical_address',
    sortable: false,
    title: translate('table.headers.physical_address', { namespace }),
    width: 200,
    value: 'physical_address',
    label: translate('table.headers.physical_address', { namespace }),
  },
  customerName: {
    id: 'name',
    field: 'name',
    sortable: true,
    title: translate('table.headers.customer_name', { namespace }),
    width: 200,
    value: 'name',
    label: translate('table.headers.customer_name', { namespace }),
    searchable: true
  },
  customerPhone: {
    id: 'phone_number',
    field: 'phone_number',
    sortable: true,
    title: translate('table.headers.phone_number', { namespace }),
    width: 120,
    value: 'phone_number',
    label: translate('table.headers.phone_number', { namespace }),
    searchable: true
  },
  email: {
    id: 'email',
    field: 'email',
    sortable: true,
    title: translate('table.headers.email', { namespace }),
    width: 200,
    value: 'email',
    label: translate('table.headers.email', { namespace }),
    searchable: true
  }
};
