import { createContext } from 'react';

const ColumnMenuContext = createContext({
  onColumnsChange: () => {},
  columns: [],
  columnsState: [],
  statusesForSelect: [],
  dispatch: () => {}
});

export default ColumnMenuContext;
