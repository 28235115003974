import React from 'react';
import PropTypes from 'prop-types';

function Upload({ upload }) {
  return (
    <li className="activity__item">
      <header className="activity__header">
        <i className="fa-light fa-screwdriver-wrench" />
      </header>
      <div className="activity__body">filename.jpg added to Documents Category #1</div>
      <footer className="activity__footer">3/04/2024 @ 1:08pm</footer>
    </li>
  );
}

Upload.propTypes = {};

Upload.defaultProps = {};

export default Upload;
