import React from 'react';
import { isValidDate } from '@fullcalendar/core/internal';
import { translate } from '@utils/i18n';
import getDatetimeFormat from '../../../../utils/getDatetimeFormat';

const namespace = 'features.dashboards.home.tables';

const DeficiencyColumns = [
  {
    dataKey: 'inspectionId',
    width: 120,
    label: `${translate('inspection', { namespace })} #`,
    renderer: ({ item }) => <a href={`/inspections/${item?.inspectionId}`}>{item?.inspectionId}</a>
  },
  {
    dataKey: 'dateOpened',
    width: 165,
    label: translate('date_opened', { namespace }),
    renderer: ({ item }) => {
      const dateOpened = new Date(item?.dateOpened);
      if (isValidDate(dateOpened)) {
        return getDatetimeFormat(item?.dateOpened);
      }
      return 'N/A';
    }
  },
  {
    dataKey: 'building',
    width: 290,
    label: translate('building', { namespace })
  },
  {
    dataKey: 'resolutionStatus',
    width: 180,
    label: translate('resolution_status', { namespace })
  }
];

export default DeficiencyColumns;
