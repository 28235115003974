import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

import InspectedItem from './InspectedItem';
import NotInspectedItem from './NotInspectedItem';

export const INSPECTED = 'inspected';
export const NOT_INSPECTED = 'not_inspected';

function ItemsSection({ category, items }) {
  let ItemsComponent;
  let title;
  let filteredItems = [];

  switch (category) {
    case INSPECTED:
      title = I18n.t('subcontractors.inspections.equipment.show.table.inspected');
      ItemsComponent = InspectedItem;
      filteredItems = items.filter((item) => item.is_inspected);
      break;

    case NOT_INSPECTED:
      title = I18n.t('subcontractors.inspections.equipment.show.table.not_inspected');
      ItemsComponent = NotInspectedItem;
      filteredItems = items.filter((item) => !item.is_inspected);
      break;
  }

  if (!filteredItems.length) return null;

  const renderItems = filteredItems.map((item, index) => {
    // eslint-disable-next-line react/no-array-index-key
    return <ItemsComponent key={index} item={item} index={index} />;
  });

  return (
    <>
      <h3 className="threaded-list__contents-label">{title}</h3>
      <ul className="qmb-itemized-list threaded-list__contents-list">{renderItems}</ul>
    </>
  );
}

ItemsSection.propTypes = {
  category: PropTypes.oneOf([INSPECTED, NOT_INSPECTED]),
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

ItemsSection.defaultProps = {
  category: NOT_INSPECTED
};

export default ItemsSection;
