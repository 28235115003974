import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@progress/kendo-react-dialogs';
import I18n, { translate } from '@utils/i18n';
import { downloadCsvInspectionsPath } from 'routes';

const namespace = 'features.inspections_index.export_csv_modal';

function ExportCsvModal({ inspectionIds, disabled, queryParams }) {
  const [visible, setVisible] = React.useState(false);
  const [includeSchedule, setIncludeSchedule] = useState(false);
  const toggleDialog = useCallback(() => {
    setVisible((prevState) => !prevState);
  }, []);

  const onSubmit = useCallback(() => {
    queryParams.append('need_contacts', includeSchedule);

    if (inspectionIds) {
      queryParams.append('checked_ids', inspectionIds);
    } else {
      queryParams.append('all', true);
    }

    window.location = `${downloadCsvInspectionsPath()}?${queryParams}`;
    toggleDialog();
  }, [inspectionIds, includeSchedule]);

  const onIncludeScheduleChange = useCallback(() => {
    setIncludeSchedule((prevState) => !prevState);
  }, []);

  return (
    <>
      <button type="button" tabIndex="0" className="qmb-control" disabled={disabled} onClick={toggleDialog}>
        <i className="fa-light fa-file-export" />
        {translate('button', { namespace })}
      </button>
      {visible && (
        <Dialog title={translate('title', { namespace })} className="qmb-dialog" onClose={toggleDialog}>
          <div className="qmb-checkbox">
            <label htmlFor="includeSchedule">
              <input
                type="checkbox"
                id="includeSchedule"
                checked={includeSchedule}
                onChange={onIncludeScheduleChange}
              />
              &nbsp;{translate('include_schedule_contacts_info', { namespace })}
            </label>
          </div>
          <p>{translate('hint_text', { namespace })}</p>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <div className="mt-3">
              <button type="button" className="qmb-button" onClick={toggleDialog}>
                {I18n.t('generic.cancel')}
              </button>
              <button type="button" className="qmb-button--submit" onClick={onSubmit}>
                {I18n.t('generic.ok')}
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}

ExportCsvModal.propTypes = {
  inspectionIds: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool.isRequired,
  queryParams: PropTypes.object.isRequired
};

ExportCsvModal.defaultProps = {
  inspectionIds: null
};

export default ExportCsvModal;
