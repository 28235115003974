import React, { useMemo } from 'react';
import { translate } from '@utils/i18n';
function TechnicianDropdown(params) {
    const namespace = 'components.technician_dropdown';
    const technicianOptions = useMemo(() => {
        const options = [];
        options.push(React.createElement("option", { key: "technician-unassigned" }, "Unassigned"));
        options.push(...params.technicianList.map((tech) => (React.createElement("option", { key: `technician-${tech.id}`, value: tech.id }, tech.name))));
        return options;
    }, [params.technicianList]);
    return (React.createElement("div", { className: "qmb-select--x-full form__field" },
        React.createElement("select", { className: "tech-select additional-tech", value: params.selectedId, onChange: (e) => {
                const selectedId = Number(e.target.value);
                const selectedTech = params.technicianList.find((tech) => tech.id === selectedId);
                params.onChange(selectedTech !== null && selectedTech !== void 0 ? selectedTech : null);
            }, disabled: params.disabled }, technicianOptions),
        React.createElement("label", { className: "qmb-label" }, translate('technician', { namespace }))));
}
export default TechnicianDropdown;
