import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popup } from "@progress/kendo-react-popup";

function ActionsCell({ colSpan, dataItem, field, className, ariaColumnIndex, style, actionsList }) {
  const anchor = useRef(null);
  const popup = useRef(null);
  const [open, setOpen] = useState(false);

  const onActionMenuClick = useCallback(() => {
    setOpen(!open);
  }, [open])

  const handleBodyClick = useCallback((event) => {
    if (popup.current?._popup?.contains(event.target)) return;
    if (anchor.current.contains(event.target)) return;

    setOpen(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  return (
    <td colSpan={colSpan} className={className} role="gridcell" aria-colindex={ariaColumnIndex} aria-selected="false" style={style}>
      <button ref={anchor} type="button" onClick={onActionMenuClick} className="qmb-control--icon--row-actions --react">
        <i className="fa-regular fa-ellipsis" />
      </button>
      <Popup anchor={anchor.current} show={open} ref={popup}>
        <div className="qmb-popup--action-list">
          <ul role="presentation">
            {actionsList.map((action) => (
              <li key={action.title}>
                {action.link && (
                  <a href={action.link}>
                    <i className={`fa-light ${action.faClass}`}/>
                    {action.title}
                  </a>
                  )}
                {action.onClick && (
                  <a onClick={action.onClick} style={{cursor: 'pointer'}}>
                    <i className={`fa-light ${action.faClass}`}/>
                    {action.title}
                </a>)}
              </li>
            ))}
          </ul>
        </div>
      </Popup>
    </td>
  );
}

ActionsCell.propTypes = {
  colSpan: PropTypes.number,
  dataItem: PropTypes.shape({
    path: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};

export default ActionsCell;
