import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

const { $, kendo } = window;

function OutstandingCustomers({ currency, isLoading, customers }) {
  const pieChartRef = useRef();

  useEffect(() => {
    if (isLoading) return;

    $(pieChartRef.current).kendoChart({
      legend: {
        visible: false
      },
      chartArea: {
        background: ''
      },
      seriesColors: ['#3D1C9D', '#6A41C7', '#9667F2', '#C28FFF', '#EFB8FF'],
      series: [
        {
          type: 'pie',
          overlay: { gradient: 'none' },
          startAngle: 150,
          data: customers.map((i) => {
            return { category: i.name, value: i.total };
          })
        }
      ],
      tooltip: {
        visible: true,
        template: `#= category#: #= kendo.format("{0:${currency}\\#,\\#\\#\\#.00}", value)#`
      }
    });
  }, [customers]);

  const customersList = customers.map((customer, i) => (
    <li key={i}>
      <a href={customer.link} target="_blank" rel="noreferrer">
        {customer.name}
      </a>
      : {currency}
      {kendo.format('{0:#,###.00}', customer.total)}
    </li>
  ));

  return (
    <div className="qmb-widget--invoices-paid--sm">
      <div className="widget__header">
        <svg className="widget__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
          <polygon points="64,0 0,64 0,0 " />
        </svg>
        <h3 className="widget__title">{I18n.t('features.dashboards.invoice_insights.outstanding_customers.title')}</h3>
      </div>

      {isLoading && (
        <div className="widget__body" style={{ display: 'flex' }}>
          <div className="load-spinner load-64">
            <svg role="img">
              <use href="/map.svg#load-spinner" />
            </svg>
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="widget__body" style={{ display: 'flex' }}>
          {customers.length > 0 && <ol>{customersList}</ol>}
          {customers.length === 0 && (
            <em className="--empty-msg">{I18n.t('features.dashboards.invoice_insights.no_results')}</em>
          )}
          <div className="widget__figure">
            <div ref={pieChartRef} style={{ height: 'inherit', width: 'inherit', borderRadius: '50%' }} />
          </div>
        </div>
      )}
    </div>
  );
}

OutstandingCustomers.propTypes = {
  currency: PropTypes.string,
  isLoading: PropTypes.bool,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string.isRequired,
      total: PropTypes.number
    })
  )
};

OutstandingCustomers.defaultProps = {
  currency: '$',
  isLoading: true,
  customers: []
};

export default OutstandingCustomers;
