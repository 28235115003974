const { kendo } = window;

export const cacheFromLocalStorage = (kendoCacheKey) => {
  if (!localStorage.getItem(kendoCacheKey)) return null;

  try {
    const cacheItem = JSON.parse(localStorage.getItem(kendoCacheKey));


    const cacheData = cacheItem.data;

    if (cacheData.stateFilters) {
      return cacheData.stateFilters;
    }
  } catch {
    console.log(`Issue with Kendo grid cache: ${kendoCacheKey}`);
    localStorage.removeItem(kendoCacheKey);
    return null;
  }
};

export const saveCacheToLocalStorage = (kendoCacheKey, data) => {
  const now = new Date();
  const cacheItem = {
    data: { stateFilters: data }
  };

  localStorage.setItem(kendoCacheKey, kendo.stringify(cacheItem));
};
