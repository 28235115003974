import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@progress/kendo-react-dialogs';
import I18n, { translate } from '@utils/i18n';
import api from '@utils/axios';
import { pushToTceInspectionsPath } from 'routes';

const namespace = 'features.inspections_index.push_to_tce_modal';

function PushToTceModal({ inspectionIds, inspectionsCount, disabled, queryParams }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const toggleDialog = () => {
    setVisible(!visible);
    setResponseMessage('');
  };

  const onSubmit = useCallback(() => {
    const body = new FormData();
    if (inspectionIds) {
      body.append('ids', inspectionIds);
    } else {
      body.append('all', 'true');
    }

    setLoading(true);
    api
      .post(pushToTceInspectionsPath(), body, { params: queryParams, headers: { Accept: 'application/json' } })
      .then(({ data }) => setResponseMessage(data.message))
      .catch(({ response }) => setResponseMessage(response.data.message))
      .finally(() => setLoading(false));
  }, [inspectionIds]);

  return (
    <>
      <button type="button" tabIndex="0" className="qmb-control" disabled={disabled} onClick={toggleDialog}>
        <i className="fa-light fa-arrow-right" />
        {translate('button', { namespace })}
      </button>
      {visible && (
        <Dialog
          title={translate('title', { namespace })}
          className="qmb-dialog"
          onClose={toggleDialog}
          closeIcon={!loading}>
          <p>
            {responseMessage && responseMessage}
            {!responseMessage && translate('confirm', { namespace, count: inspectionsCount })}
          </p>

          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            {responseMessage && (
              <div className="mt-3">
                <button type="button" className="qmb-button--submit" onClick={toggleDialog} disabled={loading}>
                  {I18n.t('generic.ok')}
                </button>
              </div>
            )}
            {!responseMessage && (
              <div className="mt-3">
                <button type="button" className="qmb-button" onClick={toggleDialog} disabled={loading}>
                  {I18n.t('generic.cancel')}
                </button>
                <button type="button" className="qmb-button--submit" onClick={onSubmit} disabled={loading}>
                  {I18n.t('generic.ok')}
                </button>
              </div>
            )}
          </div>
        </Dialog>
      )}
    </>
  );
}

PushToTceModal.propTypes = {
  inspectionIds: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool.isRequired,
  inspectionsCount: PropTypes.number.isRequired,
  queryParams: PropTypes.object.isRequired
};

PushToTceModal.defaultProps = {
  inspectionIds: null
};

export default PushToTceModal;
