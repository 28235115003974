import { useEffect, useState } from 'react';
import apiClient from '@utils/api-client';
import { apiInternalSubcontractorsPath } from '../../routes';
const useGetSubcontractors = (queryParams, condition) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(condition !== undefined ? condition : true);
    const [error, setError] = useState();
    useEffect(() => {
        if (condition === false)
            return;
        const controller = new AbortController();
        setLoading(true);
        apiClient
            .get(apiInternalSubcontractorsPath(), {
            params: queryParams,
            signal: controller.signal
        })
            .then((response) => {
            setData(response.data.data);
        })
            .catch((err) => {
            if (!controller.signal.aborted) {
                setData([]);
                setError(err);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted) {
                setLoading(false);
            }
        });
        return () => {
            controller.abort();
        };
    }, [queryParams, condition]);
    return { data, loading, error };
};
export default useGetSubcontractors;
