import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';
import { apiInternalPriceRulePath } from 'routes';
import I18n, { translate } from '@utils/i18n';
import SearchDropdown from './SearchDropdown';

const namespace = 'features.settings.price_lists.index';
const priceListInput = document.querySelector('[name*="proposal[price_list_id]"');

function MaterialsServicesCell({
  proposalProposedCorrection,
  proposedCorrection: initialProposedCorrection,
  inputName
}) {
  const [proposedCorrection, setProposedCorrection] = useState(initialProposedCorrection || { id: '', price: 0 });
  const [priceListId, setPriceListId] = useState(priceListInput.value);
  const [priceRule, setPriceRule] = useState({});
  const input = useRef();

  useEffect(() => {
    priceListInput.addEventListener('change', function () {
      setPriceListId(this.value);
    });
  }, []);

  useEffect(() => {
    if (!priceListId || !proposedCorrection?.id) {
      setPriceRule({});
      return;
    }

    const params = { price_list_id: priceListId };
    if (isProposalProposedCorrectionChanged) {
      params.proposed_correction_id = proposedCorrection?.id;
    } else {
      params.proposal_proposed_correction_id = proposalProposedCorrection.id;
    }

    api.get(apiInternalPriceRulePath(params)).then(({ data }) => setPriceRule(data.price_rule));
  }, [priceListId, proposedCorrection]);

  const handleSelectionClear = () => {
    setProposedCorrection({ id: '', price: 0 });
  };

  const isProposalProposedCorrectionChanged = useMemo(
    () => proposalProposedCorrection.proposedCorrectionId?.toString() !== proposedCorrection.id.toString(),
    [proposedCorrection]
  );

  const price = useMemo(() => {
    const initPrice = isProposalProposedCorrectionChanged
      ? proposedCorrection.price
      : proposalProposedCorrection.proposedCorrectionPrice;

    switch (priceRule?.ruleType) {
      case 'fixed':
        return priceRule.value;
      case 'discount':
        return initPrice * (1 - priceRule.value / 100);
      case 'markup':
        return initPrice * (1 + priceRule.value / 100);
      default:
        return initPrice;
    }
  }, [priceRule]);

  useEffect(() => {
    input.current.dispatchEvent(new Event('change', { bubbles: true }));
  }, [price]);

  return (
    <>
      <input
        type="hidden"
        ref={input}
        name={inputName}
        value={proposedCorrection?.id}
        data-price={price}
        data-js-proposal-proposed-correction
      />
      {proposedCorrection?.id && (
        <div
          className="qmb-input --x-full"
          style={{ display: 'flex', padding: '8px', boxSizing: 'border-box', alignItems: 'center', width: '24rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <small>
              {proposedCorrection?.category}: {proposedCorrection?.type}
            </small>
            <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '.5rem', alignItems: 'baseline' }}>
              {proposedCorrection.description}
              <button type="button" className="qmb-control" style={{ padding: '3px 6px', borderRadius: '10px' }}>
                {/* <small>{priceRule?.asApplicable ?? 'No Price Rule'}</small> */}
              </button>
            </div>
          </div>
          <button type="button" className="qmb-control--icon" onClick={handleSelectionClear}>
            <i className="fa-light fa-xmark" />
          </button>
        </div>
      )}

      <SearchDropdown proposedCorrection={proposedCorrection} setProposedCorrection={setProposedCorrection} />
    </>
  );
}

MaterialsServicesCell.propTypes = {
  proposalProposedCorrection: PropTypes.object.isRequired,
  proposedCorrection: PropTypes.object,
  inputName: PropTypes.string.isRequired
};

MaterialsServicesCell.defaultProps = {
  proposedCorrection: {
    id: '',
    price: 0
  }
};

export default MaterialsServicesCell;
