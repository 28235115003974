import React, {useEffect, useRef, useState} from "react";
import { Popup } from "@progress/kendo-react-popup";
import I18n from '@utils/i18n';

function ProposalGroupAllButton({tableId}){
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [selectedCount, setSelectedCount] = useState(0)

    const anchor = useRef(null)
    const popupRef = useRef(null)


    const buttonClick = (e) => {
        setDropdownOpen(!dropdownOpen);
    }

    const countSelected = () => {
        const totalCount = $(`#${tableId} [name*='selected']:visible`).length
        const selectedCount = $(`#${tableId} [name*='selected']:checked:visible`).length

        if (selectedCount !== totalCount){
            document.querySelector(`#${tableId} [name*='selectAll']`).checked = false;
        }

        setSelectedCount(selectedCount)
    }

    useEffect(()=> {
        document.addEventListener('SelectAllItems', function(event){
            if (event.detail === tableId){
                const allStatus = document.querySelector(`#${tableId} [name*='selectAll']`).checked
                const inputs = document.querySelectorAll(`#${tableId} [name*='selected']`)
                Array.from(inputs)
                    .forEach(element => {
                        element.checked = allStatus;
                    })
                countSelected()
            }
        })

        document.addEventListener('SelectSingleItem', function(event){
            if (event.detail === tableId){
                countSelected()
            }
        })

        $(document).on("fields_removed.nested_form_fields", function() {
            countSelected()
        })


        document.querySelector('.partition__workspace').addEventListener('scroll', function(e){
            setDropdownOpen(false)
        })

        document.body.addEventListener('click', function(e){
            if (anchor.current.contains(e.target))
                return;
            if (!popupRef.current?.contains(e.target))
                setDropdownOpen(false)
        })


    }, [])

    return(
        <React.Fragment>

            <div className="qmb-actions">
                {selectedCount > 0 &&
                    <div className="actions__group--selection">
                        <button type="button" className="actions__selection">
                            <span className="actions__count">{selectedCount}</span>
                        </button>
                    </div>
                }
                <div className="actions__group">
                    <button type="button" ref={anchor} className="qmb-control" onClick={buttonClick}>
                        <i className="fa-light fa-grid-2-plus"></i> {I18n.t('features.group_lookup.group_selected')}
                    </button>
                </div>
            </div>

            <Popup anchor={anchor.current} show={dropdownOpen}>
                <Dropdown tableId={tableId} closeDropdown={()=>setDropdownOpen(false)} popupRef={popupRef} />
            </Popup>
        </React.Fragment>
    )

}

function Dropdown({tableId, closeDropdown, popupRef}){
    const [isLoading, setIsLoading] = useState(true);
    const [allGroups, setAllGroups] = useState([])
    const [filteredGroups, setFilteredGroups] = useState([])

    const searchRef = useRef(null);

    useEffect(()=>{
        setAllGroups(FindExistingGroups());
        setFilteredGroups(FindExistingGroups());
        setIsLoading(false);
    }, [])


    const Content_Spinner = () => {
        return (
            <div className="qmb-loading--32--vertical" data-title={I18n.t('generic.loading')} style={{padding: "2rem", alignSelf: "center", flex: "1 1 auto"}}>
                <svg role="img">
                    <use href="/map.svg#load-spinner"/>
                </svg>
            </div>
        )
    }

    const NewGroupButton = () => {
        return (
            <button className={"qmb-control"} onClick={() => AddNewGroup(searchRef.current.value)}>
                {I18n.t('features.group_lookup.create_group')}<i className="fa-light fa-turn-down-left"></i>
            </button>
        )
    }

    const Content_Groups = () => {
        const GroupItem = ({desc}) => {
            return (
                <button className={"qmb-control"} title={desc} onClick={() => AddSelectedToGroup(desc)}>
                    {desc}
                </button>
            )
        }
        return (
            <React.Fragment>
                {filteredGroups.map(x => <GroupItem key={`group_${x}`} desc={x}/>)}
            </React.Fragment>
        )
    }


    const Content = () => {
        if (isLoading){
            return <Content_Spinner/>
        } else {
            return <Content_Groups />
        }
    }


    const AddNewGroup = (groupName) => {
        if (groupName){
            $('#add_new_proposal_summary_item').trigger('click', [{isGroup: true, description: groupName}])
            AddSelectedToGroup(groupName);
        }
    }
    const AddSelectedToGroup = (groupName) => {
        if (groupName){
            const rows = Array.from(document.querySelectorAll(`#${tableId} tbody tr`)).filter(x => {
                return x.querySelector("[name*='selected']")?.checked
            })

            rows.forEach(x => {
                const y = x.querySelector("[name*='group_description']")
                if (y){
                    y.value = groupName;
                    y.dispatchEvent(new Event('change'));
                }
            })
            document.dispatchEvent(new Event('GroupWatcherTrigger'));
            closeDropdown();
        }
    }

    const HandleKeyUp = (e) => {
        const value = e.target.value;
        if (e.key === 'Enter'){
            allGroups.includes(value) ? AddSelectedToGroup(value) : AddNewGroup(value)
        }
        else{
            setFilteredGroups(allGroups.filter(x => x.toLowerCase().startsWith(e.target.value.toLowerCase())))
        }
    }

    return (
        <div tabIndex={0} className={"qmb-popup"} ref={popupRef}>
            <div className={"qmb-input--blank"}>
                <input ref={searchRef} placeholder={I18n.t('features.group_lookup.enter_name')} onKeyUp={HandleKeyUp} />
            </div>

            <div style={{display: "flex", flexDirection: "column", maxHeight: "120px", overflowY: "auto" }}>
                <Content/>
                <NewGroupButton/>
            </div>

        </div>
    )
}

function FindExistingGroups(){
    const availableGroups = document.querySelectorAll("tr:has([name*='[is_group]'])")
    return Array.from(availableGroups).map(tr => {
        const isGroup = tr.querySelector("[name*='[is_group]']").value === 'true'
        if (isGroup && tr.style.display !== 'none' && getComputedStyle(tr).display !== 'none'){
            const descriptionInput = tr.querySelector('[name*="[description]"]');
            return descriptionInput.value ? descriptionInput.value : null
        }
        else {
            return null;
        }
    }).filter(x => x !== null);
}


export default ProposalGroupAllButton;