import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function Item({ index, item, update, remove }) {
  const rename = () => {
    update(index, { name: Math.random().toString(36).substr(2, 10) });
  };

  return (
    <li className="list-item d-flex py-2 mb-2 align-items-center react-layout-class">
      <div className={`list-item-title w-40 react-application-class ${styles['scoped-class']}`}>
        <p>
          <span>User</span>
          {item.name}
        </p>
      </div>
      <div className="list-item-description w-40">{item.description}</div>
      <div className="d-flex w-10">
        <input type="button" className="btn btn-primary" onClick={rename} value="Rename Item" />
      </div>
      <div className="d-flex w-10">
        <input type="button" className="btn btn-danger" onClick={() => remove(index)} value="Remove Item" />
      </div>
    </li>
  );
}

Item.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  update: PropTypes.func,
  remove: PropTypes.func
};

Item.defaultProps = {
  update: () => {},
  remove: () => {}
};

export default Item;
