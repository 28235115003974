export default (() => {
  let executed = false;
  return (cachedPage, dataSource) => {
    if (!executed) {
      executed = true;
      if (cachedPage === 1 || String(window.performance.getEntriesByType('navigation')[0].type) !== 'back_forward')
        return;
      dataSource.page(cachedPage);
    }
  };
})();
