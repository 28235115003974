import React from "react";

function ProposalNewGroupButton({}){

    const buttonClick = (e) => {
        const count = $('.nested_proposal_proposal_summary_items').length
        $('#add_new_proposal_summary_item').trigger('click', [{isGroup: true, description: `New Group ${count + 1}`}])
    }


    return(

        <div className="qmb-actions">
            <div className="actions__group">
                <button type="button" className="qmb-control" onClick={buttonClick}>
                    <i className="fa-light fa-circle-plus"></i> {I18n.t('features.group_lookup.new_group')}
                </button>
            </div>
        </div>

    )

}

export default ProposalNewGroupButton;