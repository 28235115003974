import React from 'react';

function ShallowTechnician({ technician }) {
  return (
    <div className="schedule__card--tech">
      <svg className="card__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
        <polygon points="64,0 0,64 0,0 " />
      </svg>
      <div className="card__body">
        <div className="card__text">
          <Icon technician={technician} />
          <span>{technician.name}</span>
        </div>
      </div>
    </div>
  );
}

function Icon({ technician }) {
  if (!technician.photoUrl) {
    return (
      <span className="qmb-avatar--32">
        <svg viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8" style={{ fill: technician.color }} />
          <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
            {technician.name[0]}
          </text>
        </svg>
      </span>
    );
  }
  return (
    <span className="qmb-avatar--32">
      <img
        style={{ borderRadius: '50%', border: `2px solid ${technician.color}`, boxSizing: 'border-box' }}
        src={technician.photoUrl}
      />
    </span>
  );
}

export default ShallowTechnician;
