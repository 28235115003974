import { useMemo } from 'react';
import { BaseOtherStatus } from './constants';

export default function useStatusesFormatted({ statuses, statusSelection }) {
  const statusesFormatted = useMemo(
    () =>
      statuses
        .reduce((acc, status) => {
          if (statusSelection.includes(status.statusCode)) {
            return [...acc, status];
          }
          if (status.value > 0) {
            const otherStatus = acc.find((s) => s.statusCode === 'other');
            if (otherStatus) {
              otherStatus.value += status.value;
            } else {
              return [...acc, { ...BaseOtherStatus, value: status.value }];
            }
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.statusCode === BaseOtherStatus.statusCode) {
            return 1;
          }
          if (b.statusCode === BaseOtherStatus.statusCode) {
            return -1;
          }
          return 0;
        }),
    [statuses, statusSelection]
  );

  return statusesFormatted;
}
