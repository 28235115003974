import { isDateRangeStateField } from '@components/Kendo/DateRangePickerReducer';

export default ({ page, sort, filters, search, optionalParams }) => {
  const urlParams = createPageParams(page);
  addSortParams(urlParams, sort);
  addFilterParams(urlParams, filters);
  addSearchParams(urlParams, search);
  addOptionalParams(urlParams, optionalParams);

  return urlParams;
};

const createPageParams = (page) => {
  if (!page) return new URLSearchParams();

  return new URLSearchParams({
    page: (page.skip + page.take) / page.take,
    pageSize: page.take,
    skip: page.skip,
    take: page.take
  });
};

const addSortParams = (urlParams, sort) => {
  if (!sort) return;

  sort.forEach((s, index) => {
    urlParams.append(`sort[${index}][field]`, s.field);
    urlParams.append(`sort[${index}][dir]`, s.dir);
  });
};

const addFilterParams = (urlParams, allFilters) => {
  if (!allFilters) return;

  const filters = Object.entries(allFilters.values).filter(
    ([filter, value]) =>
      (!isDateRangeStateField(value) && value) || (isDateRangeStateField(value) && (value.startDate || value.endDate))
  );

  if (filters.length > 0) urlParams.append('filter[logic]', 'and');

  filters.forEach(([filter, value], index) => {
    urlParams.append(`filter[filters][${index}][field]`, filter);
    urlParams.append(`filter[filters][${index}][operator]`, allFilters.operators[filter] || 'eq');

    if (isDateRangeStateField(value)) {
      urlParams.append(`filter[filters][${index}][value][rangeType]`, value.rangeType);
      urlParams.append(`filter[filters][${index}][value][startDate]`, value.startDate);
      urlParams.append(`filter[filters][${index}][value][endDate]`, value.endDate);
      urlParams.append(`filter[filters][${index}][value][fieldType]`, 'dateRange');
    } else if (Array.isArray(value)) {
      value.forEach((item) => {
        urlParams.append(`filter[filters][${index}][value][]`, item);
      });
    } else {
      urlParams.append(`filter[filters][${index}][value]`, value);
    }
  });
};

const addSearchParams = (urlParams, search) => {
  if (!search) return;

  urlParams.append('search', search);
};

const addOptionalParams = (urlParams, optionalParams) => {
  if (!optionalParams) return;

  Object.keys(optionalParams).forEach((key) => {
    const value = optionalParams[key];
    urlParams.append(key, value);
  });
};
