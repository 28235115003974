import { useCallback, useEffect, useState } from 'react';
import api from 'axios';
import { apiInternalTenantInfoPath } from '../../routes';
const useGetTenantDetails = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const fetchData = useCallback((controller) => {
        setLoading(true);
        api
            .get(apiInternalTenantInfoPath(), {
            signal: controller.signal
        })
            .then((response) => {
            setData(response.data.data);
        })
            .catch((err) => {
            if (!controller.signal.aborted) {
                setData(undefined);
                setError(err);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted) {
                setLoading(false);
            }
        });
    }, []);
    useEffect(() => {
        const controller = new AbortController();
        fetchData(controller);
        return () => {
            controller.abort();
        };
    }, []);
    return { data, loading, error };
};
export default useGetTenantDetails;
