import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

function Total({ currency, amount, count, isLoading, title, daysDue }) {
  const formattedAmount = Number(amount).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedAverage = Number(amount / count).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return (
    <div className="qmb-widget--total-invoiced--sm">
      <div className="widget__header">
        <svg className="widget__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
          <polygon points="64,0 0,64 0,0 " />
        </svg>
        <h3 className="widget__title">{title}</h3>
      </div>
      {isLoading && (
        <div className="widget__body" style={{ display: 'flex' }}>
          <div className="load-spinner load-64">
            <svg role="img">
              <use href="/map.svg#load-spinner" />
            </svg>
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="widget__body" style={{ display: 'flex' }}>
          {amount === 0 && <em className="--empty-msg">{I18n.t('features.dashboards.invoice_insights.no_results')}</em>}
          {amount > 0 && (
            <span className="widget__counter" title="View All Invoices">
              <span className="counter__value">
                <span>{currency}</span>
                {formattedAmount}
              </span>
              <span className="counter__label">
                {count} {I18n.t('features.dashboards.invoice_insights.total.invoices')}
              </span>
              <span className="counter__label">
                {I18n.t('features.dashboards.invoice_insights.total.avg')} <span>{currency}</span>
                {formattedAverage}
              </span>
              {daysDue && (
                <span className="counter__label">
                  {I18n.t('features.dashboards.invoice_insights.total.days_due')} {daysDue}
                </span>
              )}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

Total.propTypes = {
  currency: PropTypes.string,
  amount: PropTypes.number,
  count: PropTypes.number,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  daysDue: PropTypes.number
};

Total.defaultProps = {
  currency: '$',
  amount: 0,
  count: 1,
  isLoading: true,
  title: 'Total',
  daysDue: null
};

export default Total;
