/* eslint-disable react/no-unescaped-entities */

import React, { useEffect, useReducer, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import api from '@utils/axios';

import I18n, { translate } from '@utils/i18n';

import CommonSelect from '@components/Kendo/CommonSelect';
import BooleanSelect from '@components/Kendo/BooleanSelect';
import DateRangePicker from '@components/Kendo/DateRangePicker';
// import PagedMultiSelect from '@components/FilterPanel/nested/PagedMultiSelect';

import { DEFAULT_PAGE_SIZE, pageSizes, buildGridSettings } from '@constants/Kendo/gridSettings';
import { actionTypes, reducer, buildInitialFiltersState, isDateRangeStateField } from './IndexTableReducer';
import restoreGridPage from '../../../helpers/restoreGridPage';
import CustomScrollbar from '../../../components/CustomScrollbar/CustomScrollbar';
import GenericMultiSelect from '../../../components/Multiselects/GenericMultiSelect/GenericMultiSelect.tsx';

// Add JQuery
const { kendo } = window;
const { $, confirm, ConfirmationModal } = window;

const DEFAULT_NAMESPACE = 'reports.datatable';
const DEFAULT_CACHE_KEY = 'reports/index';
const DEFAULT_NON_HIDABLE_COLUMNS = ['selectReports', 'actions'];

// TODO: re-visit and see how we could minify the relationship between sprockets and webpacker part.
// Ideally, we should not mix them. But to be eliminate the waiting time for the duration
// of whole project being re-written and to be able to use React now - it's worth it.

function IndexTable({
  namespace,
  tableTitle,
  reportsUrl,
  deleteReportsUrl,
  markReportsPublicUrl,
  buildingsForSelect,
  inspectionTypesForSelect,
  buildingStatusesForSelect,
  territoriesForSelect,
  hasShowAllReportsInCustomerPortal,
  canDeleteReports,
  cacheKey,
  defaultHiddenColumns,
  nonHidableColumns,
  hasShowInspectionDateFilter,
  hasShowIsPublicReportFilter,
  hasDisableAllFilters,
  hasDisableAllBulkActions,
  pageSize
}) {
  const kendoCacheKey = `kendo/${cacheKey}`;
  const containerRef = useRef();
  const tableRef = useRef();
  const [state, dispatch] = useReducer(reducer, {
    tableFingerprint: undefined,
    reports: [],
    filters: buildInitialFiltersState()
  });

  // Mechanism to make state being up-to-date in Kendo grid callbacks.
  // Src: https://stackoverflow.com/a/60643670/20974169
  const filtersRef = useRef();
  filtersRef.current = state.filters;

  const tableFingerprintRef = useRef();
  tableFingerprintRef.current = state.tableFingerprint;

  const isMounted = !!state.tableFingerprint;
  const isMountedForCallbacks = () => !!tableFingerprintRef.current;
  let cachedPage = 1;

  useEffect(() => {
    setupTable();
    setupTableControls();
    loadCacheFromLocalStorage();

    dispatch({ type: actionTypes.TABLE_RELOADED });
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    reloadTable();
    reloadTableControls();
  }, [state.tableFingerprint]);

  // ===================== JS Part ================================

  const setupTable = () => {
    const gridSettings = buildGridSettings(tableRef.current, {
      columns: [
        {
          field: 'selectReports',
          title: translate('table.headers.select_reports', { namespace }),
          sortable: false,
          columnMenu: false,
          headerTemplate: checkBoxHeaderTemplate,
          template: checkBoxTemplate,
          hidden: defaultHiddenColumns.includes('selectReports'), // to hide column by default
          menu: !nonHidableColumns.includes('selectReports'), // to show column in menu
          attributes: {
            class: 'table__cell--selectable'
          }
        },
        {
          field: 'contents',
          title: translate('table.headers.report', { namespace }),
          hidden: defaultHiddenColumns.includes('contents'),
          menu: !nonHidableColumns.includes('contents'),
          template: contentsTemplate,
          sortable: false
        },
        {
          field: 'inspectionDate',
          title: translate('table.headers.inspection_date', { namespace }),
          template: inspectionDateTemplate,
          hidden: defaultHiddenColumns.includes('inspectionDate'),
          menu: !nonHidableColumns.includes('inspectionDate')
        },
        {
          field: 'account',
          title: I18n.t('generic.account'),
          template: accountTemplate,
          hidden: defaultHiddenColumns.includes('account'),
          menu: !nonHidableColumns.includes('account')
        },
        {
          field: 'building',
          title: I18n.t('generic.building'),
          template: buildingTemplate,
          hidden: defaultHiddenColumns.includes('building'),
          menu: !nonHidableColumns.includes('building')
        },
        {
          field: 'systemAsset',
          title: translate('table.headers.system_asset', { namespace }),
          sortable: false,
          template: `#= (systemAsset) ? systemAsset : '-' #`,
          hidden: defaultHiddenColumns.includes('systemAsset'),
          menu: !nonHidableColumns.includes('systemAsset')
        },
        {
          field: 'reportType',
          title: I18n.t('generic.report_type'),
          template: reportTypeTemplate,
          hidden: defaultHiddenColumns.includes('reportType'),
          menu: !nonHidableColumns.includes('reportType')
        },
        {
          field: 'displayName',
          title: translate('table.headers.display_name', { namespace }),
          template: `#= (displayName) ? displayName : '-' #`,
          hidden: defaultHiddenColumns.includes('displayName'),
          menu: !nonHidableColumns.includes('displayName')
        },
        {
          field: 'isPublicReport',
          title: translate('table.headers.is_public_report', { namespace }),
          hidden: defaultHiddenColumns.includes('isPublicReport'),
          menu: !nonHidableColumns.includes('isPublicReport')
        },
        {
          field: 'sentOn',
          title: translate('table.headers.sent_on', { namespace }),
          template: "#= (sentOn) ? sentOn : '-' #",
          hidden: defaultHiddenColumns.includes('sentOn'),
          menu: !nonHidableColumns.includes('sentOn')
        },
        {
          field: 'actions',
          title: ' ',
          sortable: false,
          columnMenu: false,
          template: actionsTemplate,
          hidden: defaultHiddenColumns.includes('actions'),
          menu: !nonHidableColumns.includes('actions'),
          attributes: {
            class: 'table__cell--options--col-actions'
          }
        }
      ],
      pageable: {
        input: true,
        numeric: false,
        pageSize,
        pageSizes: pageSizes(pageSize)
      },
      dataSource: {
        schema: {
          model: {
            fields: {
              id: { type: 'number' },
              inspectionDate: { type: 'string' },
              createdAt: { type: 'string' },
              building: { type: 'string' },
              systemAsset: { type: 'string' },
              displayName: { type: 'string' },
              isPublicReport: { type: 'string' },
              hasPdfPresent: { type: 'boolean' },
              sentOn: { type: 'string' },
              account: { type: 'string' }
            }
          }
        },
        sort: { field: 'createdAt', dir: 'desc' }
      },
      scrollable: false,
      columnShow: saveCacheToLocalStorage,
      columnHide: saveCacheToLocalStorage,
      dataBinding: () => {
        $('.rerender-inspection-reports-popup').remove();
      },
      dataBound: () => {
        new ConfirmationModal().init();
        saveCacheToLocalStorage();
        // eslint-disable-next-line no-undef
        loadDropdowns({ gridSelector: '#inspection-reports-grid', email_reports_callback: onSendEmail });

        restoreGridPage(cachedPage, $(tableRef.current).data('kendoGrid').dataSource);
        const pager = $(tableRef.current).data('kendoGrid').pager.element;
        pager.on('change', 'input', () => {
          const page = parseInt($(this).val(), 10);
          $(tableRef.current).data('kendoGrid').dataSource.page(page);
        });
      }
    });
    $(tableRef.current).kendoGrid(gridSettings);
  };

  const checkBoxTemplate = ReactDOMServer.renderToString(
    <div className="--checkbox">
      <input
        type="checkbox"
        data-download-link="#= downloadLink #"
        data-report-id="#= id #"
        data-js-bulk-single-checkbox
      />
    </div>
  );
  const contentsTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (hasPdfPresent) { #'}
      <a href="#= pdfLink #" title={I18n.t('generic.view')} target="blank" rel="noopener noreferrer">
        <i className="fa-light fa-eye" /> <span>#= createdAt #</span>
      </a>
      {'# } else { #'}
      <i>
        <span className="grey">#= reportStatus #</span>
      </i>
      {'# } #'}
    </>
  );
  const checkBoxHeaderTemplate = ReactDOMServer.renderToString(
    <input type="checkbox" className="--checkbox" data-js-bulk-all-checkbox />
  );
  const inspectionDateTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (inspectionDate) { #'}
      {'# if (inspection == window.location.pathname) { #'}
      <span>#= inspectionDate #</span>
      {'# } else { #'}
      <u>
        <a href="#= inspection #">#= inspectionDate #</a>
      </u>
      {'# } #'}
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );
  const accountTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (account) { #'}
      {'# if (account == window.location.pathname) { #'}
      <span>#= accountName #</span>
      {'# } else { #'}
      <u>
        <a href="#= account #">#= accountName #</a>
      </u>
      {'# } #'}
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );
  const buildingTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (building) { #'}
      {'# if (building == window.location.pathname) { #'}
      <span>#= buildingName #</span>
      {'# } else { #'}
      <u>
        <a href="#= building #">#= buildingName #</a>
      </u>
      {'# } #'}
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const reportTypeTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (reportType) { #'}
      {'# if (inspection == window.location.pathname) { #'}
      <span>#= reportType #</span>
      {'# } else { #'}
      <u>
        <a href="#= inspection #">#= reportType #</a>
      </u>
      {'# } #'}
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );
  const actionsTemplate = ReactDOMServer.renderToString(
    <div>
      <button type="button" className="qmb-control--icon--row-actions --react">
        <i className="fa-regular fa-ellipsis" />
      </button>
      <div
        id="inspection_reports_popup_#= id #"
        className="qmb-popup--action-list single-reports-popup rerender-inspection-reports-popup">
        <ul role="presentation">
          {'# if (hasPdfPresent) { #'}
          <li>
            <a
              href="#= pdfLink #"
              title={I18n.t('generic.view')}
              className="qmb-control"
              target="blank"
              rel="noopener noreferrer">
              <i className="fa-light fa-eye" /> {I18n.t('generic.view')}
            </a>
          </li>

          <li>
            <a href="#= downloadLink #" title={I18n.t('generic.download')} className="qmb-control">
              <i className="fa-light fa-circle-down" /> {I18n.t('generic.download')}
            </a>
          </li>

          <li>
            <a
              href="mailto:#= encodeURIComponent(emails) #?body=#= encodeURIComponent(emailBody) #&subject=#= encodeURIComponent(emailSubject) #"
              title={translate('table.buttons.send_to_contacts', { namespace })}
              className="qmb-control"
              id="email-reports"
              data-id="#= id #"
              data-url="#= showLink #">
              <i className="fa-light fa-envelope" /> {translate('table.buttons.send_to_contacts', { namespace })}
            </a>
          </li>

          {'# } #'}

          {'# if (canEdit) { #'}
          <li>
            <a
              href="#= editLink #"
              title={I18n.t('generic.edit_details')}
              className="qmb-control"
              target="blank"
              rel="noopener noreferrer"
              data-remote="true">
              <i className="fa-light fa-pen-to-square" /> {I18n.t('generic.edit_details')}
            </a>
          </li>
          {'# } #'}

          {'# if (tceButtonEnabled) { #'}
          <li>
            <a
              href="\\#"
              type="button"
              title={I18n.t('reports.datatable.table.buttons.push_to_tce')}
              className="qmb-control tce-single-report-submission"
              data-id="#= id #:#= technicianId #">
              <i className="fa-light fa-arrow-right" />{' '}
              <span>{I18n.t('reports.datatable.table.buttons.push_to_tce')}</span>
            </a>
          </li>
          {'# } #'}

          {'# if (canDelete) { #'}
          <li>
            <a
              href="/"
              type="button"
              title={I18n.t('generic.delete')}
              className="qmb-control--delete"
              data-title={translate('table.confirm_delete', { namespace })}
              data-link="#= deleteLink #"
              data-link-method="delete"
              data-js-confirmation-modal-trigger
              data-needs-confirm>
              <i className="fa-light fa-trash-can" /> {I18n.t('generic.delete')}
            </a>
          </li>
          {'# } #'}
        </ul>
      </div>
    </div>
  );

  const saveCacheToLocalStorage = () => {
    if (!isMountedForCallbacks()) return;

    const grid = $(tableRef.current).data('kendoGrid');
    const cacheData = {};

    cacheData.hiddenColumns = grid.columns.filter((column) => column.hidden).map((column) => column.field);
    cacheData.stateFilters = filtersRef.current;
    // eslint-disable-next-line no-underscore-dangle
    cacheData.kendoSortSettings = grid.dataSource._sort;
    cacheData.page = grid.dataSource.page();

    localStorage.setItem(kendoCacheKey, kendo.stringify(cacheData));
  };

  const setupTableControls = () => {
    $(tableRef.current).on('change', '[data-js-bulk-all-checkbox]', onSelectAllToggle);
    $(tableRef.current).on('click', '[data-js-bulk-single-checkbox]', onCheckboxToggle);
  };

  const onCheckboxToggle = (e) => {
    const $controls = $('.js-bulk-action', containerRef.current);

    const checkbox = e.currentTarget;
    const reportId = checkbox.getAttribute('data-report-id');

    const $anyCheckedCheckbox = $('[data-js-bulk-single-checkbox]:checked', tableRef.current);
    $controls.attr('disabled', $anyCheckedCheckbox.length === 0);

    if (checkbox.checked) {
      dispatch({ type: actionTypes.REPORT_SELECTED, id: reportId });
    } else {
      $('[data-js-bulk-all-checkbox]', tableRef.current).prop('checked', false);
      dispatch({ type: actionTypes.REPORT_DESELECTED, id: reportId });
    }
  };

  const onSendEmail = (e) => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!

    const yyyy = today.getFullYear();
    today = `${mm}/${dd}/${yyyy}`;

    /* eslint-disable no-alert */
    api
      .patch(e.currentTarget.dataset.url, { report: { sent_on: today } }, { headers: { Accept: 'application/json' } })
      .then(() => dispatch({ type: actionTypes.TABLE_RELOADED }))
      .catch(({ message }) => alert(`${I18n.t('generic.something_went_wrong')} - ${message}`));
    /* CV - animation container is still present in dom after this action. force hide */
    $('.k-animation-container').remove();
  };

  const onSelectAllToggle = (e) => {
    const $checkbox = $(e.currentTarget);

    if (e.currentTarget.checked) {
      $('[data-js-bulk-single-checkbox]', tableRef.current).prop('checked', true);
      dispatch({ type: actionTypes.REPORTS_DESELECTED });

      $('[data-js-bulk-single-checkbox]', tableRef.current).each((_index, row) => {
        dispatch({ type: actionTypes.REPORT_SELECTED, id: row.getAttribute('data-report-id') });
      });
    } else {
      $('[data-js-bulk-single-checkbox]', tableRef.current).prop('checked', false);
      dispatch({ type: actionTypes.REPORTS_DESELECTED });
    }

    const $controls = $('.js-bulk-action', containerRef.current);
    $controls.attr('disabled', !$checkbox.is(':checked'));
  };

  const loadCacheFromLocalStorage = () => {
    if (!localStorage.getItem(kendoCacheKey)) return;

    try {
      const cacheData = JSON.parse(localStorage.getItem(kendoCacheKey));
      const grid = $(tableRef.current).data('kendoGrid');

      if (cacheData.hiddenColumns) {
        cacheData.hiddenColumns.forEach((fieldName) => grid.hideColumn(fieldName));

        grid.columns
          .map((column) => column.field)
          .filter((column) => !cacheData.hiddenColumns.includes(column))
          .forEach((fieldName) => grid.showColumn(fieldName));
      } else {
        grid.columns.map((column) => column.field).forEach((fieldName) => grid.showColumn(fieldName));
      }

      // eslint-disable-next-line no-underscore-dangle
      if (cacheData.kendoSortSettings) grid.dataSource._sort = cacheData.kendoSortSettings;
      if (cacheData.stateFilters) {
        dispatch({ type: actionTypes.LOAD_FILTERS_FROM_CACHE, filters: cacheData.stateFilters });
      }
      if (cacheData.page) {
        cachedPage = cacheData.page;
      }
    } catch {
      console.log(`Issue with Kendo grid cache: ${kendoCacheKey}`);
      localStorage.removeItem(kendoCacheKey);
    }
  };

  const reloadTable = () => {
    const filtersData = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(state.filters)) {
      if (!value) continue;
      // Date filters require a bit of different checks around them in different places.
      // If that part would be missed, it won't be critical, such as Back-End is prepared for empty values.
      //
      // This part is only about highlighting the grid header with red background
      // when inspectionDate field was used in filters.
      if (isDateRangeStateField(state.filters[key]) && !value.startDate && !value.endDate) continue;

      filtersData.push({ field: key, operator: 'eq', value });
    }

    $(tableRef.current).data('kendoGrid').dataSource.filter(filtersData);
  };

  const reloadTableControls = () => {
    const $controls = $('.js-bulk-action', containerRef.current);
    $controls.attr('disabled', true);

    const $selectAll = $('[data-js-bulk-all-checkbox], [data-js-bulk-single-checkbox]', tableRef.current);
    $selectAll.prop('checked', false);
  };

  const onBulkDownload = (e) => {
    e.preventDefault();

    // eslint-disable-next-line no-alert
    if (!state.reports.length) return alert(translate('table.select_report', { namespace }));

    $('[data-js-bulk-single-checkbox]:checked', tableRef.current).each((_event, item) => {
      const url = item.getAttribute('data-download-link');

      if (url) window.open(url, '_blank');
    });
  };

  const onBulkPublish = (e) => {
    e.preventDefault();

    gatherReportsAndRedirect(markReportsPublicUrl, translate('table.confirm_bulk_publish', { namespace }));
  };

  const gatherReportsAndRedirect = (url, message) => {
    // eslint-disable-next-line no-alert
    if (!state.reports.length) return alert(translate('table.select_report', { namespace }));

    let queryParams = url.includes('building_id=') || url.includes('inspection_id=') ? '&' : '?';

    state.reports.forEach((reportId) => {
      queryParams = `${queryParams}ids[]=${reportId}&`;
    });
    queryParams = queryParams.slice(0, -1);

    const response = !message || confirm(message);
    if (response) {
      window.location.href = `${url}${queryParams}`;
    }
  };

  const onBulkDelete = (e) => {
    e.preventDefault();

    gatherReportsAndRedirect(deleteReportsUrl, translate('table.confirm_bulk_delete', { namespace }));
  };

  const onChangeDateFilter = (fieldName) => {
    return (dateFieldName, value) => {
      dispatch({
        type: actionTypes.DATE_RANGE_FILTER_CHANGED,
        field: fieldName,
        dateField: dateFieldName,
        value
      });
      dispatch({ type: actionTypes.TABLE_RELOADED });
    };
  };

  const onChangeCommonFilter = (fieldName) => {
    return (e) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, field: fieldName, value: e.target.value });
      dispatch({ type: actionTypes.TABLE_RELOADED });
    };
  };

  const handleMultiSelectFilterChange = (fieldName) => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, field: fieldName, value });
      dispatch({ type: actionTypes.TABLE_RELOADED });
    };
  };

  const onResetFilters = () => {
    dispatch({ type: actionTypes.FILTERS_RESET });
    dispatch({ type: actionTypes.TABLE_RELOADED });
  };

  // For more complicated scenarios, when you have issues with cached Kendo Grid,
  // to clear all Kendo Grid caches, use the next snippet:
  //
  // Object
  //   .keys(localStorage)
  //   .filter(key => key.startsWith('kendo/'))
  //   .forEach(key => {
  //     console.log("Removing item: ", key);
  //     localStorage.removeItem(key)
  //   });
  //
  const onResetCache = () => {
    if (!confirm(I18n.t('generic.are_you_sure'))) return;

    localStorage.removeItem(kendoCacheKey);
    window.location.reload();
  };

  // ===================== HTML Part ================================
  return (
    <div ref={containerRef}>
      {tableTitle && (
        <div className="workspace__header position-relative">
          <div className="header__main">
            <h1 className="workspace__title">{tableTitle}</h1>
          </div>
        </div>
      )}

      {!hasDisableAllFilters && (
        <article id="filters" className="qmb-filters">
          <span className="filters__label">{translate('filters.title', { namespace })}</span>

          <fieldset className="filters__group">
            {hasShowInspectionDateFilter && (
              <DateRangePicker
                name="inspection_date"
                onAnyChange={onChangeDateFilter('inspectionDate')}
                values={{
                  rangeType: state.filters.inspectionDate.rangeType,
                  startDate: state.filters.inspectionDate.startDate,
                  endDate: state.filters.inspectionDate.endDate
                }}
                label={translate('filters.inspection_date', { namespace })}
                reactOptions={{ id: 'inspection-date' }}
              />
            )}

            {!!buildingsForSelect.length && (
              <div className="filters__option --active">
                <GenericMultiSelect
                  name="building"
                  value={state.filters.building}
                  dataUrl="/lookup/buildings?type=building"
                  valueMapUrl="/lookup/map?type=building"
                  elements={buildingsForSelect}
                  label={translate('filters.building', { namespace })}
                  placeholder={`Search ${translate('plural_building', { namespace: 'filters' })}`}
                  selectOptions={{
                    id: 'building',
                    onChange: handleMultiSelectFilterChange('building')
                  }}
                  template="
                  <div>#: label #</div>
                  <sub>#: address #</sub>
                  "
                />
              </div>
            )}

            {!!buildingStatusesForSelect.length && (
              <div className="filters__option--native --active">
                <CommonSelect
                  name="building_status"
                  value={state.filters.buildingStatus}
                  elements={buildingStatusesForSelect}
                  label={translate('filters.building_status', { namespace })}
                  placeholder={I18n.t('generic.all')}
                  selectOptions={{
                    id: 'building-status',
                    onChange: onChangeCommonFilter('buildingStatus')
                  }}
                />
              </div>
            )}

            {!!territoriesForSelect.length && (
              <div className="filters__option --active">
                <GenericMultiSelect
                  name="territory"
                  value={state.filters.territory}
                  dataUrl="/lookup?type=territory"
                  valueMapUrl="/lookup/map?type=territory"
                  elements={territoriesForSelect}
                  label={translate('filters.territory', { namespace })}
                  placeholder={`Search ${translate('plural_territory', { namespace: 'filters' })}`}
                  selectOptions={{
                    id: 'territory',
                    onChange: handleMultiSelectFilterChange('territory')
                  }}
                />
              </div>
            )}

            {!!inspectionTypesForSelect.length && (
              <div className="filters__option--native --active">
                <CommonSelect
                  name="inspection_type_id"
                  value={state.filters.inspectionTypeId}
                  elements={inspectionTypesForSelect}
                  label={translate('filters.inspection_type_id', { namespace })}
                  placeholder={I18n.t('generic.all')}
                  selectOptions={{
                    id: 'inspection-type-id',
                    onChange: onChangeCommonFilter('inspectionTypeId')
                  }}
                />
              </div>
            )}

            {hasShowIsPublicReportFilter && (
              <div className="filters__option--native --active">
                <BooleanSelect
                  name="is_public_report"
                  value={state.filters.isPublicReport}
                  label={translate('filters.is_public_report', { namespace })}
                  placeholder={I18n.t('generic.all')}
                  selectOptions={{
                    id: 'is-public-report',
                    onChange: onChangeCommonFilter('isPublicReport')
                  }}
                />
              </div>
            )}
          </fieldset>
          <fieldset className="filters__group--actions">
            <div role="button" tabIndex="-3" className="filters__reset-action" onClick={onResetFilters}>
              {I18n.t('generic.reset')}
            </div>

            <div role="button" tabIndex="-4" className="filters__reset-action" onClick={onResetCache}>
              {I18n.t('generic.reset_cache')}
            </div>
          </fieldset>
        </article>
      )}

      {!hasDisableAllFilters && !hasDisableAllBulkActions && <hr className="workspace__split" />}

      {!hasDisableAllBulkActions && (
        <div className="qmb-content-actions">
          <div className="content-actions__group">
            <div
              role="button"
              tabIndex="0"
              className="qmb-control js-bulk-action"
              onClick={onBulkDownload}
              data-js-download-selected
              disabled>
              <i className="fa-light fa-circle-down" />
              {translate('table.buttons.download', { namespace })}
            </div>

            {!hasShowAllReportsInCustomerPortal && (
              <div role="button" tabIndex="-1" className="qmb-control js-bulk-action" onClick={onBulkPublish} disabled>
                <i className="fa-light fa-share-from-square" />
                {translate('table.buttons.publish_to_customer_portal', { namespace })}
              </div>
            )}

            {canDeleteReports && (
              <div role="button" tabIndex="-2" className="qmb-control js-bulk-action" onClick={onBulkDelete} disabled>
                <i className="fa-light fa-trash-can" />
                {translate('table.buttons.delete', { namespace })}
              </div>
            )}
          </div>
        </div>
      )}

      <CustomScrollbar>
        <article className="--scroll-x --disable-back-gesture">
          <div ref={tableRef} id="inspection-reports-grid" data-src-url={reportsUrl} className="qmb-table" />
        </article>
      </CustomScrollbar>
    </div>
  );
}

IndexTable.propTypes = {
  reportsUrl: PropTypes.string.isRequired,
  deleteReportsUrl: PropTypes.string.isRequired,
  markReportsPublicUrl: PropTypes.string.isRequired,
  defaultHiddenColumns: PropTypes.arrayOf(PropTypes.string),
  nonHidableColumns: PropTypes.arrayOf(PropTypes.string),
  namespace: PropTypes.string,
  tableTitle: PropTypes.string,
  buildingsForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      data: PropTypes.object
    })
  ),
  buildingStatusesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      data: PropTypes.object
    })
  ),
  territoriesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      data: PropTypes.object
    })
  ),
  inspectionTypesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      data: PropTypes.object
    })
  ),
  hasShowAllReportsInCustomerPortal: PropTypes.bool,
  canDeleteReports: PropTypes.bool,
  cacheKey: PropTypes.string,
  hasShowInspectionDateFilter: PropTypes.bool,
  hasShowIsPublicReportFilter: PropTypes.bool,
  hasDisableAllFilters: PropTypes.bool,
  hasDisableAllBulkActions: PropTypes.bool,
  pageSize: PropTypes.number
};

IndexTable.defaultProps = {
  namespace: DEFAULT_NAMESPACE,
  tableTitle: undefined,
  buildingsForSelect: [],
  buildingStatusesForSelect: [],
  territoriesForSelect: [],
  inspectionTypesForSelect: [],
  hasShowAllReportsInCustomerPortal: false,
  canDeleteReports: false,
  cacheKey: DEFAULT_CACHE_KEY,
  defaultHiddenColumns: [],
  nonHidableColumns: DEFAULT_NON_HIDABLE_COLUMNS,
  hasShowInspectionDateFilter: true,
  hasShowIsPublicReportFilter: true,
  hasDisableAllFilters: false,
  hasDisableAllBulkActions: false,
  pageSize: DEFAULT_PAGE_SIZE
};

export default IndexTable;

/* eslint-enable react/no-unescaped-entities */
