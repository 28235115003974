import React from 'react';
import { technicianAvatar } from '@utils/functions/technician.functions';
import { subcontractorIcon } from '@utils/functions/subcontractor.functions';
import { isInspectionVisit, isServiceVisit } from '@utils/typeGuards/visit.typeguard';
export function visitIcon(visit) {
    return React.createElement("i", { style: { color: visit.status.color }, className: visit.status.icon });
}
export function visitStatusAndTimeLabel(visit) {
    const format = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };
    return `${visit.status.label} - ${visit.start.toLocaleString(undefined, format)}`;
}
export function visitAssigneeIcon(visit) {
    let icon;
    if (visit.assignee.technician !== undefined)
        icon = technicianAvatar(visit.assignee.technician, 16);
    else if (visit.assignee.subcontractor !== undefined)
        icon = subcontractorIcon(visit.assignee.subcontractor, 16);
    else
        icon = React.createElement("i", { className: "fa-light fa-circle-question" });
    return React.createElement("span", { style: { display: 'flex', alignItems: 'center' } }, icon);
}
export function visitAssigneeTitle(visit) {
    if (visit.assignee.technician)
        return visit.assignee.technician.name;
    if (visit.assignee.subcontractor)
        return visit.assignee.subcontractor.name;
    return 'Unassigned';
}
export function buildVisitPatch(visit) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    if (isServiceVisit(visit)) {
        return {
            assignee_type: visit.assignee.subcontractor !== undefined ? "subcontractor" /* AssigneeType.SUBCONTRACTOR */ : "technician" /* AssigneeType.TECHNICIAN */,
            technician_id: (_b = (_a = visit.assignee.technician) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
            subcontractor_id: (_d = (_c = visit.assignee.subcontractor) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null,
            duration: visit.durationMins,
            visit_status: visit.status.key,
            scheduled_date: visit.start,
            subcontractor_billing_limit: visit.billingLimit,
            subcontractor_po_number: visit.poNumber
        };
    }
    if (isInspectionVisit(visit)) {
        return {
            assignee_type: visit.assignee.subcontractor !== undefined ? "subcontractor" /* AssigneeType.SUBCONTRACTOR */ : "technician" /* AssigneeType.TECHNICIAN */,
            technician_id: (_f = (_e = visit.assignee.technician) === null || _e === void 0 ? void 0 : _e.id) !== null && _f !== void 0 ? _f : null,
            subcontractor_id: (_h = (_g = visit.assignee.subcontractor) === null || _g === void 0 ? void 0 : _g.id) !== null && _h !== void 0 ? _h : null,
            status_code: visit.status.key,
            duration_mins: visit.durationMins,
            scheduled_date: visit.start
        };
    }
    return {
        assignee_type: visit.assignee.subcontractor !== undefined ? "subcontractor" /* AssigneeType.SUBCONTRACTOR */ : "technician" /* AssigneeType.TECHNICIAN */,
        technician_id: (_k = (_j = visit.assignee.technician) === null || _j === void 0 ? void 0 : _j.id) !== null && _k !== void 0 ? _k : null,
        subcontractor_id: (_m = (_l = visit.assignee.subcontractor) === null || _l === void 0 ? void 0 : _l.id) !== null && _m !== void 0 ? _m : null,
        status_code: visit.status.key,
        projected_duration_mins: visit.durationMins,
        scheduled_date: visit.start
    };
}
