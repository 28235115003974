import React, { useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';

import I18n, { translate } from '@utils/i18n';
import getDatatableLabels from '@utils/datatables';

import ChosenSelect from '@components/ChosenSelect';
import DatePicker from '@components/DatePicker';

import { actionTypes, reducer } from './IndexTableReducer';

// Add JQuery
const { $, confirm, ConfirmationModal } = window;

// TODO: re-visit and see how we could minify the relationship between sprockets and webpacker part.
// Ideally, we should not mix them. But to be eliminate the waiting time for the duration
// of whole project being re-written and to be able to use React now - it's worth it.
function IndexTable({
  namespace,
  reportsUrl,
  deleteReportsUrl,
  markReportsPublicUrl,
  buildingsForSelect,
  buildingStatusesForSelect,
  hasShowAllReportsInCustomerPortal,
  canDeleteReports,
  showFilters
}) {
  const containerRef = useRef();
  const tableRef = useRef();
  const buildingsSelectRef = useRef();
  const buildingStatusesSelectRef = useRef();
  const selectAllRef = useRef();
  const [state, dispatch] = useReducer(reducer, {
    reports: []
  });

  useEffect(() => {
    setupTable();
    setupTableControls();
  }, []);

  const setupTable = () => {
    $(tableRef.current).dataTable({
      iDisplayLength: 25,
      bLengthChange: false,
      bProcessing: true,
      bServerSide: true,
      bFilter: true,
      language: getDatatableLabels(),
      sAjaxSource: $(tableRef.current).data('source'),
      aoColumns: dataTableColumns(),
      fnDrawCallback: () => {
        new ConfirmationModal().init();
      }
    });

    $('#reports-table_filter', tableRef.current).hide();
  };

  const dataTableColumns = () => {
    return [
      { bSortable: false },
      { bSortable: false },
      { bSortable: false },
      { bSortable: false },
      { bSortable: false },
      { bSortable: false },
      { bSortable: false },
      !hasShowAllReportsInCustomerPortal && { bSortable: false },
      { bSortable: false },
      { bSortable: false }
    ].filter((item) => item);
  };

  const setupTableControls = () => {
    const $controls = $('.js-bulk-action', containerRef.current);
    $controls.attr('disabled', true);

    $(tableRef.current).on('click', '.js-bulk-single-checkbox', onCheckboxToggle);
    $(tableRef.current).on('click', '#email-reports', onSendEmail);
  };

  const onCheckboxToggle = (e) => {
    const $controls = $('.js-bulk-action', containerRef.current);

    const checkbox = e.currentTarget;
    const reportId = checkbox.getAttribute('data-report-id');

    const $anyCheckedCheckbox = $('.js-bulk-single-checkbox:checked', tableRef.current);
    $controls.attr('disabled', $anyCheckedCheckbox.length === 0);

    if (checkbox.checked) {
      dispatch({ type: actionTypes.REPORT_SELECTED, id: reportId });
    } else {
      $(selectAllRef.current).prop('checked', false);
      dispatch({ type: actionTypes.REPORT_DESELECTED, id: reportId });
    }
  };

  const onSendEmail = (e) => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!

    const yyyy = today.getFullYear();
    today = `${mm}/${dd}/${yyyy}`;

    /* eslint-disable no-alert */
    api
      .patch(e.currentTarget.dataset.url, { report: { sent_on: today } }, { headers: { Accept: 'application/json' } })
      .then(() => $(tableRef.current).dataTable().fnDraw())
      .catch(({ message }) => alert(`${I18n.t('generic.something_went_wrong')} - ${message}`));
    /* eslint-enable no-alert */
  };

  const onBulkDownload = (e) => {
    e.preventDefault();

    // eslint-disable-next-line no-alert
    if (!state.reports.length) return alert(translate('table.select_report', { namespace }));

    $('.report-row:checked', tableRef.current).each((_event, item) => {
      const url = item.getAttribute('data-download-link');

      if (url) window.open(url, '_blank');
    });
  };

  const onBulkPublish = (e) => {
    e.preventDefault();

    gatherReportsAndRedirect(markReportsPublicUrl, translate('table.confirm_bulk_publish', { namespace }));
  };

  const gatherReportsAndRedirect = (url, message) => {
    // eslint-disable-next-line no-alert
    if (!state.reports.length) return alert(translate('table.select_report', { namespace }));

    let queryParams = url.includes('building_id=') ? '&' : '?';

    state.reports.forEach((reportId) => {
      queryParams = `${queryParams}ids[]=${reportId}&`;
    });
    queryParams = queryParams.slice(0, -1);

    const response = !message || confirm(message);
    if (response) {
      window.location.href = `${url}${queryParams}`;
    }
  };

  const onBulkDelete = (e) => {
    e.preventDefault();

    gatherReportsAndRedirect(deleteReportsUrl, translate('table.confirm_bulk_delete', { namespace }));
  };

  const onSelectAllToggle = (e) => {
    const $checkbox = $(e.currentTarget);

    if (e.currentTarget.checked) {
      $('.report-row', tableRef.current).prop('checked', true);
      dispatch({ type: actionTypes.REPORTS_DESELECTED });

      $('.report-row', tableRef.current).each((_index, row) => {
        dispatch({ type: actionTypes.REPORT_SELECTED, id: row.getAttribute('data-report-id') });
      });
    } else {
      $('.report-row', tableRef.current).prop('checked', false);
      dispatch({ type: actionTypes.REPORTS_DESELECTED });
    }

    const $controls = $('.js-bulk-action', containerRef.current);
    $controls.attr('disabled', !$checkbox.is(':checked'));
  };

  const onChangeFilter = (index) => {
    return (e) => {
      const selectedValue = $(e.currentTarget).val();

      $(tableRef.current).dataTable().fnFilter(selectedValue, index, true);
    };
  };

  const mapOptionsForSelect = (optionsForSelect) => {
    return optionsForSelect.map((option) => {
      return { label: option[0], value: option[1] };
    });
  };

  return (
    <div ref={containerRef} className="row-fluid">
      <div className="span12">
        {showFilters && (
          <div className="row-fluid">
            <div className="table-header">{translate('filters.title', { namespace })}</div>

            <div className="row-fluid">
              <div className="span4 mx-1 my-1">
                <div className="control-group">
                  <div className="controls">
                    <DatePicker
                      name="report_start"
                      label={`${translate('filters.date_start', { namespace })}:`}
                      reactOptions={{
                        id: 'report_start',
                        className: 'date-picker',
                        onChange: onChangeFilter(1)
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="span4 mx-1 my-1">
                <div className="control-group">
                  <div className="controls">
                    <DatePicker
                      name="report_end"
                      label={`${translate('filters.date_end', { namespace })}:`}
                      reactOptions={{
                        id: 'report_end',
                        className: 'date-picker',
                        onChange: onChangeFilter(2)
                      }}
                    />
                  </div>
                </div>
              </div>

              {!!buildingsForSelect.length && (
                <div className="span4 mx-1 my-1">
                  <div className="control-group">
                    <div className="controls">
                      <ChosenSelect
                        name="building"
                        elements={mapOptionsForSelect(buildingsForSelect)}
                        label={`${translate('filters.building.label', { namespace })}:`}
                        reactOptions={{
                          ref: buildingsSelectRef,
                          id: 'building',
                          className: 'chosen-select',
                          multiple: true,
                          onChange: onChangeFilter(3)
                        }}
                        chosenOptions={{
                          placeholder: translate('filters.building.placeholder', { namespace })
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row-fluid">
              {!!buildingStatusesForSelect.length && (
                <div className="span4 mx-1 my-1">
                  <div className="control-group">
                    <div className="controls">
                      <ChosenSelect
                        name="building_status"
                        elements={mapOptionsForSelect(buildingStatusesForSelect)}
                        label={`${translate('filters.building_status.label', { namespace })}:`}
                        reactOptions={{
                          ref: buildingStatusesSelectRef,
                          id: 'building-status',
                          className: 'chosen-select',
                          onChange: onChangeFilter(5)
                        }}
                        chosenOptions={{
                          placeholder: translate('filters.building_status.placeholder', { namespace })
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {!hasShowAllReportsInCustomerPortal && (
                <div className="span4 mx-1 my-1">
                  <div className="control-group">
                    <div className="controls">
                      <label htmlFor="public">{translate('filters.is_public', { namespace })}:</label>
                      <select name="public" id="public" onChange={onChangeFilter(4)}>
                        <option value="" />
                        <option value="true">{I18n.t('generic.yes')}</option>
                        <option value="false">{I18n.t('generic.no')}</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="table-header">{translate('table.title', { namespace })}</div>

        <div className="func-buttons">
          <div
            role="button"
            tabIndex="0"
            className="mr-1 btn btn-info btn-small js-bulk-action"
            onClick={onBulkDownload}
            data-js-download-selected>
            <i className="mr-1 icon-download" />
            {translate('table.buttons.download', { namespace })}
          </div>

          {!hasShowAllReportsInCustomerPortal && (
            <div
              role="button"
              tabIndex="-1"
              className="mr-1 btn btn-info btn-small js-bulk-action"
              onClick={onBulkPublish}>
              <i className="mr-1 icon-file" />
              {translate('table.buttons.publish_to_customer_portal', { namespace })}
            </div>
          )}

          {canDeleteReports && (
            <div
              role="button"
              tabIndex="-2"
              className="mr-1 btn btn-danger btn-small js-bulk-action"
              onClick={onBulkDelete}>
              <i className="mr-1 icon-trash" />
              {translate('table.buttons.delete', { namespace })}
            </div>
          )}
        </div>

        <table
          ref={tableRef}
          id={`reports-table${hasShowAllReportsInCustomerPortal ? '-no-public' : ''}`}
          className="table table-striped table-bordered table-hover js-bulk-table"
          data-source={reportsUrl}>
          <thead>
            <tr>
              <th>
                <input
                  ref={selectAllRef}
                  id="select-all-report"
                  className="js-bulk-select-all"
                  type="checkbox"
                  onChange={onSelectAllToggle}
                />
              </th>
              <th>{translate('table.headers.inspection_date', { namespace })}</th>
              <th>{I18n.t('generic.date')}</th>
              <th>{I18n.t('generic.building')}</th>
              <th>{translate('table.headers.system_asset', { namespace })}</th>
              <th>{I18n.t('generic.report_type')}</th>
              <th>{translate('table.headers.display_name', { namespace })}</th>

              {!hasShowAllReportsInCustomerPortal && <th>{translate('table.headers.is_public', { namespace })}</th>}

              <th>{translate('table.headers.sent_on', { namespace })}</th>
              <th>{I18n.t('generic.actions')}</th>
            </tr>
          </thead>
          <tbody />
        </table>
      </div>
    </div>
  );
}

IndexTable.propTypes = {
  namespace: PropTypes.string.isRequired,
  reportsUrl: PropTypes.string.isRequired,
  deleteReportsUrl: PropTypes.string.isRequired,
  markReportsPublicUrl: PropTypes.string.isRequired,
  buildingsForSelect: PropTypes.arrayOf(PropTypes.array),
  buildingStatusesForSelect: PropTypes.arrayOf(PropTypes.array),
  hasShowAllReportsInCustomerPortal: PropTypes.bool,
  canDeleteReports: PropTypes.bool,
  showFilters: PropTypes.bool
};

IndexTable.defaultProps = {
  buildingsForSelect: [],
  buildingStatusesForSelect: [],
  hasShowAllReportsInCustomerPortal: false,
  canDeleteReports: false,
  showFilters: false
};

export default IndexTable;
