import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import api from '@utils/axios';
import { buildingBuildingNotePath } from '../../../routes';
import { actionTypes } from '../reducer';

const namespace = 'features.activity_feed.visibility_toggle';

function VisibilityToggle({ note, dispatch, onVisibilityChange }) {
  const anchor = useRef(null);
  const popup = useRef(null);
  const [open, setOpen] = useState(false);

  const handleBodyClick = useCallback((event) => {
    if (popup.current?._popup?.contains(event.target)) return;
    if (anchor.current.contains(event.target)) return;

    setOpen(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const changeVisibility = (newVisibility) => {
    return () => {
      const body = new FormData();
      body.append('building_note[visibility]', newVisibility);

      if (onVisibilityChange) return onVisibilityChange(newVisibility);

      api
        .put(
          buildingBuildingNotePath({ id: note.id, building_id: note.buildingId, format: 'json', _options: true }),
          body
        )
        .then(({ data }) => dispatch({ type: actionTypes.NOTE_UPDATED, note: data }));
    };
  };

  const onButtonCLick = () => {
    if (note.canEditVisibilityDestroy) setOpen(!open);
  };

  const buttonClass = useMemo(() => (onVisibilityChange ? 'qmb-control--sm' : 'qmb-control--icon--sm--dim'), []);

  return (
    <>
      <Popup anchor={anchor.current} show={open} ref={popup}>
        <div className="qmb-popup--actions">
          <div className="popup__section--group--y-fill">
            <h4 className="popup__title--group">{translate('title', { namespace })}</h4>
            <ul role="presentation">
              <li>
                <label className="qmb-radio">
                  <input type="radio" checked={note.visibility === 'tech'} onChange={changeVisibility('tech')} />
                  <i className="fa-light fa-light fa-user-helmet-safety" />
                  {translate('tech', { namespace })}
                </label>
              </li>
              <li>
                <label className="qmb-radio">
                  <input type="radio" checked={note.visibility === 'user'} onChange={changeVisibility('user')} />
                  <i className="fa-light fa-phone-office" />
                  {translate('user', { namespace })}
                </label>
              </li>
              {/* <li>
                <label className="qmb-radio">
                  <input type="radio" checked={note.visibility === 'customer'} onChange={changeVisibility('customer')} />
                  <i className="fa-light fa-users" />
                  {translate('customer', { namespace })}
                </label>
              </li> */}
            </ul>
          </div>
        </div>
      </Popup>
      <button type="button" className={buttonClass} ref={anchor} onClick={onButtonCLick}>
        {note.visibility === 'tech' && (
          <>
            <i className="fa-light fa-user-helmet-safety" />
            {onVisibilityChange && translate('tech', { namespace })}
          </>
        )}
        {note.visibility === 'user' && (
          <>
            <i className="fa-light fa-phone-office" />
            {onVisibilityChange && translate('user', { namespace })}
          </>
        )}
        {note.visibility === 'customer' && <i className="fa-light fa-users" />}
      </button>
    </>
  );
}

VisibilityToggle.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.number,
    visibility: PropTypes.string,
    buildingId: PropTypes.number,
    user: PropTypes.shape({ canEditVisibilityDestroy: PropTypes.bool })
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  onVisibilityChange: PropTypes.func
};

VisibilityToggle.defaultProps = {
  onVisibilityChange: undefined
};

export default VisibilityToggle;
