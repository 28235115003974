import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

function InspectionHistory({ inspections, passFailHeader }) {
  const [order, setOrder] = useState('desc');
  const sortedInspections = inspections.sort((a, b) =>
    order === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date)
  );
  const sortTable = () => {
    if (order === 'desc') {
      setOrder('asc');
    } else {
      setOrder('desc');
    }
  };

  const formattedAnswer = (inspection) => {
    if (inspection.notTested) {
      return I18n.t('features.inspection_history.cannot_test');
    }
    if (inspection.answer === 'Yes' || inspection.answer === 'Pass') {
      return I18n.t('generic.pass');
    }
    if (inspection.answer === 'No' || inspection.answer === 'Fail') {
      return I18n.t('generic.fail');
    }
  };

  return (
    <>
      <h2>{I18n.t('features.inspection_history.title')}</h2>
      <table className="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <td>{I18n.t('features.inspection_history.table.id')}</td>
            <td onClick={sortTable}>
              {I18n.t('features.inspection_history.table.date')}
              <i className="icon-sort pull-right mt-1" />
            </td>
            <td>{passFailHeader}</td>
            <td>{I18n.t('features.inspection_history.table.notes')}</td>
            <td>{I18n.t('features.inspection_history.table.photos')}</td>
          </tr>
        </thead>
        <tbody>
          {sortedInspections.map((inspection) => (
            <tr key={inspection.id}>
              <td>
                <a href={inspection.inspectionLink}>{inspection.id}</a>
              </td>
              <td>{inspection.date && new Date(inspection.date).toLocaleDateString()}</td>
              <td>{formattedAnswer(inspection)}</td>
              <td>{inspection.notes}</td>
              <td>
                {inspection.photosLink && (
                  <a href={inspection.photosLink} data-remote="true">
                    <i className="icon-camera bigger-130" />
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

InspectionHistory.propTypes = {
  inspections: PropTypes.arrayOf(PropTypes.object),
  passFailHeader: PropTypes.string
};

InspectionHistory.defaultProps = {
  inspections: [],
  passFailHeader: I18n.t('features.inspection_history.pass_fail')
};

export default InspectionHistory;
