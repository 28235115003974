import produce from 'immer';

export const actionTypes = {
  DATA_LOADED: 'DATA_LOADED',
  NOTE_ADDED: 'NOTE_ADDED',
  NOTE_UPDATED: 'NOTE_UPDATED',
  NOTE_REMOVED: 'NOTE_REMOVED',
  LOAD_MORE: 'LOAD_MORE'
};

export const sevenDaysAgo = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.NOTE_ADDED:
      draft.week.unshift({ new: true, ...action.note });
      break;
    case actionTypes.NOTE_UPDATED:
      [draft.week, draft.older].forEach((range) => {
        const index = range.findIndex((note) => note.id === action.note.id);
        if (index !== -1) range.splice(index, 1, action.note);
      });
      break;
    case actionTypes.NOTE_REMOVED:
      [draft.week, draft.older].forEach((range) => {
        const indexToRemove = range.findIndex((note) => note.id === action.noteId);
        if (indexToRemove !== -1) range.splice(indexToRemove, 1);
      });
      break;
    case actionTypes.DATA_LOADED:
      draft.all = action.data;
      draft.all.splice(0, 10).forEach((note) => {
        if (sevenDaysAgo < note.datetime) {
          draft.week.push(note);
        } else {
          draft.older.push(note);
        }
      });
      break;
    case actionTypes.LOAD_MORE:
      draft.all.splice(0, 5).forEach((note) => {
        if (sevenDaysAgo < note.datetime) {
          draft.week.push(note);
        } else {
          draft.older.push(note);
        }
      });
      break;
    default:
      throw new Error('Reducer - Wrong action type.');
  }
});
