import { useEffect, useState } from 'react';
import api from 'axios';
import { apiInternalTechniciansPath } from '../../routes';
const useGetTechnicians = (queryParams) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);
        api
            .get(apiInternalTechniciansPath(), {
            params: queryParams,
            signal: abortController.signal
        })
            .then((response) => {
            setData(response.data.data);
        })
            .catch((err) => {
            if (!abortController.signal.aborted) {
                setData([]);
                setError(err);
            }
        })
            .finally(() => {
            if (!abortController.signal.aborted) {
                setLoading(false);
            }
        });
        return () => {
            abortController.abort();
        };
    }, [queryParams]);
    return { data, loading, error };
};
export default useGetTechnicians;
