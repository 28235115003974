import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

const { $ } = window;

function MultiSelectWithAvatar({ name, value, label, dataUrl, valueMapUrl, selectOptions, placeholder }) {
  const multiRef = useRef();

  useEffect(() => {
    $(multiRef.current).kendoMultiSelect({
      autoWidth: true,
      placeholder,
      dataTextField: 'label',
      dataValueField: 'value',
      change: selectOptions.onChange,
      multiple: true,
      value,
      height: 420,
      itemTemplate,
      tagTemplate,
      virtual: {
        itemHeight: 90,
        valueMapper(options) {
          $.ajax({
            url: valueMapUrl,
            type: 'GET',
            data: { ids: options.value }, // Send value to the server.
            success(data) {
              options.success(data); // Return the index number of the corresponding data item.
            }
          });
        }
      },
      dataSource: {
        transport: {
          read: {
            url: dataUrl,
            dataType: 'json'
          }
        },
        schema: {
          total: 'total_count',
          data: 'data'
        },
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        pageSize: 20
      }
    });
  }, []);

  useEffect(() => {
    const multiselect = $(multiRef.current).data('kendoMultiSelect');
    multiselect.value(value);
  }, [value]);

  const itemTemplate = ReactDOMServer.renderToString(
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {'# if (value === 0) { #'}
      <span
        className="qmb-avatar--32--unassigned-icon"
        style={{ display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}>
        <i className="fa-light fa-user-helmet-safety" />
      </span>
      {'# } else if (photoUrl) { #'}
      <span className="qmb-avatar--32">
        <img
          alt="#: label[0] #"
          style={{ borderRadius: '50%', border: '2px solid #: color #', boxSizing: 'border-box' }}
          src="#: photoUrl #"
        />
      </span>
      {'# } else { #'}
      <span className="qmb-avatar--32">
        <svg viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8" style={{ fill: '#: color#' }} />
          <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
            #: label[0] #
          </text>
        </svg>
      </span>
      {'# } #'}
      <div style={{ marginLeft: '0.8rem' }}>#: label #</div>
    </div>
  );

  const tagTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (value === 0) {#'}
      <span className="qmb-avatar--24--unassigned--alt">
        <i className="fa-light fa-user-helmet-safety" />
      </span>
      {'# } else if (photoUrl) { #'}
      <span className="qmb-avatar--24">
        <img
          alt="#: label[0] #"
          style={{ borderRadius: '50%', border: '2px solid #: color #', boxSizing: 'border-box' }}
          src="#: photoUrl #"
        />
      </span>
      {'# } else { #'}
      <span className="qmb-avatar--24">
        <svg viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8" style={{ fill: '#: color#' }} />
          <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
            #: label[0] #
          </text>
        </svg>
      </span>
      {'# } #'}
    </>
  );

  return (
    <>
      <select id={name} name={name} onChange={() => {}} className="--avatars" ref={multiRef} multiple />
      <label className="qmb-label" htmlFor={name}>
        {label}
      </label>
    </>
  );
}

export default MultiSelectWithAvatar;

MultiSelectWithAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  selectOptions: PropTypes.object,
  jQueryEvents: PropTypes.shape({
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func
  }),
  dataUrl: PropTypes.string,
  valueMapUrl: PropTypes.string
};

MultiSelectWithAvatar.defaultProps = {
  value: undefined,
  label: undefined,
  placeholder: undefined,
  selectOptions: {},
  jQueryEvents: {},
  dataUrl: '',
  valueMapUrl: ''
};
