import { BaseStatusesSelectionSort } from '../context/constants';

export default function getSortedStatuses(statuses, entity) {
  const selectionSorting = BaseStatusesSelectionSort[entity];
  return statuses.sort((a, b) => {
    if (selectionSorting.includes(a.statusCode) && !selectionSorting.includes(b.statusCode)) {
      return -1;
    }
    if (!selectionSorting.includes(a.statusCode) && selectionSorting.includes(b.statusCode)) {
      return 1;
    }
    if (selectionSorting.includes(a.statusCode) && selectionSorting.includes(b.statusCode)) {
      return selectionSorting.indexOf(a.statusCode) - selectionSorting.indexOf(b.statusCode);
    }
    return 0;
  });
}
