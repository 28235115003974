import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';
import useDataTableContext from '../context/useDataTableContext';

function Proposals({ timeFrame, declined, accepted, created, sent, expandLink, listingLink }) {
  const {
    actions: { loadProposals }
  } = useDataTableContext();
  const onLoadProposals = (status) => {
    loadProposals({ status });
  };
  return (
    <div className="qmb-widget--proposals--sm">
      <div className="widget__header">
        <svg className="widget__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
          <polygon points="64,0 0,64 0,0 " />
        </svg>
        <h3 className="widget__title">
          <a
            href={listingLink}
            className="title__link"
            title={I18n.t('features.dashboards.home.service_visits.view_all')}>
            {I18n.t('features.dashboards.home.proposals.title')}
          </a>
          <small>{timeFrame}</small>
        </h3>
        <div className="widget__controls">
          <a
            href={expandLink}
            className="qmb-control--icon"
            title={I18n.t('features.dashboards.home.proposals.expand_title')}>
            <i className="fa-light fa-chart-mixed" />
          </a>
        </div>
      </div>

      <div className="widget__body">
        <button
          type="button"
          className="widget__counter"
          onClick={() => onLoadProposals('created')}
          title="Created"
          style={{ width: '100%', alignItems: 'flex-start' }}>
          <span className="counter__label">
            {I18n.t('features.dashboards.home.proposals.created')}
            <span className="counter__context--parens">
              {created.count} {I18n.t('features.dashboards.home.proposals.title')}
            </span>
          </span>
          <span className="counter__value">{created.amount}</span>
        </button>
        <button
          type="button"
          className="widget__counter"
          title="Sent"
          style={{ alignItems: 'flex-start' }}
          onClick={() => onLoadProposals('sent')}>
          <span className="counter__label">
            {I18n.t('features.dashboards.home.proposals.sent')}
            <span className="counter__context--parens">{sent.count}</span>
          </span>
          <span className="counter__value">{sent.amount}</span>
        </button>
        <button
          type="button"
          className="widget__counter qmb-control"
          title="Accepted"
          style={{ alignItems: 'flex-start' }}
          onClick={() => onLoadProposals('accepted')}>
          <span className="counter__label">
            {I18n.t('features.dashboards.home.proposals.accepted')}
            <span className="counter__context--parens">{accepted.count}</span>
          </span>
          <span className="counter__value">{accepted.amount}</span>
        </button>
        <button
          type="button"
          className="widget__counter qmb-control"
          style={{ alignItems: 'flex-start' }}
          title="Declined"
          onClick={() => onLoadProposals('declined')}>
          <span className="counter__label">
            {I18n.t('features.dashboards.home.proposals.declined')}
            <span className="counter__context--parens">{declined?.count}</span>
          </span>
          <span className="counter__value">{declined?.amount || 0}</span>
        </button>
      </div>
    </div>
  );
}

Proposals.defaultProps = {
  expandLink: '',
  listingLink: '',
  timeFrame: I18n.t('features.dashboards.home.timeFrame'),
  created: {
    count: 0,
    amount: '$0'
  },
  sent: {
    count: 0,
    amount: '$0'
  },
  accepted: {
    count: 0,
    amount: '$0'
  },
  declined: {
    count: 0,
    amount: '$0'
  }
};

Proposals.propTypes = {
  timeFrame: PropTypes.string,
  expandLink: PropTypes.string,
  listingLink: PropTypes.string,
  created: PropTypes.shape({
    count: PropTypes.number,
    amount: PropTypes.string
  }),
  declined: PropTypes.shape({
    count: PropTypes.number,
    amount: PropTypes.string
  }),
  sent: PropTypes.shape({
    count: PropTypes.number,
    amount: PropTypes.string
  }),
  accepted: PropTypes.shape({
    count: PropTypes.number,
    amount: PropTypes.string
  })
};

export default Proposals;
