import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';
import useDataTableContext from '../context/useDataTableContext';

function Payments({ timeFrame, totalCount, totalAmount, averageAmount, expandLink }) {
  const {
    actions: { loadPayments }
  } = useDataTableContext();

  const onLoadPayments = (status) => {
    loadPayments({ status });
  };
  return (
    <div className="qmb-widget--payments--sm">
      <div className="widget__header">
        <svg className="widget__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
          <polygon points="64,0 0,64 0,0 " />
        </svg>
        <h3 className="widget__title">
          <a href={expandLink} className="title__link">
            {I18n.t('features.dashboards.home.payments.title')}
          </a>
          <small>{timeFrame}</small>
        </h3>
        <div className="widget__controls">
          <a href={expandLink} className="qmb-control" title={I18n.t('features.dashboards.home.payments.expand_title')}>
            {I18n.t('features.dashboards.home.payments.view_history')}
          </a>
        </div>
      </div>

      <div className="widget__body">
        <button
          type="button"
          className="widget__counter--x-full qmb-control"
          onClick={() => onLoadPayments()}
          style={{ width: '100%', alignItems: 'flex-start' }}>
          <span className="counter__label">{I18n.t('features.dashboards.home.payments.total')}</span>
          <span className="counter__value">
            {totalAmount}
            <span className="counter__context--split">
              {totalCount} {I18n.t('features.dashboards.home.payments.payments')}
            </span>
          </span>
        </button>
        <button
          type="button"
          className="widget__counter--x-full qmb-control"
          onClick={() => onLoadPayments()}
          style={{ width: '100%', alignItems: 'flex-start' }}>
          <span className="counter__label">{I18n.t('features.dashboards.home.payments.average_payment')}</span>
          <span className="counter__value">{averageAmount}</span>
        </button>
      </div>
    </div>
  );
}

Payments.defaultProps = {
  timeFrame: I18n.t('features.dashboards.home.timeFrame'),
  totalCount: 0,
  totalAmount: '$0',
  averageAmount: '$0',
  expandLink: ''
};

Payments.propTypes = {
  expandLink: PropTypes.string,
  timeFrame: PropTypes.string,
  totalCount: PropTypes.number,
  totalAmount: PropTypes.string,
  averageAmount: PropTypes.string
};

export default Payments;
