import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';
import { Dialog } from '@progress/kendo-react-dialogs';
import I18n, { translate } from '@utils/i18n';
import { actionTypes } from '../reducer';
import VisibilityToggle from './VisibilityToggle';
import { workOrderPath, inspectionPath, buildingBuildingNotePath } from '../../../routes';

const namespace = 'features.activity_feed.note';

function TechIcon({ technician }) {
  if (!technician.photoUrl) {
    return (
      <span className="qmb-avatar--24">
        <svg viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8" style={{ fill: technician.color }} />
          <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
            {technician.name[0]}
          </text>
        </svg>
      </span>
    );
  }
  return (
    <span className="qmb-avatar--24">
      <img alt="tech avatar" style={{ borderRadius: '50%' }} src={technician.photoUrl} />
    </span>
  );
}

function UserIcon({ user }) {
  return (
    <span className="qmb-avatar--24">
      <svg viewBox="0 0 16 16">
        <circle cx="8" cy="8" r="8" style={{ fill: '#bbb' }} />
        <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
          {user.name[0]}
        </text>
      </svg>
    </span>
  );
}

function Note({ note, dispatch, isBuilding }) {
  const [edit, setEdit] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [notes, setNotes] = useState(note.notes);
  const noteRef = useRef(null);

  const onUpdate = (e) => {
    e.preventDefault();
    const body = new FormData();
    body.append('building_note[notes]', notes);

    api
      .put(
        buildingBuildingNotePath({ id: note.id, building_id: note.buildingId, format: 'json', _options: true }),
        body
      )
      .then(({ data }) => dispatch({ type: actionTypes.NOTE_UPDATED, note: data }))
      .then(() => {
        setEdit(false);
      });
  };

  const onRemove = (e) => {
    e.preventDefault();

    api
      .delete(buildingBuildingNotePath({ id: note.id, building_id: note.buildingId, format: 'json', _options: true }))
      .then(() => dispatch({ type: actionTypes.NOTE_REMOVED, noteId: note.id }));
  };

  useEffect(() => {
    if (!noteRef.current) return;
    noteRef.current.style.height = `${noteRef.current.scrollHeight}px`;
  }, [edit]);

  return (
    <li className={`activity__item--note ${note.new ? '--new' : ''}`}>
      <header className="activity__header">
        {note.technician && <TechIcon technician={note.technician} />}
        {note.user && <UserIcon user={note.user} />}
        <span className="activity__author">{note.technician?.name ?? note.user?.name}</span>
      </header>

      <div className="activity__body">
        {note.workOrderId && isBuilding && (
          <div className="activity__ref">
            <span>{translate('related', { namespace })}:</span>
            {note.disabledWo && (
              <a>
                {translate('work_order', { namespace })} #{note.woNumber}
              </a>
            )}
            {!note.disabledWo && (
              <a href={workOrderPath(note.workOrderId)}>
                {translate('work_order', { namespace })} #{note.woNumber}
              </a>
            )}
          </div>
        )}
        {note.inspectionId && isBuilding && (
          <div className="activity__ref">
            <span>{translate('related', { namespace })}:</span>
            <a href={inspectionPath(note.inspectionId)}>
              {translate('inspection', { namespace })} #{note.inspectionId}
            </a>
          </div>
        )}

        {!edit && <span style={{ whiteSpace: 'pre-line' }}>{note.notes}</span>}

        {edit && (
          <div className="qmb-textarea--note--blank--x-full">
            <textarea ref={noteRef} value={notes} onChange={(e) => setNotes(e.target.value)} name="notes" />
            <div className="textarea__controls">
              <button
                type="button"
                onClick={() => {
                  setNotes(note.notes);
                  setEdit(false);
                }}
                className="qmb-control--cancel">
                {I18n.t('generic.cancel')}
              </button>
              <button disabled={notes.length === 0} type="submit" onClick={onUpdate} className="qmb-control--submit">
                {I18n.t('generic.save')}
              </button>
            </div>
            <svg viewBox="0 0 32 32" className="textarea__accent">
              <path d="M 32 0 L 0 32" className="accent__diag" />
              <path d="M 26 0.5 L 0 0.5" className="accent__x" />
              <path d="M 0.5 26 L 0.5 0" className="accent__y" />
            </svg>
          </div>
        )}
      </div>
      <footer className="activity__footer">
        <VisibilityToggle note={note} dispatch={dispatch} />
        <span>
          {new Date(note.datetime).toLocaleDateString('en-US')}
          {' @ '}
          {new Date(note.datetime).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}
        </span>
        {note.user?.isOwner && (
          <button
            type="button"
            className="qmb-control--icon--sm"
            title={I18n.t('generic.edit')}
            onClick={() => setEdit(true)}>
            <i className="fa-light fa-pencil" />
          </button>
        )}
        {note.canEditVisibilityDestroy && (
          <button
            type="button"
            className="qmb-control--icon--sm"
            title={I18n.t('generic.remove')}
            onClick={() => setRemoveModal(true)}>
            <i className="fa-light fa-trash-can" />
          </button>
        )}
      </footer>
      {removeModal && (
        <Dialog
          title={translate('modal_title', { namespace })}
          className="qmb-dialog"
          onClose={() => {
            setRemoveModal(false);
          }}>
          <p>{translate('confirm_delete', { namespace })}</p>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button
              type="button"
              className="qmb-button"
              onClick={() => {
                setRemoveModal(false);
              }}>
              {I18n.t('generic.cancel')}
            </button>
            <button onClick={onRemove} type="submit" className="qmb-button--submit">
              {I18n.t('generic.ok')}
            </button>
          </div>
        </Dialog>
      )}
    </li>
  );
}

Note.propTypes = {
  note: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.number,
    technician: PropTypes.object,
    user: PropTypes.object,
    workOrderId: PropTypes.number,
    buildingName: PropTypes.string,
    woNumber: PropTypes.number,
    isInspection: PropTypes.bool,
    inspectionId: PropTypes.number,
    notes: PropTypes.string,
    buildingId: PropTypes.number,
    new: PropTypes.bool,
    datetime: PropTypes.number,
    disabledWo: PropTypes.bool,
    canEditVisibilityDestroy: PropTypes.bool
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  isBuilding: PropTypes.bool.isRequired
};

export default Note;
