import React from 'react';
import PropTypes from 'prop-types';

function DescriptionCell({ tdProps, dataItem }) {
  return (
    <td {...tdProps}>
      {dataItem.description?.split('<wbr>').map((desc, index) => (
        <React.Fragment key={index}>
          {desc}
          <wbr />
        </React.Fragment>
      ))}
    </td>
  );
}

DescriptionCell.propTypes = {
  tdProps: PropTypes.object,
  dataItem: PropTypes.shape({
    description: PropTypes.string
  }).isRequired
};

export default DescriptionCell;
