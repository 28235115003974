import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';
import useDataTableContext from '../context/useDataTableContext';

function Invoices({
  timeFrame,
  currency,
  amountPaid,
  amountPaidCount,
  amountDue,
  amountDueCount,
  amountOverDue,
  amountOverDueCount,
  amountTotal,
  amountTotalCount,
  expandLink,
  listingLink
}) {
  const {
    actions: { loadInvoices }
  } = useDataTableContext();
  const formattedAmountPaid = Number(amountPaid).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedAmountDue = Number(amountDue).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedAmountOverDue = Number(amountOverDue).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedAmountTotal = Number(amountTotal).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const onLoadInvoices = (status) => {
    loadInvoices({ status });
  };

  return (
    <div className="qmb-widget--invoices--sm">
      <div className="widget__header">
        <svg className="widget__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
          <polygon points="64,0 0,64 0,0 " />
        </svg>
        <h3 className="widget__title">
          <a
            href={listingLink}
            className="title__link"
            title={I18n.t('features.dashboards.home.service_visits.view_all')}>
            {I18n.t('features.dashboards.home.invoices.title')}
          </a>
          <small>{timeFrame}</small>
        </h3>
        <div className="widget__controls">
          <a
            href={expandLink}
            className="qmb-control--icon"
            title={I18n.t('features.dashboards.home.invoices.expand_title')}>
            <i className="fa-light fa-chart-mixed" />
          </a>
        </div>
      </div>

      <div className="widget__body">
        <button
          type="button"
          className="widget__counter"
          onClick={() => onLoadInvoices('all')}
          title={I18n.t('features.dashboards.home.invoices.view_paid_invoices')}
          style={{ width: '100%', alignItems: 'flex-start' }}>
          <span className="counter__label">
            {I18n.t('features.dashboards.home.invoices.sent')}
            <span className="counter__context--parens">
              {amountTotalCount} {I18n.t('features.dashboards.home.invoices.invoices')}
            </span>
          </span>
          <span className="counter__value">
            {currency}
            {formattedAmountTotal}
          </span>
        </button>
        <button
          type="button"
          className="widget__counter--paid"
          onClick={() => onLoadInvoices('paid')}
          title={I18n.t('features.dashboards.home.invoices.view_paid_invoices')}>
          <span className="counter__label">
            {I18n.t('features.dashboards.home.invoices.paid')}
            <span className="counter__context--parens">{amountPaidCount}</span>
          </span>
          <span className="counter__value">
            {currency}
            {formattedAmountPaid}
          </span>
        </button>
        <button
          type="button"
          className="widget__counter--due"
          onClick={() => onLoadInvoices('due')}
          title={I18n.t('features.dashboards.home.invoices.view_due_invoices')}>
          <span className="counter__label">
            {I18n.t('features.dashboards.home.invoices.due')}
            <span className="counter__context--parens">{amountDueCount}</span>
          </span>
          <span className="counter__value">
            {currency}
            {formattedAmountDue}
          </span>
        </button>
        <button
          type="button"
          className="widget__counter-due"
          onClick={() => onLoadInvoices('over_due')}
          title={I18n.t('features.dashboards.home.invoices.view_over_due_invoices')}>
          <span className="counter__label">
            {I18n.t('features.dashboards.home.invoices.over_due')}
            <span className="counter__context--parens">{amountOverDueCount}</span>
          </span>
          <span className="counter__value">
            {currency}
            {formattedAmountOverDue}
          </span>
        </button>
      </div>
    </div>
  );
}

Invoices.defaultProps = {
  timeFrame: I18n.t('features.dashboards.home.timeFrame'),
  currency: '$',
  amountPaid: 0,
  amountPaidCount: 0,
  amountDue: 0,
  amountDueCount: 0,
  amountTotal: 0,
  amountTotalCount: 0,
  amountOverDue: 0,
  amountOverDueCount: 0,
  expandLink: '',
  listingLink: ''
};

Invoices.propTypes = {
  timeFrame: PropTypes.string,
  currency: PropTypes.string,
  amountPaid: PropTypes.number,
  amountPaidCount: PropTypes.number,
  amountDue: PropTypes.number,
  amountDueCount: PropTypes.number,
  amountTotal: PropTypes.number,
  amountTotalCount: PropTypes.number,
  amountOverDue: PropTypes.number,
  amountOverDueCount: PropTypes.number,
  expandLink: PropTypes.string,
  listingLink: PropTypes.string
};

export default Invoices;
