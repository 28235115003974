export default function getDatetimeFormat(date) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
}

export function getDateFormat(date) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
}

export function getTimeFormat(date) {
  return new Date(date).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric'
  });
}

export function getDurationFormat(mins) {
  const hours = Math.floor(mins / 60);
  const days = Math.floor(hours / 24);

  const remainingMinutes = mins % 60;
  const remainingHours = hours % 24;
  const remainingDays = days;

  const formattedDays = remainingDays > 0 ? `${remainingDays} day${remainingDays > 1 ? 's' : ''} ` : '';
  const formattedHours = remainingHours > 0 ? `${remainingHours} hour${remainingHours > 1 ? 's' : ''} ` : '';
  const formattedMinutes = remainingMinutes > 0 ? `${remainingMinutes} min${remainingMinutes > 1 ? 's' : ''} ` : '';

  return `${formattedDays}${formattedHours}${formattedMinutes}`;
}

export function getDMHfromMins(mins) {
  const hours = Math.floor(mins / 60);
  const days = Math.floor(hours / 24);

  const remainingMinutes = mins % 60;
  const remainingHours = hours % 24;
  const remainingDays = days;

  return { days: remainingDays, hours: remainingHours, minutes: remainingMinutes };
}
