import React from 'react';
import PropTypes from 'prop-types';
import { editPriceRulePath } from 'routes';

function ActionButtons({ priceRule, onDelete }) {
  return (
    <span>
      <a className="qmb-control--icon" href={editPriceRulePath(priceRule.id)}>
        <i className="fa-light fa-pencil" />
      </a>
      <button type="button" className="qmb-control--icon" onClick={onDelete}>
        <i className="fa-light fa-trash-can" />
      </button>
    </span>
  );
}

ActionButtons.propTypes = {
  priceRule: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ActionButtons;
