import { createContext } from 'react';

const ColumnMenuContext = createContext({
  onColumnsChange: () => {},
  columns: [],
  columnsState: [],
  deficiencyStatusesForSelect: [],
  resolutionStatusesForSelect: [],
  screen: null,
  screenId: null
});

export default ColumnMenuContext;
