import React, {useRef} from "react";
// import {
//     Tooltip
// } from "@progress/kendo-react-tooltip";

function NameCell({ tdProps, dataItem}){

    const iconRef = useRef(null);

    return (
        <td {...tdProps}>
            {dataItem.imported && <span ref={iconRef} className="fa-solid fa-check" style={{color: "#01CD8F"}}></span>} &nbsp;
            {dataItem.company_name}


            {/*<Tooltip anchorElement={iconRef.current} position="right">*/}
            {/*    Imported*/}
            {/*</Tooltip>*/}
        </td>
    );

}

export default NameCell;