import React from 'react';
import { blackOrWhiteForeground } from '@utils/colors';
export function subcontractorIcon(subcontractor, size = 32) {
    const className = `qmb-avatar--${size}`;
    if (!subcontractor)
        return (React.createElement("span", { className: `${className}--unassigned`, style: { display: 'flex', alignItems: 'center' } },
            React.createElement("i", { className: "fa-light fa-handshake" })));
    return (React.createElement("span", { className: `${className}--no-tech`, style: {
            display: 'flex',
            alignItems: 'center',
            background: subcontractor.color,
            color: blackOrWhiteForeground(subcontractor.color)
        } },
        React.createElement("i", { className: "fa-light fa-handshake" })));
}
export function subcontractorTitle(subcontractor) {
    return subcontractor ? subcontractor.name : 'Unassigned';
}
