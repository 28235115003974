import { useEffect, useState } from 'react';
import api from 'axios';
import { apiInternalInspectionStatusesPath, apiInternalServiceVisitStatusesPath } from '../../routes';
const useGetVisitStatuses = (type, queryParams) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        setLoading(true);
        const controller = new AbortController();
        let url;
        switch (type) {
            case "Visit" /* VisitType.SERVICE_VISIT */:
                url = apiInternalServiceVisitStatusesPath();
                break;
            case "InspectionVisit" /* VisitType.INSPECTION_VISIT */:
            case "Inspection" /* VisitType.INSPECTION_PRIMARY_VISIT */:
                url = apiInternalInspectionStatusesPath();
                break;
            default:
                console.error(`type: ${type}`);
                return;
        }
        api
            .get(url, {
            params: queryParams,
            signal: controller.signal
        })
            .then((response) => {
            setData(response.data.data);
        })
            .catch((err) => {
            if (!controller.signal.aborted) {
                setData([]);
                setError(err);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted) {
                setLoading(false);
            }
        });
        return () => {
            controller.abort();
        };
    }, [type, queryParams]);
    return { data, loading, error };
};
export default useGetVisitStatuses;
