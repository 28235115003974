import React from 'react';
import PropTypes from 'prop-types';

function BuildingNameCell({ tdProps, dataItem }) {
  return (
    <td {...tdProps}>
      <a href={dataItem.path}>{dataItem.name}</a>
      {dataItem.qbVerified && (
        <img
          height="16"
          width="16"
          alt={`${dataItem.name}-qb-verified`}
          src="/qbo.svg"
          className="custom-icon--qbo pull-right"
          title="QuickBooks Verified"
        />
      )}
      {dataItem.invalidAddress && (
        <img
          height="16"
          width="16"
          alt={`${dataItem.name}-invalid`}
          src="https://cdn.iconscout.com/icon/premium/png-256-thumb/wrong-position-placeholder-1079464.png"
          className="custom-icon--qbo pull-right"
          title="Invalid Address"
        />
      )}
    </td>
  );
}

BuildingNameCell.propTypes = {
  tdProps: PropTypes.object.isRequired,
  dataItem: PropTypes.shape({
    path: PropTypes.string,
    name: PropTypes.string,
    qbVerified: PropTypes.bool,
    invalidAddress: PropTypes.bool
  }).isRequired
};

export default BuildingNameCell;
