import produce from 'immer';

export const actionTypes = {
  ROW_LOADING: 'ROW_LOADING',
  ROW_LOADED: 'ROW_LOADED',
  ROW_NOT_LOADED: 'ROW_NOT_LOADED'
};

export const findTreeNode = (data, nodeLocation) => {
  if (!(nodeLocation && nodeLocation.length)) return data;

  const filteredNodes = nodeLocation.reduce((nodes, node) => nodes.concat('children', node), []);

  return filteredNodes.reduce((currentNode, nextNode) => currentNode[nextNode], data);
};

const rowLoading = (data, nodeLocation) => {
  const dataObject = findTreeNode(data, nodeLocation);

  dataObject.isLoading = true;
};

const rowLoaded = (data, action) => {
  const dataObject = findTreeNode(data, action.nodeLocation);

  dataObject.isLoading = false;
  dataObject.children = action.children || [];
  dataObject.childrenType = action.childrenType;
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.ROW_LOADING:
      rowLoading(draft.data, action.nodeLocation);
      break;
    case actionTypes.ROW_LOADED:
      rowLoaded(draft.data, action);
      break;
    case actionTypes.ROW_NOT_LOADED:
      rowLoaded(draft.data, action);
      break;
    default:
      throw new Error();
  }
});
