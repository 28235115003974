import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';

import Loader from '@components/Loader';
import logoPath from '@icons/logo.png';
import List from './components/List';

import { actionTypes, reducer } from './reducer';

const API_DELAY = 3000;

function Index({ items, apiUrl }) {
  const [state, dispatch] = useReducer(reducer, {
    items: items || [],
    isLoading: false
  });

  useEffect(() => {
    dispatch({ type: actionTypes.ITEMS_LOADING });

    setTimeout(() => {
      api
        .get(apiUrl)
        .then(({ data: { data } }) => dispatch({ type: actionTypes.ITEMS_LOADED, data }))
        .catch(() => dispatch({ type: actionTypes.ITEMS_NOT_LOADED }));
    }, API_DELAY);
  }, []);

  const updateItem = (index, params) => {
    dispatch({ type: actionTypes.ITEM_UPDATED, index, params });
  };

  const removeItem = (index) => {
    dispatch({ type: actionTypes.ITEM_REMOVED, index });
  };

  const addItem = () => {
    dispatch({
      type: actionTypes.ITEM_CREATED,
      params: {
        name: Math.random().toString(36).substr(2, 10),
        description: Math.random().toString(36).substr(2, 10)
      }
    });
  };

  return (
    <div className="list-page mr-5 w-100">
      <div className="d-flex space-between">
        <img src={logoPath} alt="Logo" />
        <div>
          <input type="button" className="btn btn-success" onClick={addItem} value="Add Item" />
        </div>
      </div>
      <List items={state.items} updateItem={updateItem} removeItem={removeItem} />
      <Loader isLoading={state.isLoading} />
    </div>
  );
}

Index.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  apiUrl: PropTypes.string.isRequired
};

Index.defaultProps = {
  items: []
};

export default Index;
