import React, { useEffect, useState, useMemo } from 'react';
import { MultiSelect as KendoMultiSelect } from '@progress/kendo-react-dropdowns';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';
import './MultiSelect.css';
import { listingLookupPath, listingMapLookupPath } from 'routes';

const PAGE_SIZE = 32;

function PagedMultiSelect({ name, value, label, onChange, backendField }) {
  const [internalValue, setInternalValue] = useState([]);
  const [elements, setElements] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterQuery, setFilterQuery] = useState('');
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    if (!value.length) {
      setInternalValue([]);
      return;
    }

    if (JSON.stringify(internalValue.map((elem) => elem.value)) === JSON.stringify(value)) return;

    fetch(listingMapLookupPath({ type: backendField, ids: value }))
      .then((response) => response.json())
      .then((response) => {
        setInternalValue(response);
      });
  }, [value]);

  useEffect(() => {
    fetch(listingLookupPath({ type: backendField, take: PAGE_SIZE, skip, search: filterQuery }))
      .then((response) => response.json())
      .then((response) => {
        setElements(response.data);
        setTotalCount(response.total_count);
      });
  }, [skip, filterQuery]);

  const handleOnChange = (event) => {
    setInternalValue(event.value);
    const res = event.value.map((elem) => elem.value);
    onChange(res);
  };

  const onPageChange = (event) => {
    setSkip(event.page.skip);
    setElements(
      Array(elements.length).fill({
        label: I18n.t('components.filter_panel.paged_multi_select.loading'),
        subLabel: I18n.t('components.filter_panel.paged_multi_select.loading')
      })
    );
  };

  const onClose = () => {
    setSkip(0);
  };

  const onFilterChange = (event) => {
    setFilterQuery(event.filter.value);
    setSkip(0);
  };

  const itemRender = useMemo(() => {
    if (backendField !== 'building') return undefined;

    return (li, itemProps) => {
      const noAddress = itemProps.dataItem.subLabel.trim().length === 0;
      const itemChildren = (
        <span className="k-list-item-text">
          {itemProps.dataItem.label}
          <br />
          {!noAddress && <small style={{ color: '#555' }}>{itemProps.dataItem.subLabel}</small>}
          {noAddress && (
            <small style={{ color: '#bbb' }}>{I18n.t('components.filter_panel.paged_multi_select.no_address')}</small>
          )}
        </span>
      );
      return React.cloneElement(li, li.props, itemChildren);
    };
  }, []);

  return (
    <>
      {label && (
        <label className="qmb-label" htmlFor={name}>
          {label}
        </label>
      )}
      <KendoMultiSelect
        style={{ color: '#000' }}
        data={elements}
        textField="label"
        dataItemKey="value"
        value={internalValue}
        filterable
        onFilterChange={onFilterChange}
        onChange={handleOnChange}
        virtual={{
          total: totalCount,
          pageSize: PAGE_SIZE,
          skip
        }}
        placeholder={I18n.t('generic.all')}
        onPageChange={onPageChange}
        onClose={onClose}
        itemRender={itemRender}
      />
    </>
  );
}

export default PagedMultiSelect;

PagedMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  backendField: PropTypes.string.isRequired
};

PagedMultiSelect.defaultProps = {
  label: undefined,
  onChange: null
};
