import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';
import I18n from '@utils/i18n';

import Loader from '@components/Loader';
import { actionTypes, reducer, findTreeNode } from './EquipmentComparisonTableReducer';
import Rows from './components/EquipmentComparison/Rows';

function EquipmentComparisonTable({ apiUrl, canSeeSubcontractor, subcontractorLink, subcontractorName }) {
  const [state, dispatch] = useReducer(reducer, {
    data: {
      isLoading: true,
      children: []
    }
  });

  useEffect(() => {
    loadRow({ url: apiUrl, nodeLocation: null });
  }, []);

  const subcontractorTitle = () => {
    if (canSeeSubcontractor) {
      return <a href={subcontractorLink}>{subcontractorName}</a>;
    }
    return subcontractorName || I18n.t('generic.n_a');
  };

  const loadRow = ({ url, nodeLocation, forceReload }) => {
    if (!forceReload) {
      const node = findTreeNode(state.data, nodeLocation);
      if (node && node.children && node.children.length) return;
    }

    dispatch({ type: actionTypes.ROW_LOADING, nodeLocation });

    /* eslint-disable camelcase */
    api
      .get(url)
      .then(({ data: { data, data_type } }) => {
        dispatch({
          type: actionTypes.ROW_LOADED,
          children: data,
          childrenType: data_type,
          nodeLocation
        });
      })
      .catch(() => dispatch({ type: actionTypes.ROW_NOT_LOADED, nodeLocation }));
    /* eslint-enable camelcase */
  };

  const displayedData = () => {
    if (state.data.children && state.data.children.length) {
      return <Rows rows={state.data.children} loadRow={loadRow} />;
    }

    if (!state.data.isLoading) {
      return (
        <p className="w-100 text-center py-5">{I18n.t('subcontractors.inspections.equipment.show.table.no_data')}</p>
      );
    }
  };

  return (
    <div className="row-fluid">
      <div className="span12">
        <h4 className="header blue bolder smaller pb-2">
          {I18n.t('subcontractors.inspections.equipment.show.table_title')}
        </h4>

        <div className="row-fluid">
          <div className="qmb-data-list">
            <div className="data-list__row--header-alt">
              <div className="data-list__col">&nbsp;</div>
              <div className="data-list__col--wide">{subcontractorTitle()}</div>
            </div>

            <div className="data-list__row--header">
              <div className="data-list__col">{I18n.t('subcontractors.inspections.equipment.show.table.system')}</div>
              <div className="data-list__col">
                {I18n.t('subcontractors.inspections.equipment.show.table.initial_count')}
              </div>
              <div className="data-list__col--divide">
                {I18n.t('subcontractors.inspections.equipment.show.table.inspected_count')}
              </div>
              <div className="data-list__col">
                {I18n.t('subcontractors.inspections.equipment.show.table.not_inspected_count')}
              </div>
            </div>

            {displayedData()}

            <Loader isLoading={state.data.isLoading} className="py-5" />
          </div>
        </div>
      </div>
    </div>
  );
}

EquipmentComparisonTable.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  canSeeSubcontractor: PropTypes.bool,
  subcontractorLink: PropTypes.string,
  subcontractorName: PropTypes.string
};

EquipmentComparisonTable.defaultProps = {
  canSeeSubcontractor: false,
  subcontractorLink: '#',
  subcontractorName: ''
};

export default EquipmentComparisonTable;
