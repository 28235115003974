import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Breadcrumbs.module.scss';

function Breadcrumbs({ items }) {
  const [state] = useState({ items });

  const isLastItem = (index) => {
    return index >= state.items.length - 1;
  };

  return (
    <div className={styles['breadcrumbs-container']}>
      <ul>
        {state.items.map((item, index) => (
          <li key={index} className={`${styles['breadcrumb-item']} ${isLastItem(index) ? 'active' : ''}`}>
            {item.iconClass && <i className={`breadcrumb-item-icon ${item.iconClass}`} />}

            <span className="breadcrumb-item-text">
              {item.path ? <a href={item.path}>{item.text}</a> : <span>{item.text}</span>}
            </span>

            {!isLastItem(index) && (
              <span className="breadcrumb-divider">
                <i className="breadcrumb-divider-icon icon-angle-right arrow-icon" />
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string,
      iconClass: PropTypes.string
    })
  )
};

Breadcrumbs.defaultProps = {
  items: []
};

export default Breadcrumbs;
