import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import './TableModal.scss';

export default function TableModal({
  title,
  subTitleView,
  isOpen,
  data,
  columns,
  onClose,
  rightActionButton,
  loading,
  timeFrame
}) {
  const renderTitle = () => (
    <>
    <section className="dialog__header">
      <div className="header__main">
        <h4 className="header__title">{title}</h4>
        <span className="header__context">{subTitleView}</span>
      </div>
      <div className="header__actions qmb-actions">
        <div className="actions__group">{rightActionButton}</div>
      </div>
    </section>
    <small className="dialog__small">{timeFrame}</small>
    </>
  );
  return (
    <Modal title={renderTitle()} className="qmb-dialog--modal" isOpen={isOpen} onClose={onClose} width={930} height={480}>
      <section className={`dialog__body${!loading && data.length === 0 || loading ? '--empty' : ''}`}>
        {!loading && data.length === 0 && <>No records found</>}
        {!loading && data.length > 0 && <Table data={data} columns={columns} size='full' />}

        {loading && (
          <div className="qmb-loading--96--alt--vert" data-title={I18n.t('generic.loading')}>
            <svg role="img">
              <use href="/map.svg#load-spinner" />
            </svg>
          </div>
        )}
      </section>
    </Modal>
  );
}

TableModal.propTypes = {
  title: PropTypes.string.isRequired,
  subTitleView: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  rightActionButton: PropTypes.node,
  loading: PropTypes.bool,
  timeFrame: PropTypes.string
};
TableModal.defaultProps = {
  subTitleView: null,
  rightActionButton: null,
  loading: false,
  timeFrame: ''
};
