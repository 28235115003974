import React from "react";

function SelectCell(props){
    return (
            <td className={props.className}>
                {props.dataItem.imported && <span className="fa-solid fa-check" style={{color: "#01CD8F"}}></span>}
                {!props.dataItem.imported &&
                    <input
                        checked={props.dataItem.selected}
                        className={"k-checkbox k-checkbox-md k-rounded-md"}
                        type={"checkbox"}
                        onChange={props.selectionChange}
                        disabled={props.dataItem.imported} />
                }
            </td>
        )

}

export default SelectCell;