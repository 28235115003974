import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

function InspectedItem({ item }) {
  const inspectedLabel = item.passed_inspection ? I18n.t('generic.pass') : I18n.t('generic.fail');
  const itemClass = [
    'itemized-list__item',
    item.passed_inspection ? '--pass' : '--fail',
    item.is_new ? '--new' : ''
  ].join('');

  const newItemSign = <i className="fa-solid fa-plus" />;

  return (
    <li className={`clearfix ${itemClass}`}>
      <div className="itemized-list__label">
        {item.is_new && newItemSign}
        {item.name}
      </div>
      <div className="itemized-list__value">{inspectedLabel}</div>
    </li>
  );
}

InspectedItem.propTypes = {
  item: PropTypes.object.isRequired
};
export default InspectedItem;
