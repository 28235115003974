import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { DateTime } from 'luxon';
import { decrementActiveDay, incrementActiveDay, status as schedulerStatus } from '../Redux/schedulerSlice';
import { types } from '../Redux/draggingSlice';
import ShallowEvent from '../DragItem/ShallowEvent';
import ShallowTechnician from '../DragItem/ShallowTechnician';
// import PropTypes from 'prop-types';

const MINUTES_IN_DAY = 1440;

function Event({ event, eventHandlers }) {
  const { HandleEventDoubleClick, HandleEventRepositionMouseDown, HandleMouseUp, HandleEventClick } = eventHandlers;

  const timeZone = useSelector((state) => state.settings.timeZone);
  const activeDay = useSelector((state) => state.scheduler.activeDay);

  const determinePercentageOfDay = (time, activeDay) => {
    const given = DateTime.fromISO(time, { zone: timeZone });
    const active = DateTime.fromISO(activeDay, { zone: timeZone });
    if (given < active) return 0;
    if (given > active.endOf('day')) return 100;

    return given.diff(given.startOf('day')).as('minutes') / MINUTES_IN_DAY * 100;
  };

  const startTimeAsPercentage = useMemo(() => determinePercentageOfDay(event.startTime, activeDay), [event.startTime, activeDay])
  const endTimeAsPercentage = useMemo(() => determinePercentageOfDay(event.endTime, activeDay), [event.endTime, activeDay])
  const overflowPrevDay = useMemo(() => startTimeAsPercentage === 0 , [startTimeAsPercentage])
  const overflowNextDay = useMemo(() => endTimeAsPercentage === 0 , [endTimeAsPercentage])

  const className = useMemo(() => {
    const classes = ['event__item'];
    if (overflowPrevDay || overflowNextDay) classes.push('--overflow');
    if (overflowPrevDay) classes.push('--prev');
    if (overflowNextDay) classes.push('--next');
    if (event.metadata.repositioning) classes.push('--dragging');
    if (event.type !== 'TimeOff' && event.type !== 'Holiday') classes.push('--custom');

    return classes.join('');
  }, [event.startTime, event.endTime, activeDay, event.metadata.repositioning]);

  const eventColor = useMemo(() => {
    if (event.type === 'TimeOff' || event.type === 'Holiday') return {};
    if (event.status === 'Scheduled') return { '--custom-status-color': `${event.statusColor}1f` };
    if (event.status === 'Started') return { '--custom-status-color': `${event.statusColor}3d` };

    return { '--custom-status-color': `${event.statusColor}26` };
  }, []);

  const style = {
    left: `${startTimeAsPercentage}%`,
    width: `${endTimeAsPercentage - startTimeAsPercentage}%`,
    height: `${(100 / event.metadata.subdivision) * event.metadata.span}%`,
    top: `calc((${100 / event.metadata.subdivision}% ) * ${event.metadata.offset})`,
    borderTop: event.metadata.offset === 0 ? 'unset' : 'transparent',
    ...eventColor
  };

  return (
    <div
      className={className}
      onMouseDown={(e) => {
        if (!event.readonly) HandleEventRepositionMouseDown(e, event.key, event.startTime);
      }}
      onDoubleClick={(e) => HandleEventDoubleClick(e, event.key)}
      onMouseUp={(e) => {
        if (!event.readonly) HandleMouseUp(e, event.key);
      }}
      onClick={(e) => HandleEventClick(e, event)}
      style={style}>
      <div className="event__card">
        {event.readonly ? null : (
          <ResizeHandles
            eventKey={event.key}
            overflowPrevDay={overflowPrevDay}
            overflowNextDay={overflowNextDay}
            eventHandlers={eventHandlers}
          />
        )}
        <OverflowIndicators overflowPrevDay={overflowPrevDay} overflowNextDay={overflowNextDay} />
        <TimeTooltips {...event} />
        <Tooltip anchorElement="target" position="top">
          <div className="event__crux">
            <span className="event__type">
              <Icon type={event.type} />
            </span>
            <span className="event__time">
              {event.metadata.repositioning || event.metadata.resizing
                ? DateTime.fromISO(event.metadata.prevStartTime, { zone: timeZone }).toFormat('hh:mm a')
                : DateTime.fromISO(event.startTime, { zone: timeZone }).toFormat('hh:mm a')}
            </span>
            <span className="event__status qmb-status">
              <i title={event.humanizedStatus} className={event.statusIcon} style={{ color: event.statusColor }} />
            </span>
            <span className="event__title">{event.title}</span>
          </div>
        </Tooltip>
        <div className="event__context">{event.subtitle}</div>
      </div>
    </div>
  );
}

function ResizeHandles({ eventKey, eventHandlers, overflowPrevDay, overflowNextDay }) {
  const { HandleEventResizeMouseDown } = eventHandlers;

  const status = useSelector((state) => state.scheduler.status);

  const left = useRef(null);
  const right = useRef(null);

  const ActivateHandle = (ref) => {
    if (!isModifyingEvent(status)) {
      ref.current.classList.add('--active');
    }
  };

  const ActivateSnapping = (ref) => {
    if (!isModifyingEvent(status)) {
      ref.current.classList.add('--snapping');
    }
  };

  const DeactivateSnapping = (ref) => {
    if (!isModifyingEvent(status)) {
      ref.current.classList.remove('--snapping');
    }
  };

  const DragHandle = (e, ref, isLeftHandle) => {
    e.stopPropagation();
    HandleEventResizeMouseDown(e, eventKey, isLeftHandle, ref.current.classList.contains('--snapping'));
    document.addEventListener('mouseup', (ev) => ResetHandle(ref, ev.target === ref.current), { once: true });
  };

  const ResetHandle = (ref, stillHovering) => {
    if (!isModifyingEvent(status)) {
      if (!stillHovering) {
        ref.current.classList.remove('--active');
        ref.current.classList.remove('--snapping');
      }
    }
  };

  const leftHandle = overflowPrevDay.prev ? null : (
    <div
      ref={left}
      className="event__handle--left"
      onMouseEnter={() => ActivateHandle(left)}
      onMouseLeave={() => ResetHandle(left)}
      onMouseDown={(e) => DragHandle(e, left, true)}>
      <div
        className="snap-grab-zone"
        onMouseEnter={() => ActivateSnapping(left)}
        onMouseLeave={() => DeactivateSnapping(left)}
      />
    </div>
  );
  const rightHandle = overflowNextDay ? null : (
    <div
      ref={right}
      className="event__handle--right"
      onMouseEnter={() => ActivateHandle(right)}
      onMouseLeave={() => ResetHandle(right)}
      onMouseDown={(e) => DragHandle(e, right, false)}>
      <div
        className="snap-grab-zone"
        onMouseEnter={() => ActivateSnapping(right)}
        onMouseLeave={() => DeactivateSnapping(right)}
      />
    </div>
  );

  return (
    <>
      {leftHandle}
      {rightHandle}
    </>
  );
}

function TimeTooltips(event) {
  const timeZone = useSelector((state) => state.settings.timeZone);

  if (event.metadata.repositioning || event.metadata.resizing) {
    return (
      <>
        <div className="drag-tooltip--left">
          {DateTime.fromISO(event.startTime, { zone: timeZone }).toFormat('hh:mm a')}
        </div>
        <div className="drag-tooltip--right">
          {DateTime.fromISO(event.endTime, { zone: timeZone }).toFormat('hh:mm a')}
        </div>
      </>
    );
  }

  return null;
}

function Icon({ type }) {
  switch (type) {
    case 'Visit':
      return <i className="fa-light fa-screwdriver-wrench" />;
    case 'Inspection':
    case 'InspectionVisit':
      return <i className="fa-light fa-clipboard-check" />;
    case 'TimeOff':
      return <i className="fa-light fa-calendar-clock" />;
    default:
      return <i className="fa-light fa-square-question" />;
  }
}

function OverflowIndicators({ overflowPrevDay, overflowNextDay }) {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.scheduler.status);

  const left = useRef(null);
  const right = useRef(null);

  const ActivateIndicator = (ref) => {
    if (!isModifyingEvent(status)) {
      ref.current.classList.add('--active');
    }
  };

  const DeactivateIndicator = (ref) => {
    if (!isModifyingEvent(status)) {
      ref.current.classList.remove('--active');
    }
  };

  const HandleClick = (e, isLeftIndicator) => {
    e.stopPropagation();
    dispatch(isLeftIndicator ? decrementActiveDay() : incrementActiveDay());
  };

  const leftIndicator = !overflowPrevDay ? null : (
    <div ref={left} className="event__nav--left">
      <button
        className="qmb-control--icon"
        onMouseEnter={(e) => ActivateIndicator(left)}
        onMouseLeave={(e) => DeactivateIndicator(left)}
        onClick={(e) => HandleClick(e, true)}>
        <i className="fa-light fa-angle-left" />
      </button>
    </div>
  );

  const rightIndicator = !overflowNextDay ? null : (
    <div ref={right} className="event__nav--right">
      <button
        className="qmb-control--icon"
        onMouseEnter={(e) => ActivateIndicator(right)}
        onMouseLeave={(e) => DeactivateIndicator(right)}
        onClick={(e) => HandleClick(e, false)}>
        <i className="fa-light fa-angle-right" />
      </button>
    </div>
  );

  return (
    <>
      {leftIndicator}
      {rightIndicator}
    </>
  );
}

const isModifyingEvent = (status) => {
  return status === schedulerStatus.REPOSITIONING || status === schedulerStatus.RESIZING;
};

Event.defaultProps = {
  subdivision: 1,
  span: 1,
  offset: 0,
  color: '#AD90CF',
  dragging: false,
  resizing: false
};

export default Event;
