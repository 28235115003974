import produce from 'immer';

import {
  actionTypes as dateRangePickerActionTypes,
  buildInitialState as buildInitialDateRangeState,
  reducerFunction as dateRangePickerReducerFunction
} from '@components/Kendo/DateRangePickerReducer';

export const actionTypes = {
  TABLE_RELOADED: 'TABLE_RELOADED',
  REPORT_SELECTED: 'REPORT_SELECTED',
  REPORT_DESELECTED: 'REPORT_DESELECTED',
  REPORTS_DESELECTED: 'REPORTS_DESELECTED',
  FILTER_CHANGED: 'FILTER_CHANGED',
  FILTERS_RESET: 'FILTERS_RESET',
  LOAD_FILTERS_FROM_CACHE: 'LOAD_FILTERS_FROM_CACHE',
  ...dateRangePickerActionTypes // static line, should be the only one for all DateRangePickers
};

export const buildInitialFiltersState = () => {
  return {
    building: '',
    buildingStatus: '',
    territory: '',
    inspectionTypeId: '',
    isPublicReport: '',
    ...buildInitialDateRangeState('inspectionDate') // dynamic line, should be added for each DateRangePicker
  };
};

// Or could be imported directly into component, up to your preferences
export { isDateRangeStateField } from '@components/Kendo/DateRangePickerReducer';

export const reducer = produce((draft, action) => {
  // static snippet, should be the only one for all DateRangePickers
  if (Object.keys(dateRangePickerActionTypes).includes(action.type)) {
    return dateRangePickerReducerFunction(draft, action);
  }

  switch (action.type) {
    case actionTypes.TABLE_RELOADED:
      draft.tableFingerprint = new Date().getTime();
      break;
    case actionTypes.REPORT_SELECTED:
      draft.reports.push(action.id);
      break;
    case actionTypes.REPORT_DESELECTED:
      draft.reports.splice(draft.reports.indexOf(action.id), 1);
      break;
    case actionTypes.REPORTS_DESELECTED:
      draft.reports = [];
      break;
    case actionTypes.FILTER_CHANGED:
      draft.filters[action.field] = action.value;
      break;
    case actionTypes.FILTERS_RESET:
      draft.filters = buildInitialFiltersState();
      break;
    case actionTypes.LOAD_FILTERS_FROM_CACHE:
      draft.filters = action.filters;
      break;
    default:
      throw new Error('Reducer - Wrong action type.');
  }
});
