/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import api from '@utils/axios';
import { translate } from '@utils/i18n';
import toast from 'react-hot-toast';
import { apiInternalVisitsPath, checkAvailableTechniciansPath } from 'routes';
import { Duration } from 'luxon';
import Modal from '../../../components/Modal/Modal';
import Button, { ButtonVariant } from '../../../components/Button/Button';
import FormTypeSelector from './components/FormTypeSelector';
import Select from '../../../components/Select/Select';
import TimeInput from '../../../components/TimeInput/TimeInput';
import TextInput from './components/TextInput';
// import InputTextArea, { StyleVariant } from '../../../components/InputTextArea/InputTextArea';
import DateInput from '../../../components/DateInput/DateInput';
import LoadableButton from '../../../components/Button/LoadableButton';

const namespace = 'features.work_orders';

export default function NewVisitModal({
  woId,
  isOpen,
  onClose,
  defaultTechnicianId,
  techOptions,
  vendorOptions,
  statusesOptions,
  onAddComplete
}) {
  const [loading, setIsLoading] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [checkAvailableNotification, setCheckAvailableNotification] = useState({ message: '', data: null });
  const { register, reset, handleSubmit, control, watch } = useForm({
    defaultValues: {
      technicianId: defaultTechnicianId || '',
      subcontractorId: '',
      status: '',
      scheduledDate: new Date(),
      days: 0,
      hours: 1,
      minutes: 0,
      notesToVendor: '',
      poNumber: '',
      billingLimit: 0
    }
  });

  useEffect(() => {
    let controller = new AbortController();

    const subscription = watch((value, { name }) => {
      setCheckAvailableNotification({ message: '', data: null });

      if (!['minutes', 'hours', 'days', 'technicianId', 'scheduledDate'].includes(name)) return;
      if (!parseInt(value.technicianId, 10)) return;

      controller.abort();
      controller = new AbortController();

      api
        .get(checkAvailableTechniciansPath({ format: 'json' }), {
          signal: controller.signal,
          params: {
            technician_id: value.technicianId,
            scheduled_date: value.scheduledDate,
            duration: Duration.fromObject({
              days: value.days,
              hours: value.hours,
              minutes: value.minutes
            }).as('minutes')
          }
        })
        .catch((error) =>
          setCheckAvailableNotification({
            message: error?.response?.data?.message,
            data: error?.response?.data?.data
          })
        );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (newVisitInfo) => {
    try {
      setIsLoading(true);
      const visitInfo = {
        scheduled_date: new Date(newVisitInfo.scheduledDate),
        duration: Number(newVisitInfo.days) * 1440 + Number(newVisitInfo.hours * 60) + Number(newVisitInfo.minutes),
        technician_id: !isVendor ? newVisitInfo.technicianId ?? null : null,
        work_order_id: woId,
        notes: isVendor ? newVisitInfo.notesToVendor : undefined,
        visit_status: newVisitInfo.status ? newVisitInfo.status : undefined,
        subcontractor_id: isVendor ? newVisitInfo.subcontractorId ?? null : null,
        subcontractor_po_number: newVisitInfo.poNumber ? newVisitInfo.poNumber : undefined,
        subcontractor_billing_limit: newVisitInfo.billingLimit ? newVisitInfo.billingLimit : undefined,
        assignee_type: isVendor ? 'subcontractor' : 'technician'
      };

      const { data: res } = await api.post(apiInternalVisitsPath({ format: 'json' }), { visit: visitInfo });

      onAddComplete(res.visit);
      reset();
      setIsVendor(false);
    } catch (e) {
      toast.error(translate('add_error', { namespace }), {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onClickCancel = () => {
    onClose();
    reset();
    setIsVendor(false);
  };

  const onChangeAsigneeType = ({ isVendor: newIsVendor }) => {
    setIsVendor(newIsVendor);
  };

  const technicianOptions = techOptions.map(([name, id]) => ({ label: name, value: id }));
  const subcontractonOptions = vendorOptions.map(([name, id]) => ({ label: name, value: id }));
  const statuses = statusesOptions.map(([name, id]) => ({ label: name, value: id }));

  return (
    <Modal
      title={translate('new_visit', { namespace })}
      isOpen={isOpen}
      onClose={onClickCancel}
      width={600}
      height={isVendor ? 510 : 368}>
      <section className="qmb-modal__body visitModal">
        <form className="modalForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="formSection">
            <FormTypeSelector isVendor={isVendor} onChange={onChangeAsigneeType} />
          </div>
          <div className="formSection equalChildrens">
            {!isVendor && (
              <Select
                {...register('technicianId')}
                id="technicianId"
                noOptionLabel={translate('unassigned', { namespace })}
                label={translate('technician', { namespace })}
                options={technicianOptions}
              />
            )}
            {isVendor && (
              <Select
                {...register('subcontractorId')}
                id="subcontractorId"
                noOptionLabel={translate('unassigned', { namespace })}
                label={translate('subcontractor', { namespace })}
                options={subcontractonOptions}
              />
            )}
            <Select {...register('status')} label={translate('status', { namespace })} id="status" options={statuses} />
          </div>
          {isVendor && (
            <div className="formSection">
              <div className="equalChildrens">
                <Controller
                  name="poNumber"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      id="poNumber"
                      value={field.value}
                      name={field.name}
                      onChange={field.onChange}
                      label={`${translate('po', { namespace })} #`}
                    />
                  )}
                />
                <Controller
                  name="billingLimit"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      id="billingLimit"
                      value={field.value}
                      name={field.name}
                      onChange={field.onChange}
                      type="number"
                      min={0}
                      label={translate('billing_limit', { namespace })}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="formSection">
            <div className="equalChildrens">
              <Controller
                name="scheduledDate"
                control={control}
                render={({ field }) => (
                  <DateInput
                    id="scheduledDate"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    label={translate('scheduled_date', { namespace })}
                  />
                )}
              />
              <Controller
                name="scheduledDate"
                control={control}
                render={({ field }) => (
                  <TimeInput
                    id="scheduledDate"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    label={translate('start_time', { namespace })}
                  />
                )}
              />
            </div>
            <div className="equalChildrens">
              <span>{translate('duration', { namespace })}:</span>
              <Controller
                name="days"
                control={control}
                render={({ field }) => (
                  <TextInput
                    id="days"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    label={translate('days', { namespace: 'features.commons' })}
                    type="number"
                    min={0}
                  />
                )}
              />
              <Controller
                name="hours"
                control={control}
                render={({ field }) => (
                  <TextInput
                    id="hours"
                    type="number"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    min={0}
                    label={translate('hours', { namespace: 'features.commons' })}
                  />
                )}
              />
              <Controller
                name="minutes"
                control={control}
                render={({ field }) => (
                  <TextInput
                    id="minutes"
                    type="number"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    min={0}
                    label={translate('minutes', { namespace: 'features.commons' })}
                  />
                )}
              />
            </div>
          </div>
          {/*{isVendor && (
            <div className="formSection">
              <Controller
                name="notesToVendor"
                control={control}
                render={({ field }) => (
                  <InputTextArea
                    label={translate('notes_to_subcontractor', { namespace })}
                    value={field.value}
                    name={field.name}
                    buttonControlled={false}
                    variant={StyleVariant.Light}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          )} */}
          <div className="modalActionContainer">
            <div style={{ margin: 'auto' }}>
              {checkAvailableNotification.message}
              <br />
              {checkAvailableNotification.data && (
                <a href={checkAvailableNotification.data.link} target="_blank" rel="noreferrer">
                  {checkAvailableNotification.data.text_link}
                </a>
              )}
            </div>
            <Button onClick={onClickCancel}>{translate('cancel', { namespace: 'features.commons' })}</Button>
            <LoadableButton type="submit" variant={ButtonVariant.Primary} loading={loading}>
              {translate('save', { namespace: 'features.commons' })}
            </LoadableButton>
          </div>
        </form>
      </section>
    </Modal>
  );
}

NewVisitModal.propTypes = {
  woId: PropTypes.number.isRequired,
  vendorOptions: PropTypes.array,
  onAddVisit: PropTypes.func,
  isOpen: PropTypes.bool,
  defaultTechnicianId: PropTypes.number,
  onClose: PropTypes.func,
  techOptions: PropTypes.array,
  statusesOptions: PropTypes.array,
  onAddComplete: PropTypes.func.isRequired
};

NewVisitModal.defaultProps = {
  vendorOptions: [],
  onAddVisit: () => {},
  defaultTechnicianId: null,
  isOpen: false,
  onClose: () => {},
  techOptions: [],
  statusesOptions: []
};
