import { loadCacheFromLocalStorage, toRestoreGridPage } from '../../../../helpers/localStorage';
import { INITIAL_SORT, ALL_COLUMNS, INITIAL_FILTERS, INITIAL_PAGE } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const getInitialData = (cacheKey) => {
  const cache = loadCacheFromLocalStorage(cacheKey);
  if (cacheIsValid(cache, cacheKey)) {
    return { ...cache, loading: true, page: toRestoreGridPage() && cache.page ? cache.page : INITIAL_PAGE };
  }

  return {
    page: INITIAL_PAGE,
    sort: INITIAL_SORT,
    filters: INITIAL_FILTERS,
    columns: ALL_COLUMNS,
    loading: true
  };
};

const cacheIsValid = (cache, cacheKey) => {
  if (!cache) return false;

  if (
    cache.filters &&
    cache.filters.values &&
    cache.filters.active &&
    cache.filters.operators &&
    cache.sort &&
    typeof cache.columns[0] === 'string'
  )
    return true;

  localStorage.removeItem(cacheKey);
  return false;
};
