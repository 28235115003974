import api from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import qs from 'qs';
// On API Calls:
// - Convert camelCase to snake_case for typescript -> rails standards
// - Convert arrays to compatible rails array syntax
const apiClient = applyCaseMiddleware(api.create({
    paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
    }
}));
export default apiClient;
