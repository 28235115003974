import React from 'react';
function TechSubToggle(params) {
    if (!params.render)
        return null;
    return (React.createElement("fieldset", { className: "form__set--vertical--compact" },
        React.createElement("div", { className: "formSection" },
            React.createElement("div", { className: "formTypeSelector" },
                React.createElement("div", { className: "qmb-radio-group--x-full" },
                    React.createElement("label", { htmlFor: "assigneeType", className: "qmb-label" }, "Assign To:"),
                    React.createElement("div", { className: "qmb-radio-group radioGroupContainer", style: { alignItems: 'center' } },
                        React.createElement("div", { className: "qmb-radio-group__item" },
                            React.createElement("input", { type: "radio", id: "assignee-radio-technicians", name: "assigneeType", value: "technicians", checked: params.type === "technician" /* AssigneeType.TECHNICIAN */, onChange: () => params.onChange("technician" /* AssigneeType.TECHNICIAN */) }),
                            React.createElement("label", { htmlFor: "assignee-radio-technicians" }, "Our Technicians")),
                        React.createElement("div", { className: "qmb-radio-group__item" },
                            React.createElement("input", { type: "radio", id: "assignee-radio-subcontractors", name: "assigneeType", value: "subcontractor", checked: params.type === "subcontractor" /* AssigneeType.SUBCONTRACTOR */, onChange: () => params.onChange("subcontractor" /* AssigneeType.SUBCONTRACTOR */) }),
                            React.createElement("label", { htmlFor: "assignee-radio-subcontractors" }, "Subcontractors"))))))));
}
export default TechSubToggle;
