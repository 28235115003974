/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import './TimeInput.scss';

function TimeInput({ id, value, onChange, label, name }) {
  return (
    <div className="qmb-input timeInputContainer">
      <TimePicker id={id} name={name} value={value} onChange={onChange} />
      <label htmlFor={id} className="qmb-label">
        {label}
      </label>
    </div>
  );
}

export default TimeInput;

TimeInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  label: PropTypes.string
};

TimeInput.defaultProps = {
  value: '',
  name: '',
  onChange: () => {},
  label: ''
};
