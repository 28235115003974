import React, {useEffect, useRef, useState} from "react";
import { Popup } from "@progress/kendo-react-popup";


function ProposalGroupButton({rowName}){
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [currentGroupName, setCurrentGroupName] = useState(document.querySelector(`[name*="${rowName}[group_description]"]`).value)

    const anchor = useRef(null)
    const popupRef = useRef(null)

    useEffect(() => {
        const groupInput = document.querySelector(`[name*='${rowName}[group_description]']`)
        groupInput.addEventListener('change', function(){
            if (this.value)
                setCurrentGroupName(this.value)
            else
                setCurrentGroupName(null)
        })


        document.querySelector('.partition__workspace').addEventListener('scroll', function(e){
            setDropdownOpen(false)
        })


        document.body.addEventListener('click', function(e){
            if (anchor.current.contains(e.target))
                return;
            if (!popupRef.current?.contains(e.target))
                setDropdownOpen(false)
        })

    }, [])

    const buttonClick = (e) => {
        setCurrentGroupName(document.querySelector(`[name*="${rowName}[group_description]"]`).value)
        setDropdownOpen(!dropdownOpen);
    }


    return(
        <React.Fragment>
            <button type="button" ref={anchor} className={`qmb-control--icon--toggle${currentGroupName ? "--active" : ""}`} onClick={buttonClick}>
                {currentGroupName && <i className="fa-light fa-object-group"></i>}
                {!currentGroupName && <i className="fa-light fa-grid-2-plus"></i>}
            </button>

            <Popup anchor={anchor.current} show={dropdownOpen}>
                <Dropdown rowName={rowName} currentGroup={currentGroupName} closeDropdown={()=>setDropdownOpen(false)} popupRef={popupRef} />
            </Popup>
        </React.Fragment>


    )

}

function Dropdown({rowName, currentGroup, closeDropdown, popupRef}){
    const [isLoading, setIsLoading] = useState(true)
    const [allGroups, setAllGroups] = useState([])
    const [filteredGroups, setFilteredGroups] = useState([])

    const searchRef = useRef(null);

    useEffect(()=>{
        setAllGroups(FindExistingGroups());
        setFilteredGroups(FindExistingGroups());
        setIsLoading(false);
    }, [])


    const Content_Spinner = () => {
        return (
            <div className="qmb-loading--32--vertical" data-title={I18n.t('generic.loading')} style={{padding: "2rem", alignSelf: "center", flex: "1 1 auto"}}>
                <svg role="img">
                    <use href="/map.svg#load-spinner"/>
                </svg>
            </div>
        )
    }

    const NewGroupButton = () => {
        return (
            <button className={"qmb-control"} onClick={() => AddNewGroup(searchRef.current.value)}>
                {I18n.t('features.group_lookup.create_group')}<i className="fa-light fa-turn-down-left"></i>
            </button>
        )
    }

    const Content_Groups = () => {
        const GroupItem = ({desc}) => {
            return (
                <button className={"qmb-control" + (desc === currentGroup ? "--highlight" : "")} title={desc} onClick={() => AddToGroup(desc)}>
                    {desc}
                </button>
            )
        }
        return (
            <React.Fragment>
                {filteredGroups.map(x => <GroupItem key={`group_${x}`} desc={x}/>)}
            </React.Fragment>
        )
    }


    const Content = () => {
        if (isLoading){
            return <Content_Spinner/>
        } else {
            return <Content_Groups />
        }
    }


    const AddNewGroup = (groupName) => {
        if (groupName){
            $('#add_new_proposal_summary_item').trigger('click', [{isGroup: true, description: groupName}])
            AddToGroup(groupName);
        }
    }
    const AddToGroup = (groupName) => {
        if (groupName){
            const rowGroupInput = document.querySelector(`[name*='${rowName}[group_description]']`)
            rowGroupInput.value = groupName
            rowGroupInput.dispatchEvent(new Event('change'));
            document.dispatchEvent(new Event('GroupWatcherTrigger'));
            closeDropdown();
        }
    }

    const RemoveFromGroup = () => {
        const rowGroupInput = document.querySelector(`[name*='${rowName}[group_description]']`)
        rowGroupInput.value = null;
        rowGroupInput.dispatchEvent(new Event('change'));
        document.dispatchEvent(new Event('GroupWatcherTrigger'));
        closeDropdown();
    }

    const HandleKeyUp = (e) => {
        const value = e.target.value;
        if (e.key === 'Enter'){
            allGroups.includes(value) ? AddToGroup(value) : AddNewGroup(value)
        }
        else{
            setFilteredGroups(allGroups.filter(x => x.toLowerCase().startsWith(e.target.value.toLowerCase())))
        }
    }

    return (
        <div tabIndex={0} className={"qmb-popup"} ref={popupRef}>
            <div className={"qmb-input--blank"}>
                <input ref={searchRef} placeholder={I18n.t('features.group_lookup.enter_name')} onKeyUp={HandleKeyUp} />
            </div>

            <div style={{display: "flex", flexDirection: "column", maxHeight: "120px", overflowY: "auto" }}>
                <Content/>
                <NewGroupButton/>
            </div>

            { currentGroup &&
                <div style={{ padding: "1.0rem", display: "flex", justifyContent: "center"}}>
                    <button className={"qmb-control"} onClick={RemoveFromGroup}><i className="fa-light fa-xmark"></i>{I18n.t('features.group_lookup.remove_from_group')}</button>
                </div>
            }

        </div>
    )
}

function FindExistingGroups(){
    const availableGroups = document.querySelectorAll("tr:has([name*='[is_group]'])")
    return Array.from(availableGroups).map(tr => {
        const isGroup = tr.querySelector("[name*='[is_group]']").value === 'true'
        if (isGroup && tr.style.display !== 'none' && getComputedStyle(tr).display !== 'none'){
            const descriptionInput = tr.querySelector('[name*="[description]"]');
            return descriptionInput.value ? descriptionInput.value : null
        }
        else {
            return null;
        }
    }).filter(x => x !== null);
}


export default ProposalGroupButton;