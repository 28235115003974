import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function VisibilityObserver({ onChangeVisibility, children }) {
  const ref = useRef(null);
  const visibility = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (visibility.current !== entry.isIntersecting) {
          visibility.current = entry.isIntersecting;
          onChangeVisibility(entry.isIntersecting);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [onChangeVisibility]);

  return <div ref={ref}>{children}</div>;
}

export default VisibilityObserver;

VisibilityObserver.propTypes = {
  onChangeVisibility: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};
