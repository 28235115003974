import { loadCacheFromLocalStorage, toRestoreGridPage } from '../../../helpers/localStorage';
import { INITIAL_SORT, ALL_COLUMNS, INITIAL_FILTERS, INITIAL_PAGE } from '../constants';

export const getInitialData = ({ cacheKey, buildingId, inspectionSeriesId, accountId }) => {
  const cache = loadCacheFromLocalStorage(cacheKey);
  if (cacheIsValid(cache, cacheKey)) {
    return {
      ...cache,
      page: toRestoreGridPage() && cache.page ? cache.page : INITIAL_PAGE,
      data: [],
      totalCount: 0,
      selectAll: false,
      loading: true
    };
  }

  return {
    page: INITIAL_PAGE,
    sort: INITIAL_SORT,
    filters: getInitialFilters(buildingId, inspectionSeriesId, accountId),
    columns: getInitialColumns(buildingId, inspectionSeriesId, accountId),
    data: [],
    totalCount: 0,
    selectAll: false,
    loading: true
  };
};

export const getInitialFilters = (buildingId, inspectionSeriesId, accountId) => {
  if (buildingId) {
    return { ...INITIAL_FILTERS, values: { ...INITIAL_FILTERS.values, building: buildingId } };
  }

  if (inspectionSeriesId) {
    return { ...INITIAL_FILTERS, values: { ...INITIAL_FILTERS.values, inspectionSeries: inspectionSeriesId } };
  }

  if (accountId) {
    return { ...INITIAL_FILTERS, values: { ...INITIAL_FILTERS.values, account: accountId } };
  }

  return INITIAL_FILTERS;
};

const getInitialColumns = (buildingId, inspectionSeriesId, accountId) => {
  if (buildingId || inspectionSeriesId) {
    return ALL_COLUMNS.filter((column) => column !== 'building');
  }

  if (accountId) {
    return ALL_COLUMNS.filter((column) => column !== 'account');
  }

  return ALL_COLUMNS;
};

const cacheIsValid = (cache, cacheKey) => {
  if (!cache) return false;

  if (
    cache.filters &&
    cache.filters.active &&
    cache.filters.values &&
    cache.filters.operators &&
    cache.sort &&
    typeof cache.columns[0] === 'string'
  )
    return true;

  localStorage.removeItem(cacheKey);
  return false;
};
