/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import { Loader, LoaderSizes } from '../Loader/Loader';

export default function LoadableButton({ loading, ...props }) {
  return (
    <Button {...props} disabled={loading}>
      {loading ? <Loader size={LoaderSizes.small} /> : props.children}
    </Button>
  );
}

LoadableButton.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired
};
