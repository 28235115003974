var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import useDebounce from '@hooks/useDebounce';
import { Popup } from '@progress/kendo-react-popup';
import './GenericMultiSelect.scss';
import useDebounceFn from '@hooks/useDebounceFn';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader, LoaderSizes } from '../../Loader/Loader';
import { MAX_ITEMS_PER_PAGE } from './constants';
function GenericMultiSelect(_a) {
    var { dataTitleField = 'label', dataSubTitleField = 'address' } = _a, params = __rest(_a, ["dataTitleField", "dataSubTitleField"]);
    const [searchText, setSearchText] = useState('');
    const searchValue = useDebounce(searchText, 300);
    const onChangeDebounced = useDebounceFn(params.selectOptions.onChange, 300);
    const [options, setOptions] = useState([]);
    const [loadingValues, setLoadingValues] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [open, setOpen] = useState(false);
    const rootAnchor = useRef(null);
    const popup = useRef(null);
    const page = useRef(0);
    const [totalCount, setTotalCount] = useState(0);
    const [internalValue, setInternalValue] = useState([]);
    useEffect(() => {
        const initialValues = Array.isArray(params.value) ? params.value : [params.value].filter(Boolean);
        if (initialValues.length === 0 && !open) {
            setSearchText('');
            setOptions((currentOptions) => {
                return [
                    ...currentOptions.sort((a, b) => {
                        return a.title.localeCompare(b.title);
                    })
                ];
            });
        }
        if (initialValues.some((val) => !options.some((option) => option.value === val))) {
            void getMissingValues(initialValues);
        }
        setInternalValue(initialValues);
    }, [params.value]);
    useEffect(() => {
        if (!open) {
            setSearchText('');
        }
    }, [open]);
    useEffect(() => {
        page.current = 0;
        setOptions((curState) => {
            return [...curState.filter((option) => internalValue.includes(option.value))];
        });
        void loadMoreOptions();
    }, [searchValue]);
    const getMissingValues = (initialValues) => __awaiter(this, void 0, void 0, function* () {
        setLoadingValues(true);
        yield Promise.all(initialValues
            .filter((val) => !options.some((option) => option.value === val))
            .map((val) => __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(`${params.valueMapUrl}&id=${val}`);
            const data = (yield res.json());
            const tableFindParam = {
                take: '1',
                skip: data
            };
            const searchParams = new URLSearchParams(tableFindParam);
            const response = yield fetch(`${params.dataUrl}&${searchParams.toString()}`);
            const { data: [itemInfo] } = 
            // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
            (yield response.json());
            setOptions((prev) => [
                { value: val, title: itemInfo[dataTitleField], subtitle: itemInfo[dataSubTitleField] },
                ...prev.filter((opt) => opt.value !== val)
            ]);
        })));
        setLoadingValues(false);
    });
    const onChangeOption = (option) => {
        const newValue = internalValue.includes(option.value)
            ? internalValue.filter((val) => val !== option.value)
            : [...internalValue, option.value];
        setInternalValue(newValue);
        onChangeDebounced(newValue);
    };
    const handleClearFilter = () => {
        setSearchText('');
        setInternalValue([]);
        setOpen(false);
        onChangeDebounced([]);
    };
    const loadMoreOptions = () => __awaiter(this, void 0, void 0, function* () {
        setLoadingOptions(true);
        const filterParams = Object.assign({ take: MAX_ITEMS_PER_PAGE.toString(), skip: (page.current * MAX_ITEMS_PER_PAGE).toString() }, (searchValue.trim() !== '' && {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'filter[filters][0][value]': searchValue.trim(),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'filter[filters][0][field]': 'label',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'filter[filters][0][operator]': 'contains',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'filter[filters][0][ignoreCase]': 'true',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'filter[logic]': 'and'
        }));
        const searchParams = new URLSearchParams(filterParams);
        const response = yield fetch(`${params.dataUrl}&${searchParams.toString()}`);
        const { data, total_count: totalItems } = 
        // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
        (yield response.json());
        setOptions((currentOptions) => [
            ...currentOptions,
            ...data
                .filter((elem) => !currentOptions.some((opt) => opt.value === Number(elem.value)))
                .map((elem) => ({
                value: Number(elem.value),
                title: elem[dataTitleField],
                subtitle: elem[dataSubTitleField]
            }))
        ]);
        setTotalCount(totalItems);
        setLoadingOptions(false);
    });
    const loadMore = () => {
        page.current += 1;
        void loadMoreOptions();
    };
    const textValue = internalValue.length > 0
        ? internalValue.map((val) => { var _a; return (_a = options.find((opt) => opt.value === val)) === null || _a === void 0 ? void 0 : _a.title; }).join(', ')
        : params.label;
    const optionsFiltered = options.filter((opt) => {
        var _a;
        return opt.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            ((_a = opt.subtitle) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchValue.toLowerCase()));
    });
    const onClickAway = (e) => {
        if (e.target !== rootAnchor.current) {
            setOpen(false);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: `qmb-control--menu multiSelectInput ${internalValue.length === 0 && !loadingValues ? 'items-center' : ''}`, type: "button", ref: rootAnchor, onClick: () => setOpen(!open) }, loadingValues ? React.createElement("span", { className: "k-icon k-i-loading k-input-loading-icon" }) : textValue),
        internalValue.length > 0 && (React.createElement("label", { className: "qmb-label multiSelectInputLabel" },
            params.label,
            " ",
            React.createElement("b", null, internalValue.length))),
        React.createElement(Popup, { anchor: rootAnchor.current, show: open, ref: popup },
            React.createElement(ClickAwayListener, { onClickAway: onClickAway },
                React.createElement("div", { className: "qmb-popup--actions--y-max multiSelectPopupContainer", style: { zIndex: 1000 } },
                    React.createElement("div", { className: "popup__section" },
                        React.createElement("input", { className: "searchInput", onChange: (e) => setSearchText(e.target.value), style: { border: 'none', padding: '1.2rem' }, placeholder: params.placeholder, value: searchText }),
                        React.createElement("i", { className: "fa-light fa-magnifying-glass searchIcon" })),
                    React.createElement("hr", { className: "popup__split" }),
                    React.createElement("div", { className: "popup__section--y-fill" },
                        React.createElement(InfiniteScroll, { loadMore: loadMore, hasMore: optionsFiltered.length < totalCount && optionsFiltered.length > 0 && !loadingOptions, useWindow: false },
                            React.createElement("ul", { key: "container", role: "presentation" },
                                React.createElement("div", { className: "messageContainer" }, !loadingOptions && optionsFiltered.length === 0 && React.createElement("span", { className: "noResults" }, "No results")),
                                optionsFiltered.map((option, idx) => (React.createElement("li", { key: `tech-option-${option.value}-idx-${idx}`, onClick: () => {
                                        onChangeOption(option);
                                    } },
                                    React.createElement("label", { className: "qmb-checkbox" },
                                        React.createElement("input", { type: "checkbox", checked: internalValue.includes(option.value), readOnly: true }),
                                        React.createElement("p", { className: "qmb-checkbox-label", style: { padding: 0 }, onClick: (e) => {
                                                e.preventDefault();
                                            } },
                                            React.createElement("span", { className: "qmb-checkbox-title" }, option.title),
                                            option.subtitle && React.createElement("span", { className: "qmb-checkbox-subtitle" }, option.subtitle)))))),
                                React.createElement("div", { className: "messageContainer" }, loadingOptions && React.createElement(Loader, { size: LoaderSizes.small }))))),
                    React.createElement("hr", { className: "popup__split" }),
                    React.createElement("div", { className: "popup__section" },
                        React.createElement("ul", { role: "presentation" },
                            React.createElement("li", null,
                                React.createElement("div", { className: "messageContainer" },
                                    React.createElement("button", { type: "button", disabled: internalValue.length === 0, className: "qmb-control--sm--reset", onClick: handleClearFilter },
                                        React.createElement("i", { className: "fa-light fa-arrow-rotate-left" }),
                                        "Reset"))))))))));
}
export default GenericMultiSelect;
