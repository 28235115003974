import React from 'react';
import { blackOrWhiteForeground } from '@utils/colors';
export function technicianAvatar(technician, size = 32) {
    const className = `qmb-avatar--${size}`;
    if (!technician)
        return (React.createElement("span", { className: `${className}--unassigned`, style: { display: 'flex', alignItems: 'center' } },
            React.createElement("i", { className: "fa-light fa-user-helmet-safety" })));
    if (!technician.photoUrl) {
        return (React.createElement("span", { className: className, style: { display: 'flex', alignItems: 'center' } },
            React.createElement("svg", { viewBox: "0 0 16 16" },
                React.createElement("circle", { cx: "8", cy: "8", r: "8", style: { fill: technician.color } }),
                React.createElement("text", { x: "50%", y: "55%", dominantBaseline: "middle", textAnchor: "middle", style: { fill: blackOrWhiteForeground(technician.color) } }, technician.name[0]))));
    }
    return (React.createElement("span", { className: className, style: { display: 'flex', alignItems: 'center' } },
        React.createElement("img", { alt: technician.name[0], style: { borderRadius: '50%', border: `1px solid ${technician.color}`, boxSizing: 'border-box' }, src: technician.photoUrl })));
}
export function technicianTitle(technician) {
    return (technician === null || technician === void 0 ? void 0 : technician.name) ? technician.name : 'Unassigned';
}
