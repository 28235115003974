import { configureStore } from '@reduxjs/toolkit';
import draggingReducer from './draggingSlice';
import schedulerReducer from './schedulerSlice';
import mapReducer from './visitsSlice';
import editReducer from './editModalSlice';
import settingsReducer from './settingsSlice';
import fleetTrackingReducer from './fleetTrackingSlice';

export const store = configureStore({
  reducer: {
    settings: settingsReducer,
    dragging: draggingReducer,
    scheduler: schedulerReducer,
    map: mapReducer,
    edit: editReducer,
    fleet: fleetTrackingReducer
  }
});

export default store;
