import produce from 'immer';

export const actionTypes = {
  PAGE_CHANGED: 'PAGE_CHANGED',
  FILTER_CHANGED: 'FILTER_CHANGED',
  SORT_CHANGED: 'SORT_CHANGED',
  COLUMNS_CHANGED: 'COLUMNS_CHANGED',
  DATA_LOADED: 'DATA_LOADED',
  SEARCH_CHANGED: 'SEARCH_CHANGED',
  LOADING_CHANGED: 'LOADING_CHANGED'
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.PAGE_CHANGED:
      draft.page = action.page;
      break;
    case actionTypes.FILTER_CHANGED:
      if (action.filters) {
        draft.filters = action.filters;
      } else {
        draft.filters.values[action.field] = action.value;
      }
      draft.page.skip = null;
      draft.page.skip = 0;
      break;
    case actionTypes.LOADING_CHANGED:
      draft.loading = action.loading;
      break;
    case actionTypes.FILTER_TOGGLED:
      draft.filters.active[action.field] = !draft.filters.active[action.field];
      draft.filters.values[action.field] = '';
      draft.page.skip = null;
      draft.page.skip = 0;
      break;
    case actionTypes.SORT_CHANGED:
      draft.sort = action.sort;
      draft.page.skip = null;
      draft.page.skip = 0;
      break;
    case actionTypes.COLUMNS_CHANGED:
      draft.columns = action.columns;
      break;
    case actionTypes.DATA_LOADED:
      draft.data = action.data.data;
      draft.totalCount = action.data.total_count;
      break;
    case actionTypes.SEARCH_CHANGED:
      draft.search = action.search;
      draft.page.skip = null;
      draft.page.skip = 0;
      break;
    default:
      throw new Error('Reducer - Wrong action type.');
  }
});
