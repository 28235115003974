/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import I18n from '@utils/i18n';
import { CUSTOM_RANGE, RANGE_OPTIONS } from '@constants/Kendo/rangeOptions';

import CommonSelect from './CommonSelect';
import DatePicker from './DatePicker';
import {DATE_FORMAT} from "../../constants/Kendo/rangeOptions";

// Add JQuery
const { $ } = window;

// Component which renders range date picker for Kendo grid filters
//
// NB. If you want to re-use it from Rails views - move to features/Layouts
function DateRangePicker({ name, onAnyChange, values, label, reactOptions, rangesOverride, message }) {
  const containerRef = reactOptions.ref || useRef();
  const popoverRef = useRef();
  const rangeValueRef = useRef();

  useEffect(() => {
    $(popoverRef.current).kendoPopup({ anchor: $(containerRef.current) });
    $(rangeValueRef.current).click(() => $(popoverRef.current).data('kendoPopup').toggle());
  }, []);

  const rangeOptionsForSelect = rangesOverride.length > 0
      ? rangesOverride.map((rangeOption)=> {return { label: I18n.t(`datatables.kendo.range_options.${rangeOption}`), value: rangeOption };})
      : RANGE_OPTIONS.map((rangeOption) => {return { label: I18n.t(`datatables.kendo.range_options.${rangeOption}`), value: rangeOption };});

  const rangeInputId = `${name}_range`;
  const rangeValue =
    values.startDate || values.endDate
      ? `${values.startDate || I18n.t('generic.not_set')} - ${values.endDate || I18n.t('generic.not_set')}`
      : '';

  const onFieldChange = (fieldName) => {
    return (e) => onAnyChange(fieldName, e.target.value);
  };

  return (
    <div className={`filters__option--plain ${ rangeValue ? '--active' : ''}`} ref={containerRef} id={reactOptions.id}>
      <button type="button" className="filter__value" ref={rangeValueRef}>
        <span>{rangeValue}</span>
      </button>
      {label && (
        <label className="qmb-label" htmlFor={rangeInputId}>
          {label}
        </label>
      )}

      <div className="hidden">
        <div className="qmb-popup--fieldset" ref={popoverRef}>
          <fieldset className="form__set--compact--vertical">
            <div className="qmb-select --x-full">
              <CommonSelect
                name={rangeInputId}
                value={values.rangeType}
                elements={rangeOptionsForSelect}
                label={I18n.t('generic.date_range')}
                selectOptions={{ id: `${name}-range` }}
                jQueryEvents={{ onChange: onFieldChange('rangeType') }}
              />
            </div>

            <DatePicker
              name={`${name}_start_date`}
              label={I18n.t('generic.start')}
              value={values.startDate}
              reactOptions={{
                id: `${name}-start-date`,
                onChange: onFieldChange('startDate')
              }}
              datePickerOptions={{value: values.startDate}}
            />
            <DatePicker
              name={`${name}_end_date`}
              label={I18n.t('generic.end')}
              value={values.endDate}
              reactOptions={{
                id: `${name}-end-date`,
                onChange: onFieldChange('endDate')
              }}
              datePickerOptions={{value: values.endDate}}
            />
          </fieldset>

          { message && <div style={{ display: "flex", justifyContent: "center", paddingTop: "1.6rem"}}>
            <em className={"--empty"}>{message}</em>
          </div>}
        </div>
      </div>
    </div>
  );
}

DateRangePicker.propTypes = {
  name: PropTypes.string.isRequired,
  onAnyChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    rangeType: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }),
  rangesOverride: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  reactOptions: PropTypes.object
};

DateRangePicker.defaultProps = {
  values: {
    rangeType: CUSTOM_RANGE,
    startDate: '',
    endDate: ''
  },
  rangesOverride: [],
  message: null,
  label: undefined,
  reactOptions: {}
};

export default DateRangePicker;
/* eslint-enable react/jsx-props-no-spreading */
