import produce from 'immer';

export const actionTypes = {
  ITEMS_LOADING: 'ITEMS_LOADING',
  ITEMS_LOADED: 'ITEMS_LOADED',
  ITEMS_NOT_LOADED: 'ITEMS_NOT_LOADED',
  ITEM_CREATED: 'ITEM_CREATED',
  ITEM_UPDATED: 'ITEM_UPDATED',
  ITEM_REMOVED: 'ITEM_REMOVED'
};

export const reducer = produce((draft, action) => {
  console.log('Items action:', action.type);

  switch (action.type) {
    case actionTypes.ITEMS_LOADING:
      draft.isLoading = true;
      break;
    case actionTypes.ITEMS_LOADED:
      draft.isLoading = false;
      draft.items.push(...action.data.map((item) => item.attributes));
      break;
    case actionTypes.ITEMS_NOT_LOADED:
      draft.isLoading = false;
      break;
    case actionTypes.ITEM_CREATED:
      draft.items.push(action.params);
      break;
    case actionTypes.ITEM_UPDATED:
      draft.items[action.index] = { ...draft.items[action.index], ...action.params };
      break;
    case actionTypes.ITEM_REMOVED:
      draft.items.splice(action.index, 1);
      break;
    default:
      throw new Error();
  }
});
