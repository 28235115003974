import { useState, useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useDebounceFn(fn, delay) {
    const [timeoutId, setTimeoutId] = useState(null);
    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const newTimeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
        setTimeoutId(newTimeoutId);
    };
}
export default useDebounceFn;
