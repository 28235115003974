import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import {
  refreshQbdCustomersAccountsPath,
  refreshQbdCustomersBuildingsPath
} from "../../../routes";


const namespace = 'features.retrieve_from_qb.mass_actions';

function MassActions({children, customers, selectAll, onSelectAll, onImport, importing, type, importTo, loading}) {

  const selectedCustomers = customers.customers.filter((x) => x.selected);
  const disabled = selectedCustomers.length === 0 || importing || loading;
  const customersCount = selectAll ? customers.total_count : selectedCustomers.length;
  const allSelected = customersCount === customers.total_count;
  const customersIds = selectedCustomers.map((customer) => customer.id).join();

  const handleRefresh = () => {
    window.location = importTo === 'building' ? refreshQbdCustomersBuildingsPath() : refreshQbdCustomersAccountsPath();
  }

  return (

    <div className="qmb-actions">
      {!disabled && (
        <div className="content-actions__group--selection">
          <button type="button" className="actions__selection">
            <span className="actions__count">{customersCount}</span>
          </button>
          {!selectAll && (
            <div className="actions__entire">
              <button type="button" tabIndex="0" className="qmb-control--sm" onClick={onSelectAll}>
                {translate('selected_global_counter', { namespace, count: customers.total_count })}
              </button>
            </div>
          )}
        </div>
      )}

      <div className="content-actions__group">
        <button type="button" tabIndex="0" className="qmb-control" disabled={disabled} onClick={onImport}>
          <i className="fa-light fa-file-import" />
          {translate('import_from_qb', { namespace })}
        </button>

        {type === 'desktop' &&
            <button type="button" tabIndex="0" className="qmb-control" onClick={handleRefresh}>
              <i className="fa-light fa-arrows-rotate" />
              {translate('refresh_from_qbd', { namespace })}
            </button>

        }

      </div>
      {children}
    </div>
  );
}

MassActions.propTypes = {
  customers: PropTypes.shape({
    customers: PropTypes.arrayOf(PropTypes.object),
    total_count: PropTypes.number
  }).isRequired,
  onSelectAll: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
  techniciansForSelect: PropTypes.arrayOf(PropTypes.object),
  statusesForSelect: PropTypes.arrayOf(PropTypes.object),
  buildingId: PropTypes.number
};

MassActions.defaultProps = {
  techniciansForSelect: [],
  statusesForSelect: [],
  buildingId: null
};

export default MassActions;
