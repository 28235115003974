import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

function ServiceVisitsDisabled({ supportEmail }) {
  return (
    <div className="qmb-widget--sm" style={{ backgroundColor: '#ddd' }}>
      <div className="widget__header">
        <svg className="widget__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
          <polygon points="64,0 0,64 0,0 " />
        </svg>
        <h3 className="widget__title">{I18n.t('generic.service')}</h3>
      </div>

      <div className="widget__body" style={{ display: 'flex' }}>
        <div>
          {I18n.t('features.dashboards.home.service_visits_disabled.notice')}&nbsp;
          <a href={`mailto:${supportEmail}`}>{I18n.t('features.dashboards.home.service_visits_disabled.reach_out')}</a>
          &nbsp;{I18n.t('features.dashboards.home.service_visits_disabled.to_learn_more')}
        </div>
      </div>
    </div>
  );
}

ServiceVisitsDisabled.defaultProps = {
  supportEmail: 'support@inspectpoint.com'
};

ServiceVisitsDisabled.propTypes = {
  supportEmail: PropTypes.string
};

export default ServiceVisitsDisabled;
