import { translate } from '@utils/i18n';

const namespace = 'features.dashboards.home.tables';

// eslint-disable-next-line import/prefer-default-export
export const BaseOtherStatus = {
  name: translate('other', { namespace }),
  statusCode: 'other',
  icon: 'qmb-status--other',
  statusColor: '#777777',
  value: 0
};
