import React, {useEffect, useState} from "react";

function ProposalGroupWatcher({rowName}){

    const isGroup = document.querySelector(`[name*="${rowName}[is_group]"]`)?.value === 'true'
    if (!isGroup) return null;

    const [childrenItems, setChildrenItems] = useState([])

    const FindMyItems = () => {
        const availableItems = document.querySelectorAll("tr:has([name*='[group_description]'])")

        return Array.from(availableItems).map(tr => {
            const isChild = tr.querySelector("[name*='[group_description]']").value === document.querySelector(`[name*="${rowName}[description]"`).value;
            return isChild && tr.style.display !== 'none' && getComputedStyle(tr).display !== 'none' ? tr : null;
        }).filter(x => x !== null);
    }


    useEffect(() => {
        setChildrenItems(FindMyItems())
        document.addEventListener('GroupWatcherTrigger', function(){
            setChildrenItems(FindMyItems())
        })

        document.querySelector(`[name*="${rowName}[description]"`).addEventListener('change', function(){
            const newDescription = this.value;
            const oldDescription = this.getAttribute('data-previous-value')
            const groupMembers = document.querySelectorAll('[name*="group_description"]')

            groupMembers.forEach(function(element) {
                if (element.value && element.value === oldDescription) {
                    element.value = newDescription || null;
                    element.dispatchEvent(new Event('change')); // Alert changed programatically
                }
            });
            this.setAttribute('data-previous-value', newDescription || null);
        });


        document.querySelector(`[data-delete-association-field-name*="${rowName}[_destroy]"`).addEventListener('click', function(){
            const groupMembers = document.querySelectorAll('[name*="group_description"]')
            const description = document.querySelector(`[name*="${rowName}[description]"`).value

            groupMembers.forEach(function(element) {
                if (element.value && element.value === description) {
                    element.value =null;
                    element.dispatchEvent(new Event('change'));
                }
            });
        });
    }, [])

    useEffect(() => {
        let sumPrice = 0;
        let sumCost = 0;
        let sumMargin = 0;

        for (const child of childrenItems){
            sumPrice += Number(child.querySelector("[name*='total']").value)
            sumCost += Number(child.querySelector("[name*='unit_cost']")?.value)
            sumMargin += Number(child.querySelector("[name*='margin']")?.value)
        }

        document.querySelector(`[name*="${rowName}[price]"`).value = sumPrice.toFixed(2);

        const profitMargin = document.querySelector(`[name*="${rowName}[unit_cost]"`)
        if (profitMargin){
            document.querySelector(`[name*="${rowName}[unit_cost]"`).value = sumCost.toFixed(2);
            document.querySelector(`[name*="${rowName}[margin]"`).value = sumMargin.toFixed(2);
            document.querySelector(`[data-name*="${rowName}[margin_percentage]"`).innerHTML = sumPrice !== 0 ? `(${Math.round((sumMargin / sumPrice) * 10000) / 100}%)` : ''
        }

    }, [childrenItems])

    return null;
}


export default ProposalGroupWatcher;