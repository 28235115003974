import React from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

function ShallowEvent({ event }) {
  const timeZone = useSelector((state) => state.settings.timeZone);

  return (
    <div className={`schedule__card--event--${event.status}`}>
      <svg className="card__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
        <polygon points="64,0 0,64 0,0 " />
      </svg>
      <div className="card__body">
        <div className="card__text">
          <Icon type={event.type} />
          <span>{event.title}</span>
        </div>
        <div className="card__context">
          <span>{DateTime.fromISO(event.startTime, { zone: timeZone }).toFormat("M/d/yyyy' @ 'hh:mm a")}</span>
        </div>
      </div>
    </div>
  );
}

function Icon({ type }) {
  switch (type) {
    case 'Visit':
      return <i className="card__visual fa-light fa-screwdriver-wrench" />;
    case 'Inspection':
    case 'InspectionVisit':
      return <i className="card__visual fa-light fa-clipboard-check" />;
    default:
      return <i className="card__visual fa-light fa-square-question" />;
  }
}

export default ShallowEvent;
