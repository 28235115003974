import React, { useEffect, useCallback, useMemo, useReducer, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import FilterPanel from '@components/FilterPanel';
import api from '@utils/axios';
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import I18n from '@utils/i18n';
import { datatableInspectionsPath } from 'routes';
import { saveCacheToLocalStorage } from '@helpers/localStorage';
import kendoDatatableParams from '@helpers/kendoDatatableParams';
import ColumnMenu from './nested/ColumnMenu';
import MassActions from './nested/MassActions';
import ColumnMenuContext from './nested/ColumnMenuContext';
import './index.css';
import getFiltersList from './helpers/filtersList';
import { getInitialFilters, getInitialData } from './helpers/initialData';
import { ALL_COLUMNS, ALL_COLUMNS_DATA, CACHE_KEY } from './constants';
import { actionTypes, reducer } from './reducer';

import esMessages from '../../locales/es.json';
import frMessages from '../../locales/fr.json';
import CustomScrollbar from '../../components/CustomScrollbar/CustomScrollbar';

loadMessages(esMessages, 'es');
loadMessages(frMessages, 'fr');

function InspectionsIndex({
  frequenciesForSelect,
  inspectionTypesForSelect,
  statusesForSelect,
  territoriesForSelect,
  subcontractorsForSelect,
  optionalFilters,
  optionalMassActions,
  techniciansForSelect,
  assetsForSelect,
  buildingId,
  inspectionSeriesId,
  accountId,
  cacheKey
}) {
  const [state, dispatch] = useReducer(
    reducer,
    { cacheKey, buildingId, inspectionSeriesId, accountId },
    getInitialData
  );
  const [successMessage, setSuccessMessage] = useState('');
  const [fullData, setFullData] = useState([]);

  const { page, sort, filters, columns, selectAll, data, totalCount, loading } = state;

  useEffect(() => {
    const moveSeriesButton = document.getElementById('move-series');
    if (moveSeriesButton && data && fullData?.data) {
      const dataMap = new Map(data.map(item => [item.id, item]));

      const updatedFullData = fullData.data.map(item =>
        dataMap.has(item.id) ? dataMap.get(item.id) : item
      );
      setFullData({ total_count: fullData.total_count, data: updatedFullData });

      const relevantInspections = updatedFullData.filter(i =>
        !['Weekly', 'Daily'].includes(i.frequency) &&
        ['scheduled', 'pending'].includes(i.status)
      );

      if (relevantInspections && relevantInspections.length > 0) {
        moveSeriesButton.style.display = 'block';
      } else {
        moveSeriesButton.style.display = 'none';
      }
    }
  }, [data]);

  useEffect(() => {
    saveCacheToLocalStorage(cacheKey, { page, sort, filters, columns });
  }, [page, sort, filters, columns]);

  useEffect(() => {
    dispatch({ type: actionTypes.LOADING_CHANGED, loading: true });
    api
      .get(datatableInspectionsPath(), {
        params: kendoDatatableParams({ page, sort, filters })
      })
      .then(({ data }) => {
        dispatch({ type: actionTypes.DATA_LOADED, data });
        setFullData(data);
      })
      .finally(() => {
        dispatch({ type: actionTypes.LOADING_CHANGED, loading: false });
      });
  }, [page]);

  const handlePageSizeChange = (newPageSize) => {
    debouncedSetPageSizeValue(newPageSize);
  };

  // Debounce the function that updates pageSizeValue
  const debouncedSetPageSizeValue = debounce((newValue) => {
    dispatch({ type: actionTypes.PAGE_CHANGED, page: newValue });
  }, 300);

  const filtersList = useMemo(
    () =>
      getFiltersList({
        filters,
        dispatch,
        optionalFilters,
        frequenciesForSelect,
        inspectionTypesForSelect,
        territoriesForSelect,
        subcontractorsForSelect,
        assetsForSelect,
        buildingId,
        inspectionSeriesId,
        accountId
      }),
    [filters]
  );

  const onColumnsChange = useCallback(
    (cols) => dispatch({ type: actionTypes.COLUMNS_CHANGED, columns: cols.map((col) => col.id) }),
    []
  );

  const onResetFilters = useCallback(() => {
    dispatch({
      type: actionTypes.FILTER_CHANGED,
      filters: getInitialFilters(buildingId, inspectionSeriesId, accountId)
    });
  }, []);

  const onResetCache = () => {
    if (!confirm(I18n.t('generic.are_you_sure'))) return;

    localStorage.removeItem(cacheKey);
    window.location.reload();
  };

  const onSelectionChange = useCallback((event) => {
    dispatch({ type: actionTypes.SELECTION_CHANGED, id: event.dataItem.id });
  }, []);

  const onHeaderSelectionChange = useCallback((event) => {
    dispatch({ type: actionTypes.HEADER_SELECTION_CHANGED, checked: event.syntheticEvent.target.checked });
  }, []);

  const onSelectAll = useCallback(() => {
    dispatch({ type: actionTypes.ALL_SELECTED });
  }, []);

  const onDataChanged = (message) => {
    setSuccessMessage(message);

    dispatch({ type: actionTypes.DATA_CHANGED });
  };

  const headerSelectionValue = selectAll || (totalCount > 0 && data.every((inspection) => inspection.selected));

  const allColumnsWrapped = useMemo(() => ALL_COLUMNS.map((column) => ALL_COLUMNS_DATA[column]), []);
  const columnsWrapped = useMemo(() => columns.map((column) => ALL_COLUMNS_DATA[column]), [columns]);

  return (
    <LocalizationProvider language={I18n.locale}>
      {successMessage && (
        <SuccessAlert
          message={successMessage}
          onClose={() => {
            setSuccessMessage('');
          }}
        />
      )}
      <ColumnMenuContext.Provider
        value={{
          onColumnsChange,
          columnsState: columnsWrapped,
          columns: allColumnsWrapped,
          statusesForSelect,
          dispatch
        }}>
        <FilterPanel onResetFilters={onResetFilters} onResetCache={onResetCache} filters={filtersList} />
        <hr className="workspace__split--sticky" />
        {loading && (
          <div className="workspace__section">
            <div className="qmb-loading--96" data-title={I18n.t('generic.loading')}>
              <svg role="img">
                <use href="/map.svg#load-spinner" />
              </svg>
            </div>
          </div>
        )}
        {!loading && (
          <CustomScrollbar>
            <Grid
              data={data}
              total={totalCount}
              skip={page.skip}
              take={page.take}
              onPageChange={(event) => {
                handlePageSizeChange(event.page);
              }}
              pageable={{
                type: 'input',
                pageSizes: [25, 50, 100],
                pageSizeValue: page.take
              }}
              sortable
              sort={sort}
              onSortChange={(event) => {
                dispatch({ type: actionTypes.SORT_CHANGED, sort: event.sort });
              }}
              dataItemKey="id"
              selectedField="selected"
              onSelectionChange={onSelectionChange}
              onHeaderSelectionChange={onHeaderSelectionChange}
              className="qmb-grid--01">
              <GridToolbar>
                <MassActions
                  state={state}
                  onSelectAll={onSelectAll}
                  statusesForSelect={statusesForSelect}
                  techniciansForSelect={techniciansForSelect}
                  queryParams={kendoDatatableParams({ filters })}
                  buildingId={buildingId}
                  optionalMassActions={optionalMassActions}
                  onDataChanged={onDataChanged}
                />
              </GridToolbar>

              {columnsWrapped.map((column) => (
                <Column
                  key={column.id}
                  field={column.field}
                  title={column.title}
                  columnMenu={ColumnMenu}
                  cell={column.cell}
                  headerSelectionValue={headerSelectionValue}
                  sortable={column.sortable}
                  width={column.width}
                  className={column.className}
                  headerClassName={column.headerClassName}
                />
              ))}
            </Grid>
          </CustomScrollbar>
        )}
      </ColumnMenuContext.Provider>
    </LocalizationProvider>
  );
}

function SuccessAlert({ message, onClose }) {
  return (
    <section className="workspace__section--alerts">
      <div className="qmb-alert --success">
        <i className="alert__visual" />
        <span id="success_alert_text">{message}</span>
        <button type="button" className="qmb-control--icon--close fa-light fa-xmark" onClick={onClose} />
      </div>
    </section>
  );
}

InspectionsIndex.propTypes = {
  frequenciesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  inspectionTypesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  statusesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  territoriesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  subcontractorsForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  optionalFilters: PropTypes.shape({
    subcontractor: PropTypes.bool,
    tce: PropTypes.bool,
    inspectionType: PropTypes.bool
  }),
  optionalMassActions: PropTypes.shape({
    pushToTCE: PropTypes.bool
  }),
  techniciansForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  assetsForSelect: PropTypes.arrayOf(PropTypes.object),
  buildingId: PropTypes.number,
  inspectionSeriesId: PropTypes.number,
  accountId: PropTypes.number,
  cacheKey: PropTypes.string
};

InspectionsIndex.defaultProps = {
  territoriesForSelect: [],
  frequenciesForSelect: [],
  inspectionTypesForSelect: [],
  statusesForSelect: [],
  subcontractorsForSelect: [],
  optionalFilters: [],
  optionalMassActions: {},
  techniciansForSelect: [],
  assetsForSelect: [],
  buildingId: null,
  inspectionSeriesId: null,
  accountId: null,
  cacheKey: CACHE_KEY
};

export default InspectionsIndex;
