import React from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

function NotInspectedItem({ item }) {
  const inactiveLabel = <span className="threaded-list__inactive-tag">{I18n.t('generic.inactive')}</span>;
  const itemClass = ['itemized-list__not-inspected-equipment', item.is_new ? '--new' : ''].join('');

  const newItemSign = <i className="fa-solid fa-plus" />;

  return (
    <li className={itemClass}>
      <div className="itemized-list__label">
        {item.is_new && newItemSign}
        {item.name} {!item.active && inactiveLabel}
      </div>
    </li>
  );
}

NotInspectedItem.propTypes = {
  item: PropTypes.object.isRequired
};
export default NotInspectedItem;
