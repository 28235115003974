/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import I18n from '@utils/i18n';

// Add JQuery
const { $ } = window;

// Component which renders search select for Kendo grid filters
//
// NB. If you want to re-use it from Rails views - move to features/Layouts
function SearchSelect({
  name,
  value,
  label,
  selectOptions,
  jQueryEvents,
  template,
  valueTemplate,
  dataUrl,
  valueMapUrl,
  placeholder,
  itemHeight
}) {
  const selectRef = selectOptions.ref || useRef();

  useEffect(() => {
    $(selectRef.current).kendoDropDownList({
      filter: 'contains',
      optionLabel: placeholder,
      dataTextField: 'label',
      dataValueField: 'value',
      autoWidth: true,
      autobind: false,
      change: selectOptions.onChange,
      value,
      height: 420,
      virtual: {
        itemHeight,
        valueMapper(options) {
          $.ajax({
            url: valueMapUrl,
            type: 'GET',
            data: { id: value }, // Send value to the server.
            success(data) {
              options.success(data); // Return the index number of the corresponding data item.
            }
          });
        }
      },
      messages: {
        noData: I18n.t('generic.no_data')
      },
      dataSource: {
        transport: {
          read: {
            url: dataUrl,
            dataType: 'json'
          }
        },
        schema: {
          total: 'total_count',
          data: 'data'
        },
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        pageSize: 20
      },
      template,
      valueTemplate
    });

    registerJQueryEvents();
  }, [value]);

  // useEffect(() => {
  //   registerJQueryEvents();
  // }, []);

  // TODO: remake somehow with native event handlers
  // Some of widgets are using JQuery and its events as a basis.
  // That's why sometimes you cannot use native event handlers, but need to treat them separately.
  // Better solution - highly appreciated
  const registerJQueryEvents = () => {
    if (jQueryEvents.onChange) {
      $(selectRef.current).on('change', jQueryEvents.onChange);
      delete selectOptions.onChange;
    }

    if (jQueryEvents.onKeyUp) {
      $(selectRef.current).on('keyup', jQueryEvents.onKeyUp);
      delete selectOptions.onKeyUp;
    }

    if (jQueryEvents.onKeyDown) {
      $(selectRef.current).on('keydown', jQueryEvents.onKeyDown);
      delete selectOptions.onKeyDown;
    }

    if (jQueryEvents.onClick) {
      $(selectRef.current).on('click', jQueryEvents.onClick);
      delete selectOptions.onClick;
    }

    if (jQueryEvents.onFocus) {
      $(selectRef.current).on('focus', jQueryEvents.onFocus);
      delete selectOptions.onFocus;
    }
  };

  return (
    <>
      <select name={name} onChange={() => {}} {...selectOptions} ref={selectRef} value={value} />
      {label && (
        <label className="qmb-label" htmlFor={name}>
          {label}
        </label>
      )}
    </>
  );
}

SearchSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      data: PropTypes.object
    })
  ),
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholderValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  selectOptions: PropTypes.object,
  jQueryEvents: PropTypes.shape({
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func
  }),
  template: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  valueTemplate: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  dataUrl: PropTypes.string,
  valueMapUrl: PropTypes.string,
  itemHeight: PropTypes.number
};

SearchSelect.defaultProps = {
  elements: [],
  value: undefined,
  label: undefined,
  placeholder: undefined,
  placeholderValue: '',
  selectOptions: {},
  jQueryEvents: {},
  template: undefined,
  valueTemplate: undefined,
  dataUrl: '',
  valueMapUrl: '',
  itemHeight: 90
};

export default SearchSelect;
/* eslint-enable react/jsx-props-no-spreading */
