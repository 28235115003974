import React, { useMemo } from 'react';
function SubcontractorDropdown(params) {
    const namespace = 'components.technician_dropdown';
    const subcontractorOptions = useMemo(() => {
        const options = [];
        options.push(React.createElement("option", { key: "sub-unassigned" }, "Unassigned"));
        options.push(...params.subcontractorList.map((sub) => (React.createElement("option", { key: `sub-${sub.id}`, value: sub.id }, sub.name))));
        return options;
    }, [params.subcontractorList]);
    return (React.createElement("div", { className: "qmb-select--x-full form__field" },
        React.createElement("select", { className: "tech-select additional-tech", value: params.selectedId, onChange: (e) => {
                const selectedId = Number(e.target.value);
                const selectedSub = params.subcontractorList.find((sub) => sub.id === selectedId);
                params.onChange(selectedSub !== null && selectedSub !== void 0 ? selectedSub : null);
            }, disabled: params.disabled }, subcontractorOptions),
        React.createElement("label", { className: "qmb-label" }, "Subcontractor")));
}
export default SubcontractorDropdown;
